/*
* @描述: 执行后台请求
* @作者: aoqilajiao
* @时间: 2023-07-12
* @最后修改时间: 2023-07-12
*/
import axios from '@/util/axios'

export default {
    // 获取课程表，id 可能是 cid， 也可能是 tid
    getClassOrder(page) {
        return axios.get(`/api/teacher/getClassOrder?page=${page}`)
    },

    // 发布作业
    addHomework(dataJson, files) {
        return axios.upload('/api/teacher/addHomework', dataJson, files)
    },

    // 添加报告
    addReport(jsonString) {
        return axios.post('/api/teacher/addReport', jsonString)
    },
}