/*
* @描述: 
* @作者: aoqilajiao
* @时间: 2023-05-05
* @最后修改时间: 2023-05-05
*/
// const matchs = require('./match')
import matches from './match'

const string = {
    getFisherArr(array, number, without) {
        // 在已有数组中，随机抽取N个数据创建成新数组，过滤掉 without
        // 如果数组长度小于新数组，则在其中随机重复抽取补足
        const newArray = array.filter(item => item !== without)
        const rows = []
        // 如果新数组长度为0，直接压入without
        if (newArray.length === 0) {
            rows.push(without)
        }

        while (rows.length < number && newArray.length > 0) {
            const idx = string.randomInt(0, newArray.length - 1)
            const row = newArray[idx]

            if (!rows.includes(row)) {
                rows.push(row)
            }
            // 删除已选数组，避免重复
            newArray.splice(idx, 1)
        }

        // 如数组的长度小于要获取的数组长度，那么直接重复添加
        while (rows.length < number) {
            const row = this.getFisherArr(rows, 1)
            rows.push(row[0])
        }

        return rows
    },
    // 数组乱序，Fisher-Yates 洗牌算法
    fisherArr(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1))
            [array[i], array[j]] = [array[j], array[i]]
        }
    },

    randomInt(min, max) {
        const roundedMin = Math.ceil(min) // 向上取整
        const roundedMax = Math.floor(max) // 向下取整0
        return Math.floor(Math.random() * (roundedMax - roundedMin + 1)) + roundedMin
    },

    test(source, regex) {
        return matches.test(source, regex)
    },
    isInteger(input) {
        // if(typeof input === 'number'){return true}

        return this.test(input, /^[+-]?\d+$/)

        if (isEmpty(input)) {
            return false
        }

        if (typeof input === 'number') {
            return true
        }

        return input == parseInt(input)
    },
    /**
     * @函数 返回数组为 空的情况下，如果再取 [1] 等，结果就是 undefined，如果再想分割什么的，就要重新 || ''
     * @描述 增加index参数，可以直接指定获取的数组，这样返回最少都是 ''
     * @参数 {group 组，无全局时则为 index}  
     * @返回 {} 
     */
    match(source, regex, index = -1) {
        return matches.match(source, regex, index)
    },
    /**
     * @函数 把中文数字转换成阿拉伯数字
     * @描述 
     * @参数 {}  
     * @返回 {} 
     */
    cn2Num(chineseNum) {
        const chineseNumbers = {
            零: 0,
            一: 1,
            二: 2,
            三: 3,
            四: 4,
            五: 5,
            六: 6,
            七: 7,
            八: 8,
            九: 9
        };
        const chineseUnits = {
            十: 10,
            百: 100,
            千: 1000,
            万: 10000,
            亿: 100000000
        };

        let result = 0; // 记录已识别数字的总和
        let lastNum = 1;  //默认最后一个数字为 1

        for (let i = 0; i < chineseNum.length; i++) {
            const ch = chineseNum[i];

            const n = chineseNumbers[ch];
            const u = chineseUnits[ch];
            // console.log('本次 n=', n, ' u=' + u, ' last_u=', lastNum)

            if (n != null) { // 是数字
                lastNum = n;  //先保存，不能加
            } else if (u != null) { // 是单位
                result += u * lastNum;
            } else { // 非法字符
                return NaN;
            }
        }

        // 处理最后一段数字（如果有的话）
        result += lastNum;

        return result;
    }
}

// module.exports = string
export default string
