
/*
* @描述: 重新封装 match，防止出错
* @作者: aoqilajiao
* @时间: 2023-06-25
* @最后修改时间: 2023-06-25
*/

const matches = {
    match (source, regex, index = -1) {
        if (typeof source !== "string" || source === null || source === undefined) {
            console.error('无效的数据源: ', source)
            return ''
        }
    
        if (`${regex}`.match(/g$/)) {
            // console.log('检查到全局')
            const matches = [...source.matchAll(regex)]
            return matches.map(match => match[index])
        }
    
        const matchResult = source.match(regex)
        const result = matchResult ? matchResult.slice() : ''
     
        switch (index) {
            case -1: return result
            default: return result[index] || ''
        }
    },
    test(source, regex) {
        switch(typeof source){
            case 'string':
            case 'number':
                return regex.test(source)
        }
        return false
    },
}

export default matches
 