<template>
    <div class="div-container">
        <el-row justify="start" align="middle" :gutter="5" style="margin-left: 10px;">
            <!-- selectUser -->
            <SearchUser 
                v-model:selectValue="selectUser.value" 
                :inputFold="false" 
                :showCheckTeacher="true" 
                 />
        </el-row>

        <BalanceTable :uid="selectUser.value" :tableHeight="stoneAction.clientHeight(config.windowHeight.rowLockHeight)" />
    </div>
</template>

<script setup>
import { reactive } from 'vue'
import SearchUser from '@/components/control/SearchUser.vue'
import BalanceTable from '@/components/control/BalanceTable.vue'
import config from '@/components/config/Config'
import stoneAction from '@/util/stoneAction'

const dataModel = reactive({
    checkTeacher: { value: true },
    selectUser: { value: ''  },
    tables: { data: [] },
})

const { selectUser} = dataModel
 
</script>

<style lang="scss" scoped>
</style>