/*
* @描述: 执行后台请求
* @作者: aoqilajiao
* @时间: 2023-07-09
* @最后修改时间: 2023-07-09
*/

import axios from '@/util/axios'

const adminApi = {
    // 结算
    setSettlement() {
        return axios.post('/api/admin/setSettlement')
    },
    // 获取系统配置
    getSystemConfig(key) {
        return axios.get(`/api/admin/getSystemConfig?key=${key}`)
    },

    addThesaurus(id, data) {
        return axios.post('/api/admin/addThesaurus', { id, data })
    },
    // 获取题库类目
    findThesaurusCategory(type, tid) {
        // console.log('tid',tid)
        return axios.get(`/api/admin/findThesaurusCategory?type=${type}&tid=${tid}`)
    },

    // 获取 题库 table表
    findQuestionTable() {
        return axios.get('/api/admin/findQuestionTable')
    },

    // 获取 题库 table表的配置
    getQuestionTableConfig() {
        return axios.get('/api/admin/getQuestionTableConfig')
    },

    // 创建 题库
    createQuestionTable({ subject, category, grade }) {
        console.log(subject, category, grade)
        return axios.post('/api/admin/createQuestionTable', {
            subject,
            category,
            grade
        })
    },

    // 设置价格
    setBalance(uid, money, time, remark) {
        return axios.post('/api/admin/setBalance', {
            uid: uid,
            money: money,
            time: time,
            remark: remark
        })
    },

    // 设置积分
    setScore(uid, score, time) {
        return axios.post('/api/admin/setScore', {
            uid: uid,
            score: score,
            time: time
        })
    },
    // 获取用户积分
    findUserScore(name) {
        return axios.get(`/api/admin/findUserScore?name=${name}`)
    },

    // 获取用户列表，full = 更详细
    findUser(scanString, isTeacher, full = '') {
        return axios.get(`/api/manager/findUser?username=${scanString}&teacher=${isTeacher}&full=${full}`)
    },

    // 添加用户
    addUser(username, phone, referer, teacher) {
        return axios.post('/api/manager/addUser', {
            username, phone, referer, teacher
        })
    },

    // 设置用户
    setUser(uid, password, phone, block, referer) {
        return axios.post('/api/manager/setUser', {
            uid: uid,
            password: password,
            phone: phone,
            block: block,
            referer: referer
        })
    },

    // 添加学生
    addStudent(cname, eName, birthday, mid) {
        return axios.post('/api/manager/addStudent', {
            cname: cname,
            ename: eName,
            birthday: birthday,
            mid: mid
        })
    },

    // 学生列表
    findStudent(name) {
        return axios.get(`/api/manager/findStudent?name=${name}`)
    },

    // 获取定课表
    getClassOrder(tid, page) {
        return axios.get(`/api/manager/getClassOrder?tid=${tid}&page=${page}`)
    },

    // 设置定课表
    setClassOrder(dataJson) {
        return axios.post('/api/admin/setclassorder', dataJson)
    },
    addClassOrder(dataJson) {
        return axios.post('/api/manager/addClassOrder', dataJson)
    },
    // 添加临时订课
    // addClassOrderTemp(dataJson) {
    //     return axios.post('/api/manager/addclassorder', dataJson)
    // },
    delClassOrder(dataJson) {
        return axios.post('/api/admin/delclassorder', dataJson)
    }
}

export default adminApi