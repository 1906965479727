<template>
    <div class="container">
        <div class="content">
            <div>
                {{ getCellUp(row) }}
            </div>
            <div>
                {{ getCellDown(row) }}
            </div>
        </div>
        <el-badge v-show="getCellDot(row)" is-dot type="primary" />
    </div>
</template>

<script setup>

const props = defineProps({
    checkShowValue: { default: false },
    row: { default: {} },
})

// 获取cell上面显示的学生名
function getCellUp(row) {
    return row ? row.cname : ''
}

// 获取cell下面显示的属性，包括价格和备注时间
function getCellDown(row) {
    return !row ? ''
        : (props.checkShowValue ? row.value : row.remark)
}

// 获取cell是否有作业，显示小圆点
function getCellDot(row) {
    return !row ? false : (row.hid ? true : false)
}

</script>

<style lang="scss" scoped>
.container {
    display: flex;
    align-items: center;
}

.content {
    flex-grow: 1;
    text-align: center;
}
</style>