import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/util/axios.config'
import vhCheck from "vh-check"   //移动端浏览器100vh高度不一致
vhCheck()

createApp(App)
    .use(ElementPlus)
    .use(store)
    .use(router)
    .mount('#app')

export { router, store }