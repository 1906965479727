<template>
    <div class="div-formContainer">
        <!-- <div class="div-findUser"> -->
        <div>
            <el-row justify="start" align="middle">
                <el-col :span="6" style="minWidth: 100px;">
                    <el-select value-key="username" v-model="dataModel.currentTeacher" placeholder="选择老师"
                        @change="getClassOrder()">
                        <el-option v-for="item in dataModel.teachers" :key="item.uid" :label="item.username"
                            :value="item" />
                    </el-select>
                </el-col>
                <el-col :span="2" />
                <el-col :span="2" style="minWidth: 60px;">
                    <el-button type="primary" text @click="getClassOrder(-1)">上一周</el-button>
                </el-col>
                <el-col :span="2">
                    <el-button type="primary" text @click="getClassOrder(1)">下一周</el-button>
                </el-col>
            </el-row>
        </div>


        <!-- :cell-style="{ pointerEvents: 'none' }" 
        class="el-table-class" 
    :row-style="tableRowStyle"    

                :header-cell-style="{borderColor:'#C0C0C0',color: 'black'}"
            :cell-style="{borderColor:'#C0C0C0',height:'40px',padding:0,color: 'black',fontSize: '10px'}"

    -->
        <el-table :data="dataModel.tables" border style="width: 100%" height="650" @cell-click="showDialog"
            :cell-class-name="tableCellClassName" :header-cell-class-name="headerCellClassName"
            :header-cell-style="{ borderColor: '#C0C0C0', color: 'black' }"
            :cell-style="{ borderColor: '#C0C0C0', height: '40px', padding: 0, color: 'black', fontSize: '10px' }">

            <!-- <el-table-column type="index"></el-table-column> -->

            <!-- <el-table-column property="uid" label="序列id" v-if="false">
                </el-table-column> -->
            <!-- <el-Table-Column property="class_fix">
                    <template #default="scoped">
                        <el-button link type="primary" size="small" @click="handleClick">{{ scoped.row }}</el-button>
                    </template>

                </el-Table-Column> -->

            <el-table-column property="time" :label="filterFirstDayOfWeek()" fixed align="center" width="70px" />

            <el-table-column property="1.cname" label="一" align="center" width="65px">
                <!-- <template #default="scope">
                    <div class="div-cell">
                        <span> {{ scope.row.n1 }}</span>
                    </div>
                </template> -->
            </el-table-column>

            <el-table-column property="2.cname" label="二" align="center" width="65px">
                <!-- <template #default="scope">
                    <span> {{ scope.row.class_fix[scope.column.index] }}</span>
                </template> -->
            </el-table-column>
            <el-table-column property="3.cname" label="三" align="center" width="65px" />

            <el-table-column property="4.cname" label="四" align="center" width="65px" />
            <el-table-column property="5.cname" label="五" align="center" width="65px" />
            <el-table-column property="6.cname" label="六" align="center" width="65px" />
            <el-table-column property="7.cname" label="日" align="center" width="65px" />


            <!-- <el-table-column property="block" fixed="right" label="状态" width="80">

                </el-table-column> -->

        </el-table>
        <!-- </div> -->
    </div>


    <!-- 弹出窗口设置 -->
    <el-dialog v-model="dataModel.dialog.visible" width="300px" style="height: 340px;">
        <el-row justify="space-between" align="middle">
            <el-col :span="16">
                <el-text>{{ dataModel.currentData.time }}</el-text>
            </el-col>
            <el-col :span="8" style="text-align: right">
                <el-text>星期 {{ dataModel.currentData.week }}</el-text>
            </el-col>
        </el-row>

        <!-- 显示老师 -->
        <el-row justify="space-between">
            <el-col :span="12">
                <el-text>老师：</el-text>
            </el-col>
            <el-col :span="12" style="text-align: right;">
                <el-text>{{ dataModel.currentTeacher.username }}</el-text>
            </el-col>
        </el-row>

        <!-- 显示学生 -->
        <el-row justify="space-between" v-show="!dataModel.dialog.selectStudentVisible">
            <el-col :span="12">
                <el-text>学生：</el-text>
            </el-col>
            <el-col :span="12" style="text-align: right;">
                <el-text>{{ dataModel.currentData.cname }}</el-text>
            </el-col>
        </el-row>
        <!-- 显示显示学生 -->
        <el-row justify="space-between" align="middle" v-show="dataModel.dialog.selectStudentVisible">
            <el-input v-model="dataModel.dialog.inputScanStudent" placeholder="搜索" class="input-with-select"
                @keyup.enter="findStudent()">
                <!-- 插入选择框 -->
                <template #prepend>
                    <el-select v-model="dataModel.dialog.selectStudent" :placeholder="dataModel.dialog.selectStudentHint"
                        style="width: 115px">
                        <el-option v-for="item in dataModel.students" :key="item.cid"
                            :label="`${item.e_name}, ${item.mname}`" :value="item.cid" />
                    </el-select>
                </template>
                <!-- 插入搜索框 -->
                <template #append>
                    <el-button :icon="Search" @click="findStudent()" />
                </template>
            </el-input>
        </el-row>


        <!-- 设置单价 -->
        <el-row justify="space-between" align="middle">
            <el-col :span="12">
                <el-text>设置单价：</el-text>
            </el-col>
            <el-col :span="12">
                <el-input v-model="dataModel.dialog.inputClassValue" placeholder="设置单价" />
            </el-col>
        </el-row>

        <!-- 备注时间 -->
        <el-row justify="space-between" align="middle">
            <el-col :span="12">
                <el-text>备注时间：</el-text>
            </el-col>
            <el-col :span="12">
                <el-select v-model="dataModel.dialog.selectRemark" placeholder="选择时间">
                    <el-option v-for="item in dataModel.currentData.remarkTime" :key="item.label" :label="item.label"
                        :value="item.value" />
                </el-select>
            </el-col>
        </el-row>

        <!-- 操作 -->
        <el-row justify="space-between" align="middle">
            <el-col :span="12">
                <el-text>操作选择：</el-text>
            </el-col>
            <el-col :span="12">
                <el-select v-model="dataModel.dialog.selectAction" placeholder="操作类型">
                    <el-option v-for="item in dataModel.actionList" :key="item.value" :label="item.label"
                        :value="item.value" />
                </el-select>
            </el-col>
        </el-row>

        <el-row justify="end" align="middle">
            <el-button type="primary" plain @click="setClassOrder()">确 认</el-button>
        </el-row>

    </el-dialog>
</template>

<script setup>
import { reactive, onMounted } from 'vue'
import { ElMessage, ElTable } from 'element-plus'
import { Search } from '@element-plus/icons-vue'
import config from '@/components/config/Config'
import adminApi from '@/api/adminApi'
import moment from 'moment-timezone'

const dataModel = reactive({
    firstDayOfWeek: '2023-07-09',
    weekPage: 0,
    dialog: {                           // 面板绑定的各项值
        visible: false,
        selectStudentVisible: false,     // 显示搜索学生
        selectAction: '',               // 操作
        selectStudentHint: '选择学生',   // 搜索条目显示
        inputScanStudent: '',           // 搜索学生字符串
        inputClassValue: '',            // 价格设置
        selectStudent: '',              // 学生选择
        selectRemark: ''                // 备注时间选择
    },
    teachers: [],           // 老师列表
    currentTeacher: {},     // 选择的老师数据
    students: [],           // 学生列表
    currentData: {},        // 选中的cell数据
    actionList: [],         // 动态的操作列表
    sendClass: {
        time: '',
        tid: '',
        cid: '',
        value: '',
        remark: ''
    },
    tables: [
        {
            time: '20:00',
            "1": {
                "kid": 22184,
                "cname": "Emma.LLLLL",
                "fix": 3,
                "value": "100.00"
            },
            "2": {
                "kid": 24595,
                "cname": "Bosco",
                "fix": 3,
                "value": "100.00",
                "remark": "17:15"
            },
            "3": {
                "kid": 18846,
                "cname": "Emma.LLLL",
                "fix": 3,
                "value": "100.00",
                "remark": "17:15"
            },
            "4": {
                "kid": 22181,
                "cname": "Egbert",
                "fix": 3,
                "value": "100.00"
            },
            "5": {
                "kid": 22185,
                "cname": "Emma",
                "fix": 3,
                "value": "100.00"
            },
            "6": {
                "kid": 22595,
                "cname": "Johnny.Z",
                "fix": 3,
                "value": "100.00"
            },
            "7": {
                "kid": 6794,
                "cname": "Phyllis",
                "fix": 3,
                "value": "90.00"
            }
        }
    ]
})

onMounted(() => {
    adminApi.findUser('', true).then(res => {
        if (res) {
            dataModel.teachers = res.data
        }
    })
})

// 获取课程表
function getClassOrder(page) {

    if (!dataModel.currentTeacher) {
        return
    }

    dataModel.weekPage = page ? dataModel.weekPage + page : 0
    // console.log('获取', dataModel.sendData.tid, dataModel.weekPage)
    adminApi.getClassOrder(dataModel.currentTeacher.uid, dataModel.weekPage).then(res => {

        if (res) {
            dataModel.tables = res.data
            dataModel.firstDayOfWeek = res.firstDayOfWeek
        }
    })
}

// 搜索学生
const findStudent = () => {
    adminApi.findStudent(dataModel.dialog.inputScanStudent).then(res => {
        if (res) {
            dataModel.dialog.selectStudent = ''
            dataModel.students = res.data
            dataModel.dialog.selectStudentHint = `条目 ${res.data.length}`
        }
    })
}

// 渲染表头背景色
function headerCellClassName({ columnIndex }) {

    const week = moment().format('d') || 7
    if (columnIndex == week) {
        return 'header-week-cell'
    }

    if (columnIndex > 0) {
        return 'header-cell'
    }

    return
}

// 给不同课程添加背景色
const tableCellClassName = ({ row, column, rowIndex, columnIndex }) => {
    //利用单元格的 className 的回调方法，给行列索引赋值
    row.index = rowIndex
    column.index = columnIndex
    // console.log(row.index, column.index)
    if (columnIndex === 0) {
        return 'header-cell'
    }

    if (!row[columnIndex]) {
        return
    }

    // 根据不同的fix，修改背景颜色
    const fix = row[columnIndex].fix
    // console.log(row[`n${columnIndex}`], row[`f${columnIndex}`])
    const {
        opened, temporary, tempClosed, sortDue, teamSortDue, longDue, teamLongDue
    } = config.class

    switch (fix) {
        case opened.value:
            return 'open-cell'
        case temporary.value:
            return 'temporary-cell'
        case tempClosed.value:
            return 'tempClosed-cell'
        case sortDue.value:
            return 'sortDue-cell'
        case teamSortDue.value:
            return 'teamSortDue-cell'
        case longDue.value:
            return 'longDue-cell'
        case teamLongDue.value:
            return 'teamLongDue-cell'
    }

    // return ''
}

// 过滤显示左的时间为 月-日
const filterFirstDayOfWeek = () => {
    return moment(dataModel.firstDayOfWeek).format('MM-DD')
}

// 单击表格
const showDialog = (row, column, cell) => {
    // console.log(row.index)
    // console.log(column.index)
    // console.log(row[column.index])
    // console.log(row.time)

    // 没有则返回，防止误点
    if (!row[column.index]) { return }

    // 初始化清空
    dataModel.dialog.selectStudentHint = '选择学生'     // 搜索条目显示
    dataModel.dialog.inputScanStudent = ''              // 搜索学生字符串
    // dataModel.dialog.inputClassValue = ''            // 价格设置
    // dataModel.dialog.selectStudent = ''              // 学生选择
    dataModel.dialog.selectRemark = ''                  // 备注时间选择

    // 把选择的课程数据写入 currentData，并添加完善
    dataModel.currentData = row[column.index]
    const currentTime = moment(`${dataModel.firstDayOfWeek} ${row.time}`).add(column.index - 1, 'days')
    dataModel.currentData.time = currentTime.format('YYYY-MM-DD HH:mm')
    dataModel.currentData.week = column.index
    dataModel.currentData.remarkTime = []

    // 设置默认金额，如果该课时存在金额，则以课时金额计算，否则按老师定价/2
    dataModel.dialog.inputClassValue = dataModel.currentData.value || Math.floor(dataModel.currentTeacher.balance)

    // 设置备注时间
    for (let i = -4; i < 5; i++) {
        if (i === 0) { continue }
        const remark = moment(currentTime).add(i * 5, 'minute').format('HH:mm')
        dataModel.currentData.remarkTime.push({ label: remark, value: remark })
    }

    // 设置显示学生搜索
    dataModel.dialog.selectStudentVisible = isShowSelectStudent()

    // 设置动态动作列表
    setActionList(dataModel.currentData.fix)

    console.log(dataModel.currentData)
    console.log(dataModel.currentTeacher)

    // 显示设置窗口
    dataModel.dialog.visible = true
}

// 设置动态动作列表
function setActionList(fix) {

    const {
        opened, temporary, tempClosed,
        sortDue, teamSortDue, longDue,
        teamLongDue,

        cancelClosed, setValue, longClose,
        setRemark, cancelOpened, temporaryClose,
        classClose

    } = config.class

    // 初始化
    dataModel.dialog.selectAction = ''
    dataModel.actionList = []

    // 设置动态动作列表
    switch (fix) {
        case opened.value:
            dataModel.actionList.push(cancelOpened)
            dataModel.actionList.push(temporary)
            dataModel.actionList.push(setValue)
            dataModel.actionList.push(sortDue)
            dataModel.actionList.push(longDue)
            dataModel.actionList.push(teamSortDue)
            dataModel.actionList.push(teamLongDue)
            break
        // 试听状态
        case temporary.value:
            dataModel.actionList.push(temporaryClose)
            dataModel.actionList.push(setRemark)
            dataModel.actionList.push(setValue)
            break
        // 临时取消状态
        case tempClosed.value:
            dataModel.actionList.push(temporary)
            dataModel.actionList.push(sortDue)
            dataModel.actionList.push(teamSortDue)
            dataModel.actionList.push(cancelClosed)
            break
        // 短期定课状态
        case sortDue.value:
        // 小班短期定课状态
        case teamSortDue.value:
            dataModel.actionList.push(classClose)
            dataModel.actionList.push(setRemark)
            dataModel.actionList.push(setValue)
            break
        // 长期定课状态
        case longDue.value:
        // 小班长期定课状态
        case teamLongDue.value:
            dataModel.actionList.push(tempClosed)
            dataModel.actionList.push(longClose)
            dataModel.actionList.push(setRemark)
            dataModel.actionList.push(setValue)
    }
}

function isShowSelectStudent() {
    const { opened, tempClosed } = config.class

    switch (dataModel.currentData.fix) {
        case opened.value:
        case tempClosed.value:
            return true
    }
    return false
}

function setClassOrder() {
    const { setValue, setRemark } = config.class

    const dataJson = { kid: dataModel.currentData.kid }

    switch (dataModel.dialog.selectAction) {
        case setRemark.value:
            const remark = dataModel.dialog.selectRemark
            if (!remark) {
                ElMessage.error('设置备注不能为空')
                return
            }
            dataJson.remark = remark
            break
        case setValue.value:
            const value = dataModel.dialog.inputClassValue
            if (value < 45) {
                ElMessage.error('这里不是慈善机构')
                return
            }
            dataJson.value = value
            break
        default:
            dataJson.fix = dataModel.dialog.selectAction
    }

    adminApi.setClassOrder(dataJson).then(res => {
        console.log(res)
        return

        if (res.success) {
            // console.log(res.data.data)
            ElMessage.success('设置成功')
        } else {
            ElMessage.error('设置失败，格式错误')
        }
    })

    // if (dataModel.dialog.selectAction === config.level)
}

</script>

<style lang="scss" scoped>
.div-formContainer {
    // margin: auto;
    // min-width: 500px;
    width: 100%;
    // border: 3px solid #73AD21;
    // padding: 10px;
    overflow: overflow;
}

//调整表头，等价于 header-cell-style
// ::v-deep .el-table__header tr,
// ::v-deep .el-table__header th {
//     // padding: 0;
//     // height: 42px;
//     // font-size: 20px;
//     // border: 1;
//     // border-width: 1px;
//     border-color: #C0C0C0;
//     color: black;
// }

// 设置表格，等价于 cell-style
// ::v-deep .el-table__body tr,
// ::v-deep .el-table__body td {
//     padding: 0;
//     margin: 0;
//     font-size: 10px;
//     color: black;
//     border-color: #C0C0C0;
//     border-top-color: #c0c0c0!important;
//     border-bottom-color: #c0c0c0!important;
//     // border-bottom-color: rgb(248, 117, 41);
//     // border-top-color: rgb(248, 117, 41)!important;
//     // border: 1;
//     // border-width: 1px;
//     height: 40px;
//     // 不换行，暂时没效果
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     outline-width: 0px;
//     word-break: break-all;
// }

// 控制el-row上下间隙
.el-row {
    margin-top: 10px;
    margin-bottom: 10px;
}

// 防止鼠标移动变色，好像都可以
// ::v-deep  .el-table__row--hover {
//   background-color: transparent !important;
// }
// .el-table tbody tr:hover > td {
//     background-color: #e8f0fa !important;
//   }

// 功能和el-table__body差不多，不如body设置全
// ::v-deep .el-table .el-table__row {
//     // line-height: 640px; /* 设置你需要的行高 */
//     height: 10px;
//     font-size: 10px;
// }

// 开放定课 0
:deep(.open-cell) {
    // background: linear-gradient(to right, #fba721, #fcce68, #faefb4) !important;
    background-color: greenyellow !important;
}

// 临时定课 
:deep(.sortDue-cell) {
    background-color: rgb(70, 168, 138) !important;
}

// 小组临时定课
:deep(.teamSortDue-cell) {
    background-color: rosybrown !important;
}

// 长期定课
:deep(.longDue-cell) {
    background-color: rgb(243, 188, 93) !important;
}

// 小组长期定课
:deep(.teamLongDue-cell) {
    background-color: rgb(187, 142, 219) !important;
}

// 临时取消
:deep(.tempClosed-cell) {
    background-color: gray !important;
}

// 试听
:deep(.temporary-cell) {
    background-color: lightblue !important;
}

// 表头当天周几
:deep(.header-cell) {
    background-color: rgb(241, 250, 224) !important;
}

// 表头当天周几
:deep(.header-week-cell) {
    background-color: rgb(240, 171, 213) !important;
}
</style>