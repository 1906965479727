<template>
    <div class="formContainer">
        <el-form ref="loginFormRef" :model="loginForm" status-icon :rules="loginRules" label-width="80px" class="formLogin">

            <el-form-item label="帐 号" prop="phone">
                <el-input v-model="loginForm.phone" autocomplete="off" />
            </el-form-item>

            <el-form-item label="密 码" prop="passwd">
                <el-input v-model="loginForm.passwd" type="password" autocomplete="off" />
            </el-form-item>

            <el-form-item>
                <el-col :span="6">

                </el-col>
                <!-- <el-button>注册</el-button> -->
                <el-col :span="14">
                    <el-button type="primary" @click="submitForm()">登录</el-button>
                </el-col>

            </el-form-item>
        </el-form>
    </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
import userApi from '@/api/UserApi'

const store = useStore()
const router = useRouter()

// 表单的引用对象
const loginFormRef = ref()

// 表单绑定的响应对象
const loginForm = reactive({
    phone: '',
    passwd: '',
})

const loginRules = reactive({
    phone: [
        {
            required: true, message: '请输入手机号', trigger: 'blur'
        }],
    passwd: [
        {
            required: true, message: '请输入密码', trigger: 'blur'
        }
    ],
})

// 1 校验 2 提交后台 3 设置token
const submitForm = () => {
    loginFormRef.value.validate(valid => {
        if (valid) {
            login()
        }
    })
}

//登录
async function login() {
    const { phone, passwd } = loginForm
    const res = await userApi.login({
        username: phone,
        password: passwd
    })

    if (res.success) {
        // console.log(res.data)
        store.commit('changeUserInfo', res.data)
        store.commit('changGetterRouter', false)
        router.push('/index')
        // localStorage.setItem('token', 'ceshi123')
    } else {
        ElMessage.error(res.error)
    }
}


</script>

<style lang="scss" scoped>
// .formContainer{
//     width: 500px;
//     height: 300px;
//     position: fixed;
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -50%);
//     background: rgba($color: #000000, $alpha: 1.0);

// }

.formContainer {
    position: relative;
    /* 将容器设置为相对定位 */
    height: 100vh;
    /* 充满整个视口 */
    display: flex;
    justify-content: center;
    /* 主轴居中 */
    align-items: center;
    /* 侧轴居中 */
}

.formLogin {
    position: absolute;
    /* 将表单设置为绝对定位 */
    top: 40%;
    /* 上方距离屏幕顶部50% */
    left: 50%;
    /* 左侧距离屏幕左侧50% */
    transform: translate(-50%, -50%);
    /* 移动表单使其完全居中 */
}
</style>