<template>
    <div class="div-container">
        <!-- <div class="div-findUser"> -->
        <el-row justify="start" align="middle" :gutter="5" class="lockHeight" style="margin-left: 10px;">
            <!-- 孩子选择框 selectChildren -->
            <el-col :span="6">
                <el-select v-model="dataModel.selectChildren.value" placeholder="选择孩子" size="default" placement="bottom-end"
                    @change="changSelectChildren()">
                    <el-option v-for="item in dataModel.selectChildren.list" :key="item.cid" :label="item.e_name"
                        :value="item.cid" />
                </el-select>
            </el-col>

            <!-- <el-col :span="2" style="minWidth: 60px;"> -->
            <el-button type="primary" text  @click="getClassOrder(-1)">上一周</el-button>
            <!-- </el-col> -->
            <!-- <el-col :span="2"> -->
            <el-button type="primary" text style="margin-left: 0px;" @click="getClassOrder(1)">下一周</el-button>
            <!-- </el-col> -->
            <el-text type="danger"> 请假请提前24小时！</el-text>

        </el-row>

        <ClassTable :tables="dataModel.tables" @cell-click="clickCell" />

        <!-- <el-table :data="dataModel.tables.data" border style="width: 100% " height="650"
            :cell-class-name="tableCellClassName" :header-cell-class-name="headerCellClassName"
            :header-cell-style="{ borderColor: '#C0C0C0', color: 'black' }" :cell-style="{
                borderColor: '#C0C0C0',
                height: '40px',
                padding: 0,
                color: 'black',
                fontSize: '10px'
            }">

            <el-table-column property="time" :label="filterFirstDayOfWeek" fixed align="center" min-width="50px" />

            <el-table-column property="1.cname" label="一" align="center" min-width="60px">
                <template #default="scope">
                    <div>
                        {{ getCellUp(scope.row['1']) }}
                    </div>
                    <div>
                        {{ getCellDown(scope.row['1']) }}
                    </div>
                </template>
            </el-table-column>

            <el-table-column property="2.cname" label="二" align="center" min-width="60px">
                <template #default="scope">
                    <div>
                        {{ getCellUp(scope.row['2']) }}
                    </div>
                    <div>
                        {{ getCellDown(scope.row['2']) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column property="3.cname" label="三" align="center" min-width="60px">
                <template #default="scope">
                    <div>
                        {{ getCellUp(scope.row['3']) }}
                    </div>
                    <div>
                        {{ getCellDown(scope.row['3']) }}
                    </div>
                </template>
            </el-table-column>

            <el-table-column property="4.cname" label="四" align="center" min-width="60px">
                <template #default="scope">
                    <div>
                        {{ getCellUp(scope.row['4']) }}
                    </div>
                    <div>
                        {{ getCellDown(scope.row['4']) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column property="5.cname" label="五" align="center" min-width="60px">
                <template #default="scope">
                    <div>
                        {{ getCellUp(scope.row['5']) }}
                    </div>
                    <div>
                        {{ getCellDown(scope.row['5']) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column property="6.cname" label="六" align="center" min-width="60px">
                <template #default="scope">
                    <div>
                        {{ getCellUp(scope.row['6']) }}
                    </div>
                    <div>
                        {{ getCellDown(scope.row['6']) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column property="7.cname" label="日" align="center" min-width="60px">
                <template #default="scope">
                    <div>
                        {{ getCellUp(scope.row['7']) }}
                    </div>
                    <div>
                        {{ getCellDown(scope.row['7']) }}
                    </div>
                </template>
            </el-table-column>

        </el-table> -->
        <!-- </div> -->
    </div>
</template>

<script setup>
import { reactive, onMounted } from 'vue'
import userApi from '@/api/UserApi'
import ClassTable from '@/components/control/ClassTable.vue'
import { useRouter } from 'vue-router'
import string from '@/util/string'
const router = useRouter()

const dataModel = reactive({
    checkShowValue: { value: false, visible: true },
    selectChildren: { value: '', list: [] }, // 孩子列表
    tables: { firstDayOfWeek: '', data: [] },
    weekPage: { value: 0 }  // 获取第几周
})

const { selectChildren, weekPage } = dataModel

// 获取孩子列表
onMounted(() => {
    findChildren()
})

// 选择孩子，获取课程安排
const changSelectChildren = () => {
    getClassOrder(0)
}

// 获取自己的孩子名单
async function findChildren() {
    const res = await userApi.findChildren()
    if (res.data) {
        selectChildren.list = res.data
        // console.log(res.data)
    }
}

const clickCell = ({ row }) => {
    console.log(row.hid)
    const { hid } = row
    if (hid) {
        router.push(`/classroom/Homework?id=${hid}`)
    }
}

// 获取课程表
async function getClassOrder(page) {
    if (!selectChildren.value) {
        return
    }
    
    weekPage.value = string.isInteger(page) ? weekPage.value + page : 0

    const res = await userApi.getClassOrder(selectChildren.value, weekPage.value)

    if (res.data) {
        dataModel.tables = res.data[0]
    }
}

</script>

<style lang="scss" scoped>
.div-border {
    margin: auto;
    max-width: 900px;
}

// 控制el-row上下间隙
.el-row {
    margin-top: 10px;
    margin-bottom: 10px;
}

// 开放定课 0
:deep(.open-cell) {
    // background: linear-gradient(to right, #fba721, #fcce68, #faefb4) !important;
    background-color: greenyellow !important;
}

// 临时定课 
:deep(.sortDue-cell) {
    background-color: rgb(70, 168, 138) !important;
}

// 小组临时定课
:deep(.teamSortDue-cell) {
    background-color: rosybrown !important;
}

// 长期定课
:deep(.longDue-cell) {
    background-color: rgb(243, 188, 93) !important;
}

// 小组长期定课
:deep(.teamLongDue-cell) {
    background-color: rgb(187, 142, 219) !important;
}

// 临时取消
:deep(.tempClosed-cell) {
    background-color: gray !important;
}

// 试听
:deep(.temporary-cell) {
    background-color: lightblue !important;
}

// 表头当天周几
:deep(.header-cell) {
    background-color: rgb(241, 250, 224) !important;
}

// 表头当天周几
:deep(.header-week-cell) {
    background-color: rgb(240, 171, 213) !important;
}
</style>