<template>
    <div class="div-container">
        <el-form label-width="80px">
            <!-- 用户名 inputUsername -->
            <el-form-item label="用户名">
                <el-input v-model="inputUsername.value" autocomplete="off" />
            </el-form-item>

            <!-- 电话 inputPhone -->
            <el-form-item label="手机">
                <el-input v-model="inputPhone.value" autocomplete="off" />
            </el-form-item>

            <!-- 推荐人 selectReferer -->
            <el-form-item label="推荐">
                <SearchUser v-model:selectValue="selectReferer.value" ref="actionSelect" />
            </el-form-item>
            <!-- <el-form-item label="推荐" >
                <el-input v-model="dataModel.selectReferer.inputValue" placeholder="搜索" @focus="focusSelectReferer()"
                    @blur="blurSelectReferer()" @keyup.enter="clickSelectReferer()">
                    <template #prepend>
                        <el-select :style="{ width: dataModel.selectReferer.width }"
                            :placeholder="dataModel.selectReferer.hint" v-model="dataModel.selectReferer.value">
                            <el-option v-for="item in dataModel.selectReferer.list" :key="item.uid"
                                :label="`${item.username}, ${item.phone}`" :value="item.uid" />
                        </el-select>
                    </template>

                    <template #append>
                        <el-button :icon="Search" @click="clickSelectReferer()" />
                    </template>
                </el-input>
            </el-form-item> -->

            <!-- 是否为老师 checkTeacher 和 提交按钮 buttonAddUser -->
            <el-row>
                <!-- 是否老师 checkTeacher -->
                <el-col :span="12" class="right-align">
                    <el-checkbox label="老师" v-model="checkTeacher.value" v-if="stoneAction.isAdmin()" />
                </el-col>
                <!-- 提交修改 buttonAddUser -->
                <el-col :span="12" class="right-align">
                    <el-button type="primary" @click="submitButtonAddUser()">提 交</el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script setup>
import { reactive, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { Search } from '@element-plus/icons-vue'
import webService from '@/components/webService'
import stoneAction from '@/util/stoneAction'
import adminApi from '@/api/adminApi'
import config from '@/components/config/Config'
import { useStore } from 'vuex'
const store = useStore()
import SearchUser from '@/components/control/SearchUser.vue'

const dataModel = reactive({
    inputUsername: { value: '' },
    inputPhone: { value: '' },
    checkTeacher: { value: '' },
    selectReferer: {
        value: '',
        list: [],
        disabled: true,
        visible: false,
        hint: '选择用户',
        inputValue: '',
        width: '100px',
        minWidth: '40px',
        maxWidth: '100px'
    }
})

const { selectReferer, inputUsername, inputPhone, checkTeacher } = dataModel

// 提交 添加用户
const submitButtonAddUser = () => {
    addUser()
}

// 初始化
function init() {
    inputUsername.value = ''
    inputPhone.value = ''
    selectReferer.value = ''
    checkTeacher.value = false
    // actionSelect.value.initSelect() 
}

// 添加用户
async function addUser() {
    const res = await adminApi.addUser(
        inputUsername.value,
        inputPhone.value,
        selectReferer.value,
        checkTeacher.value
    )

    if (res.success) {
        init()
        ElMessage.success('添加成功')
    } else {
        ElMessage.error(res.error)
    }
}

 

</script>

<style lang="scss" scoped>
.div-container {
    margin-top: 150px;
    min-width: 300px;
    max-width: 500px;
    width: 40%;
}
</style>
