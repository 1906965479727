<template>
    <div class="div-container">

        <el-row justify="center" class="lockHeight">
            <!-- inputStudent -->
            <el-col :span="8" :push="4">
                <el-input v-model="dataModel.inputStudent.value" placeholder="搜索学生" 
                @keyup.enter="submitInputStudent">
                    <template #append>
                        <el-button :icon="Search" @click="submitInputStudent" />
                    </template>
                </el-input>
            </el-col>
        </el-row>

        <el-table :data="tables.data" highlight-current-row border 
        :height="stoneAction.clientHeight(0)">
            <!-- <el-table-column type="index"></el-table-column> -->

            <el-table-column type="index" prop="cid" width="40px" align="center" />

            <!-- <el-table-column property="uid" label="序列id" v-if="false">
                </el-table-column> -->
            <el-table-column property="e_name" label="英文名" width="auto" align="center" />

            <el-table-column property="c_name" label="中文名" width="auto" align="center" />

            <el-table-column property="parentName" label="家长" width="auto" align="center" />

            <el-table-column property="birthday" label="年龄" width="50" align="center" :formatter="formatterAge" />
        </el-table>

    </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { ElTable } from 'element-plus'
import { Search } from '@element-plus/icons-vue'
import adminApi from '@/api/adminApi'
import moment from 'moment-timezone'
import stoneAction from '@/util/stoneAction'

// 表单的引用对象
const formRef = ref()

const dataModel = reactive({
    inputStudent: { value: '' },
    tables: { data: [] },
})

const { inputStudent, tables } = dataModel

// 搜索学生
const submitInputStudent = () => {
    findStudent()
}

// 格式化年龄
const formatterAge = (row, column, cellValue, index) => {
    return moment().diff(row.birthday, 'y')
}

// 搜索学生
async function findStudent() {
    const res = await adminApi.findStudent(inputStudent.value)
    if (res.data) {
        tables.data = res.data
    }
}

</script>

<style lang="scss" scoped>
</style>