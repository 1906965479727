/*
* @描述: 监控窗口尺寸变化，加入防抖功能
* @作者: aoqilajiao
* @时间: 2023-08-17
* @最后修改时间: 2023-08-17
*/

import { onMounted, onUnmounted, ref } from 'vue'
import stoneAction from '@/util/stoneAction'

/***
* 使用方法

import windowSize from '@/util/windowSize'
const { width, height } = windowSize() 
watch([width, height], ([width, height]) =>{
    console.log('改变')
})

*/
function windowSize() {

    const width = ref(0)
    const height = ref(0)

    //使用防抖 (setTimeout定时器)
    const getWindowInfo = () => {
        width.value = window.innerWidth
        height.value = window.innerHeight
    }

    // 防抖定时器
    const debounce = (fun, delay) => {
        let timer
        return function () {
            if (timer) {
                // console.log('清除')
                clearTimeout(timer)
            }
            timer = setTimeout(() => {
                fun()
            }, delay)
        }
    }

    //触发事件    触发时间（指定时间内执行事件）
    const watchWindowSize = debounce(getWindowInfo, 10)
 
    onMounted(() => {
        window.addEventListener('resize', watchWindowSize)


        // 初始化需要手动执行一次
        watchWindowSize()
    })

    onUnmounted(() => {
        window.removeEventListener('resize', watchWindowSize)
    })

    stoneAction.setWindowSize(width, height)
    const windowSize = { width, height }
    // stoneAction.commit('changeWindowSize', windowSize)
    // console.log('监控', windowSize)

    return windowSize
}


export default windowSize