<template>
    <div class="div-container">

        <!-- table -->
        <el-table :data="tables.data" highlight-current-row border stripe 
        :height="stoneAction.clientHeightNotRow(0)"
            @row-click="clickTable">
            <!-- <el-table-column type="index"></el-table-column> -->

            <el-table-column type="index" property="uid" width="40" align="center" />

            <!-- <el-table-column property="uid" label="序列id" v-if="false">
                </el-table-column> -->
            <el-table-column property="min_pubtime" label="发布时间" width="auto"  align="center"
                :formatter="formatMin_pubtime" />

            <el-table-column property="pname" label="老师" width="auto" align="center" />

            <el-table-column property="cname" label="学生" width="auto" align="center" />
            <!-- <el-table-column property="max_pubtime" label="学生" width="auto" align="center"
                :formatter="formatMax_pubtime" /> -->

            <el-table-column property="readed" label="状态"  width="60" align="center">
                <!-- 翻页箭头 buttonPage -->
                <template #header>
                    <el-button-group>
                        <el-button style="width: 2px;" text :disabled="!buttonPage.value" :icon="ArrowLeft"
                            @click="clickButtonPage(-1)" />
                        <el-button style="width: 2px;" text :disabled="buttonPage.disabled" :icon="ArrowRight" 
                            @click="clickButtonPage(1)" />
                    </el-button-group>
                </template>
                <template #default="scope">
                    <el-text class="center-align" style="margin-right: 5px;"
                        :type="scope.row.readed ? 'success' : 'danger'">
                        {{ scope.row.readed ? '已读' : ' 未读' }}</el-text>
                    <!-- <span :class="scope.row.readed ? 'mx-1 text-success' : 'mx-1 text-danger'">{{ scope.row.readed ? '已读' :
                        '未读' }}</span> -->
                    <!-- {{ scope.row.readed ? '已读' : '未读' }} -->
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script setup>
import { reactive, onMounted } from 'vue'
import { ElTable } from 'element-plus'
import { ArrowLeft, ArrowRight } from '@element-plus/icons-vue'
import stoneAction from '@/util/stoneAction'
import userApi from '@/api/UserApi'
import moment from 'moment-timezone'
import { useRouter } from 'vue-router'
const router = useRouter()

const dataModel = reactive({
    tables: { data: [] },
    buttonPage: { value: 0, disabled: true }
})

const { tables, buttonPage } = dataModel

// 每页最大数量，修改需对应修改server
const maxLimit = 50

// 初始化，加载作业第一页
onMounted(() => {
    findHomework(0)
})

// 过滤时间
const formatMin_pubtime = (row) => {
    return formatTime(row.min_pubtime)
}

// 过滤时间
const formatMax_pubtime = (row) => {
    return row.min_pubtime === row.max_pubtime ? '' : formatTime(row.max_pubtime)
}

// 过滤时间
function formatTime(time){
    return moment(time).format('MM-DD HH:mm')
}

// 点击table跳转
const clickTable = (row) => {
    router.push(`/classroom/Homework?id=${row.hid}`)
    // router.push({ path: '/classroom/Homework', query: { id: row.hid } })
}

// 点击翻页
const clickButtonPage = (page) => {
    findHomework(page)
}

// 获取作业列表
async function findHomework(page) {
    buttonPage.value = page === 0 ? 0 : buttonPage.value + page

    if (buttonPage.value < 0) {
        buttonPage.value = 0
    }
    const res = await userApi.findHomework(buttonPage.value)
    if (res.data) {
        tables.data = res.data
        // console.log(res.data)
        // 翻页数量
        buttonPage.disabled = res.data.length < maxLimit
    }
}


</script>

<style lang="scss" scoped>
</style>