<template>
    <div class="wrapper">
        <div class="div-image">
            <el-image :src="dataModel.igg" alt="image" class="stretch-image" loading="lazy" />
        </div>
        
    </div>
    <el-row justify="center"><span ></span> <el-text style="font-size: 22px;color: chocolate;">微妈欢迎您 </el-text>  </el-row>
 
</template>

<script setup>
import { reactive, computed, onMounted } from 'vue'
const dataModel = reactive({
    igg: '/app/me_bg.jpg',
    tgg: '/homeworks/me_bg.jpg',
    userInfo: { data: {} },
    avatar: {
        circleUrl:
            'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
        squareUrl:
            'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png',
    },
    inputPassword: { old: '', new: '', confirm: '' },
    dialog: { visible: false }
})
</script>

<style lang="scss" scoped>
.wrapper {
    display: flex;
    justify-content: center;
}

.div-image {
    width: 100%;
    max-height: 400px;
}

.stretch-image {
    width: 100%;
    height: 100%;
    /* 将图片的高度设置为100% */
    object-fit: fill;
    /* 不保持宽高比，将图片整体横向拉伸 */
}</style>