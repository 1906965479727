<template>
    <el-row align="middle" :style="{padding: '10px',  backgroundColor: props.backgroundColor}" >
        <el-col :span="16">
            <div><el-text>{{ props.textEng }}</el-text></div>
            <div><el-text>{{ props.textCn }}</el-text></div>
        </el-col>

        <!-- selectScore -->
        <el-col :span="8" class="right-align">
            <div><el-text :type="textScore">{{ props.textScore }}</el-text></div>
        </el-col>
    </el-row>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    textEng: '',
    textCn: '',
    textScore: ''
})

const textScore = computed(() => {
    switch(props.textScore){
        case 1: return 'danger'
        case 2: return 'warning'
        case 3: return 'info'
        case 4: return 'primary'
        case 5: return 'success'
        default: return ''
    }
})

</script>

<style lang="scss" scoped>
.el-row {
    border-right: 1px solid #ddd;
}
</style>