/*
* @描述: 负责耦合、解析
* @作者: aoqilajiao
* @时间: 2023-08-05
* @最后修改时间: 2023-08-05
*/
// const adminApi = require('@/util/api.admin')
// const axios  = require('axios')
// import axios from 'axios'
// const myAxios = require ('@/util/myAxios')
import adminApi from '@/api/adminApi'

const webService = {


    // 搜索用户
    findUser(selectUser, isTeacher) {
        adminApi.findUser(selectUser.inputValue, isTeacher).then(res => {
            if (res.data) {
                selectUser.inputValue = ''
                selectUser.value = ''
                selectUser.list = res.data
                selectUser.hint = `条目 ${res.data.length}`
            }
        })
    },

    setUser({ current, inputPassword, inputPhone, checkSetBlock, selectReferer }) {
        return adminApi.setUser(
            current.row.uid,
            inputPassword.value,
            inputPhone.value,
            checkSetBlock.value,
            selectReferer.value
        )
    },

    addUser({ inputUsername, inputPhone, selectReferer, checkTeacher}){
        return adminApi.addUser(
            inputUsername.value,
            inputPhone.value,
            selectReferer.value,
            checkTeacher.value
        )
    },
}

// module.exports = webService
export default webService