<template>
    <el-table :data="tables.data" border :height="stoneAction.clientHeight(0)" :cell-class-name="tableCellClassName"
        :header-cell-class-name="headerCellClassName" :header-cell-style="{ borderColor: '#C0C0C0', color: 'black' }"
        :cell-style="{ borderColor: '#C0C0C0', height: '40px', padding: 0, color: 'black', fontSize: '16px' }"
        @cell-click="clickCell">

        <el-table-column property="time" :label="filterFirstDayOfWeek" fixed align="center" min-width="35px" />

        <el-table-column property="1.cname" label="一" align="center" min-width="35px">
            <template #default="scope">
                <ClassDiv :row="scope.row['1']" :checkShowValue="checkShowValue" />
                <!-- <div class="container">
                    <div class="content">
                        <div>
                            {{ getCellUp(scope.row['1']) }}
                        </div>
                        <div>
                            {{ getCellDown(scope.row['1']) }}
                        </div>
                    </div>
                    <el-badge v-show="getCellDot(scope.row['1'])" is-dot type="primary" />
                </div> -->
                <!-- <div class="container"> -->
                <!-- <div class="content">{{ getCellUp(scope.row['1']) }}</div> -->
                <!-- <div class="badge-container"> -->
                <!-- <el-badge is-dot class=".right-align"></el-badge> -->
                <!-- </div> -->
                <!-- </div> -->

            </template>
        </el-table-column>

        <el-table-column property="2.cname" label="二" align="center" min-width="35px">
            <template #default="scope">
                <ClassDiv :row="scope.row['2']" :checkShowValue="checkShowValue" />
            </template>
        </el-table-column>
        <el-table-column property="3.cname" label="三" align="center" min-width="35px">
            <template #default="scope">
                <ClassDiv :row="scope.row['3']" :checkShowValue="checkShowValue" />
            </template>
        </el-table-column>
        <el-table-column property="4.cname" label="四" align="center" min-width="35px">
            <template #default="scope">
                <ClassDiv :row="scope.row['4']" :checkShowValue="checkShowValue" />
            </template>
        </el-table-column>
        <el-table-column property="5.cname" label="五" align="center" min-width="35px">
            <template #default="scope">
                <ClassDiv :row="scope.row['5']" :checkShowValue="checkShowValue" />
            </template>
        </el-table-column>
        <el-table-column property="6.cname" label="六" align="center" min-width="35px">
            <template #default="scope">
                <ClassDiv :row="scope.row['6']" :checkShowValue="checkShowValue" />
            </template>
        </el-table-column>
        <el-table-column property="7.cname" label="日" align="center" min-width="35px">
            <template #default="scope">
                <ClassDiv :row="scope.row['7']" :checkShowValue="checkShowValue" />
            </template>
        </el-table-column>
    </el-table>
</template>

<script setup>
import { computed } from 'vue'
import { ElTable } from 'element-plus'
import ClassDiv from './ClassDiv.vue'
import config from '@/components/config/Config'
import moment from 'moment-timezone'
import stoneAction from '@/util/stoneAction'

const props = defineProps({
    checkShowValue: { default: false },
    tables: {
        type: Object,
        default: { firstDayOfWeek: '', data: [] }
    }
})

const emits = defineEmits(['cell-click'])

// 点击cell
const clickCell = (row, column) => {
    // console.log(row)
    // 没有则返回，防止误点
    // if (column.index === 0) { return }
    // 没有则返回，防止误点
    if (column.index !== 0) {
        const rows = !row[column.index] ? {} : row[column.index]

        emits('cell-click', { row: rows, column, time: row.time })
        // console.log(rows)
    }
}

// 过滤显示左的时间为 月-日
const filterFirstDayOfWeek = computed(() => {
    const date = props.tables.firstDayOfWeek || moment().startOf('isoWeek')

    return moment(date).format('MM-DD')
})

// 渲染表头背景色
function headerCellClassName({ columnIndex }) {
    const week = moment().format('d') || 7
    if (columnIndex == week) {
        return 'header-week-cell'
    }

    if (columnIndex > 0) {
        return 'header-cell'
    }

    return
}

// 给不同课程添加背景色
const tableCellClassName = ({ row, column, rowIndex, columnIndex }) => {
    //利用单元格的 className 的回调方法，给行列索引赋值
    row.index = rowIndex
    column.index = columnIndex
    // console.log(row.index, column.index)
    if (columnIndex === 0) {
        return 'header-cell'
    }

    if (!row[columnIndex]) {
        return
    }

    // 根据不同的fix，修改背景颜色
    const fix = row[columnIndex].fix
    // console.log(row[`n${columnIndex}`], row[`f${columnIndex}`])
    const {
        opened, temporary, tempClosed, sortDue, teamSortDue, longDue, teamLongDue
    } = config.class

    switch (fix) {
        case opened.value:
            return 'open-cell'
        case temporary.value:
            return 'temporary-cell'
        case tempClosed.value:
            return 'tempClosed-cell'
        case sortDue.value:
            return 'sortDue-cell'
        case teamSortDue.value:
            return 'teamSortDue-cell'
        case longDue.value:
            return 'longDue-cell'
        case teamLongDue.value:
            return 'teamLongDue-cell'
    }
    // return ''
}

</script>

<style lang="scss" scoped>
// 开放定课 0
:deep(.open-cell) {
    // background: linear-gradient(to right, #fba721, #fcce68, #faefb4) !important;
    background-color: greenyellow !important;
}

// 临时定课 
:deep(.sortDue-cell) {
    background-color: rgb(70, 168, 138) !important;
}

// 小组临时定课
:deep(.teamSortDue-cell) {
    background-color: rosybrown !important;
}

// 长期定课
:deep(.longDue-cell) {
    background-color: rgb(243, 188, 93) !important;
}

// 小组长期定课
:deep(.teamLongDue-cell) {
    background-color: rgb(187, 142, 219) !important;
}

// 临时取消
:deep(.tempClosed-cell) {
    background-color: gray !important;
}

// 试听
:deep(.temporary-cell) {
    background-color: lightblue !important;
}

// 表头当天周几
:deep(.header-cell) {
    background-color: rgb(241, 250, 224) !important;
}

// 表头当天周几
:deep(.header-week-cell) {
    background-color: rgb(240, 171, 213) !important;
}
</style>