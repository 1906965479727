const Class = {
    opened: 0, temporary: 1,
    tempClosed: 2, longDue: 3,
    teamLongDue: 4, sortDue: 5,
    teamSortDue: 6,

    longClose: 10, setValue: 11,
    setRemark: 12, classClose: 13,
    temporaryClose: 14, cancelClosed: 15,
    cancelOpened: 16
}

const config = {

    windowHeight:{
        topHeader: 60,
        divContainerMargin: 20,
        rowMargin: 15,
        rowLockHeight: 40,
        rowHeight: 55,  //上面2个总和，因为上下margin重合，所以要减5
    },
    level: {
        admin: 3,
        manager: 2,
        teacher: 1,
        user: 0
    },
    /***
    * open 开放, temp 试听, tempClosed 临时关闭, 
    * sortDue 短期定课, teamSortDue 小组短期定课
    * longDue 长期定课, teamLongDue 小组长期定课
    */
    class: {
        // 状态类，又是操作类，都需要增加一条数据
        opened: { label: '开放预定', elabel: 'open', value: Class.opened },
        temporary: { label: '临时试听', elabel: 'temporary', value: Class.temporary },
        tempClosed: { label: '临时取消', elabel: 'tempClosed', value: Class.tempClosed },
        longDue: { label: '长期定课', elabel: 'longDue', value: Class.longDue },
        teamLongDue: { label: '小班长定', elabel: 'teamLongDue', value: Class.teamLongDue },
        sortDue: { label: '短期定课', elabel: 'sortDue', value: Class.sortDue },
        teamSortDue: { label: '小班短定', elabel: 'teamSortDue', value: Class.teamSortDue },

        // 操作类，修改数据
        longClose: { label: '永久取消', value: Class.longClose },
        setValue: { label: '修改价格', value: Class.setValue },
        setRemark: { label: '修改备注', value: Class.setRemark },

        // 操作类，删除数据
        classClose: { label: '短定取消', value: Class.classClose },       // 对应 短期定课 或 小班短定  sortDue teamSortDue
        temporaryClose: { label: '试听取消', value: Class.temporaryClose },   // 对应 临时试听 temporary
        cancelClosed: { label: '取消无效', value: Class.cancelClosed },     // 对应 临时取消 tempClosed
        cancelOpened: { label: '关闭预定', value: Class.cancelOpened }      // 对应 开放定课 opened
    },
    // 已定课状态
    orderedClass:[
        Class.temporary,
        Class.sortDue,
        Class.teamSortDue,
        Class.longDue,
        Class.teamLongDue
    ],
    // 可接受删除的状态
    delClass:[
        Class.opened, 
        Class.temporary,
        Class.sortDue,
        Class.teamSortDue,
        Class.cancelClosed
    ],
    // 可接受定课状态
    addClass: [
        Class.opened, Class.tempClosed
    ],
    // 可接受设置状态，长期定课可接受 终止时间
    setClass:[
        Class.opened, Class.temporary, 
        Class.sortDue, Class.longDue, 
        Class.teamSortDue,  Class.teamLongDue
    ],
    // 长期定课状态
    longClass:[
        Class.longDue, Class.teamLongDue
    ],
    // 添加的动作
    addAction:[
        Class.opened, Class.temporary, 
        Class.tempClosed, Class.sortDue, 
        Class.longDue, Class.teamSortDue, 
        Class.teamLongDue
    ],
    setAction:[
        Class.setValue, Class.setRemark, Class.longClose
    ],
    delAction:[
        Class.classClose, 
        Class.temporaryClose, 
        Class.cancelClosed,
        Class.cancelOpened
    ]

}


module.exports = config