const booklist = {
    engPractice: [
        { label: 'razA', value: 1 },
        { label: 'razB', value: 2 },
        { label: 'razC', value: 3 },
        { label: 'razD', value: 4 },
        { label: 'razE', value: 5 },
        { label: 'razF', value: 6 },
        { label: 'razG', value: 7 },
        { label: 'razH', value: 8 },
        { label: 'razI', value: 9 },
        { label: 'razJ', value: 10 },
        { label: 'razK', value: 11 },
        { label: 'razL', value: 12 },
        { label: 'razM', value: 13 },
        { label: 'razN', value: 14 },
        { label: 'razO', value: 15 },
        { label: 'razP', value: 16 },
        { label: 'razQ', value: 17 },
        { label: 'razR', value: 18 },
        { label: 'razS', value: 19 },
        { label: 'razT', value: 20 },
    ]
}

export default booklist