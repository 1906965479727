<template>
    <el-row align="middle" :style="{padding: '10px',  backgroundColor: props.backgroundColor}" >
        <el-col :span="16">
            <div><el-text>{{ props.textEng }}</el-text></div>
            <div><el-text>{{ props.textCn }}</el-text></div>
        </el-col>

        <!-- selectScore -->
        <el-col :span="8" class="right-align">
            <el-select v-model="selectScore.value" 
            placeholder="Select" 
            size="small" 
            style="width: 100px;"
            @change="changeSelectScore()">
                <el-option v-for="item in options.list" 
                :key="item.value" 
                :label="item.value" 
                :value="item.value" 
                />
            </el-select>
        </el-col>
    </el-row>
</template>

<script setup>
import { reactive } from 'vue'

const dataModel = reactive({
    selectScore: { value: '', },
    options: { list: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }] }
})

const props = defineProps({
    textEng: String,
    textCn: String
})

const { selectScore, options } = dataModel

const emits = defineEmits(['updateSelect'])

// 下拉选择后，进行向父组件更新
const changeSelectScore = () =>{
    emits('updateSelect', selectScore.value)
}

</script>

<style lang="scss" scoped>
.el-row {
    border-right: 1px solid #ddd;
}
</style>