<template>
    <div class="div-container">
        <!-- 题库类目选择 selectThesaurus -->
        <el-row align="middle">
            <!-- 大类选择 selectThesaurus -->
            <el-select v-model="selectThesaurus.value" style="width: 220px;" placeholder="选择词库">
                <el-option v-for="item in questionTable.list" :key="item.id"
                    :label="`${item.subjects}, ${item.category}, ${item.grade}`" :value="item.id" />
            </el-select>

            <!-- 级别选择 selectThesaurusChild -->
            <!-- <el-select v-model="selectThesaurusChild.value" placeholder="选择等级">
                <el-option v-for="item in questionTable.grades" :key="item.value" :label="item.label" :value="item.value" />
            </el-select> -->

            <el-text>问题+答案+笔记+提示+声音+图片</el-text>

            <div style="margin-left: auto; margin-right: 10px;">
                <el-icon size="20" class="right-align" @click="iconSettingClick()">
                    <Setting />
                </el-icon>
            </div>
        </el-row>

        <!-- 输入 inputText -->
        <el-row>
            <el-input v-model="inputText.value" :autosize="{ minRows: 10, maxRows: 300 }" type="textarea"
                placeholder="Please input">

            </el-input>
        </el-row>

        <!-- 格式化检查 buttonFormatThesaurus 提交 buttonSubmitThesaurus -->
        <el-row class="right-align">
            <!-- 格式化 buttonFormatThesaurus -->
            <el-button type="primary" @click="clickButtonFormatThesaurus()">格式化检查</el-button>

            <!-- 提交 buttonSubmitThesaurus -->
            <el-button type="primary" @click="submitThesaurusAdd()">提交</el-button>
        </el-row>
    </div>

    <el-dialog v-model="dialogSetting.visible" width="380px" style="height: 200px;">

        <!-- selectSubjects  -->
        <el-select v-model="questionTable.subject" placeholder="选择科目" @change="selectSubjectsChange()">
            <el-option v-for="item in questionTable.data.subjects" :key="item.value" :label="item.label"
                :value="item.value" />
        </el-select>

        <el-select v-model="questionTable.category" placeholder="选择类别">
            <!-- <el-option v-for="item in questionTable.data.subjects[questionTable.subject]" :key="item" :label="item"
                :value="item" /> -->
            <el-option v-for="item in questionTable.data.categories" :key="item.value" :label="item.label"
                :value="item.value" />
        </el-select>

        <el-select v-model="questionTable.grade" placeholder="选择级别">
            <el-option v-for="item in questionTable.data.grades" :key="item.value" :label="item.label"
                :value="item.value" />
        </el-select>

        <!-- 提交确认 buttonCreateQuestion -->
        <el-row justify="end" style="margin: 18px;">
            <el-button type="primary" plain @click="buttonCreateQuestionClick()">确认</el-button>
        </el-row>

    </el-dialog>
</template>

<script setup>
import { reactive, onMounted } from 'vue'
import { ElMessage, ElTable } from 'element-plus'
import { Setting } from '@element-plus/icons-vue'
import adminApi from '@/api/adminApi'

const dataModel = reactive({
    selectThesaurus: { value: '', list: [] },
    selectThesaurusChild: { value: '', list: [] },
    inputText: { value: '', data: [] },
    dialogSetting: { visible: false },
    questionTable: { subjects: {}, grades: {}, list: [], data: { subjects: {}, categories: {}, grades: {} }, subject: '', category: '', grade: '' }
})

const { selectThesaurus, selectThesaurusChild, inputText, dialogSetting, questionTable } = dataModel

// 获取类目
onMounted(() => {
    findQuestionTable()
})

// 获取当前题库列表
async function findQuestionTable() {
    const res = await adminApi.findQuestionTable()

    if (res.data) {
        questionTable.list = res.data
        console.log(res.data)
        questionTable.subjects = res.data.subjects
        questionTable.grades = res.data.grades
    }
}

// 获取题库表
const buttonCreateQuestionClick = async () => {
    const res = await adminApi.createQuestionTable(questionTable)
    if (res.success) {
        ElMessage.success('创建数据库成功')
    } else {
        ElMessage.error(res.error)
    }
}

// 改变选择
const selectSubjectsChange = () => {
    questionTable.category = ''
}

// 点击设置按钮
const iconSettingClick = async () => {
    dialogSetting.visible = true

    const res = await adminApi.getQuestionTableConfig()
    if (res) {
        // console.log(res.data)
        questionTable.data = res.data
    }
}

// 格式化内容
const clickButtonFormatThesaurus = () => {
    // 按换行符分割每一行
    const rows = inputText.value.split('\n')
    const arr = []

    // 以第一行 tab 长度为基准
    const len = rows[0].split('\t').length

    // 遍历每一行数据，将每行数据按制表符分割为数组，并放入结果数组
    for (const i in rows) {
        const rowArray = rows[i].split('\t')
        if (rowArray.length !== len) {
            ElMessage.error(`第 "${Number(i) + 1}" 行与起始行数组数量不同 "${rowArray.length} <> ${len}" 不同: ${rowArray}`)
            return
        }
        arr.push(rowArray);
    }

    inputText.data = JSON.stringify(arr)
}

// 提交题库内容
const submitThesaurusAdd = async () => {
    if (!selectThesaurus.value) { return }

    const arr = inputText.value.replace(/\t{2,}/g, '\t').split('\n')
    const data = arr.map(line => {
        const [q, a] = line.split('\t')
        return { q, a }
    })

    const res = await adminApi.addThesaurus(selectThesaurus.value, data)
    if (res.success) {
        ElMessage.success('上传成功')
    } else {
        ElMessage.error(res.error)
    }
}

// 下拉大类选择
const selectThesaurusVisibleChange = (val) => {
    if (val && !selectThesaurus.list[0]) {
        findThesaurusCategory(0)
    }
}

// 选择大类后
const selectThesaurusChange = async (val) => {
    const res = await adminApi.findThesaurusCategory(1, val)
    if (res.data) {
        selectThesaurusChild.list = res.data
    }
}

// 获取类目
async function findThesaurusCategory(type, tid) {
    const res = await adminApi.findThesaurusCategory(type, tid)
    // console.log(res)
    if (res.data) {
        selectThesaurus.list = res.data
    }
}

async function addThesaurus() {
    const arr = inputText.value.split('\t')

    console.log(arr)

}

</script>

<style scoped>
.el-select {
    width: 100px;
    margin-left: 5px;
    margin-right: 5px;
}
</style>