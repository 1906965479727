/*
* @描述: axios 拦截器的配置
* @作者: aoqilajiao
* @时间: 2023-06-16
* @最后修改时间: 2023-06-16
*/
import axios from 'axios'

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    const token = localStorage.getItem('token')
    config.headers.authorization = `Bearer ${token}`

    // console.log('访问时携带token', config.headers.authorization)
    return config
}, function (error) {

    // console.log('axios失败')
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log('headers!!!', response.headers)

    // console.log('获取 response')
    const { authorization } = response.headers
    
    if (authorization) {
        // console.log('写入 token')
        localStorage.setItem('token', authorization)
    }

    return response
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // const {status} = error.response

    // 如果后端返回401，则跳转到 login页面
    if (error.response.status === 401) {
        localStorage.removeItem('token')
        window.location.href = '#/login'
    }
    // console.log('axios错误返回', error)

    // return Promise.reject(error);
});