
<template>
    <div class="div-container">
        <el-form label-width="120px">

            <el-form-item label="上次截至日期：">
                <el-text>{{ lastStatement.value }}</el-text>
            </el-form-item>

            <!-- dateStart dateEnd datePicker -->
            <!-- <el-form-item label="本次结算至：">
                <el-date-picker v-model="datePicker.value" type="date" placeholder="开始日期" style="min-width: 100px" />
            </el-form-item> -->
        </el-form>
        <!-- ButtonStatement -->
        <el-row justify="center">
            <el-button @click="submitButtonStatement" type="primary">开始结算</el-button>
        </el-row>
    </div>
</template>

<script setup>
import { reactive, onMounted } from 'vue'
import adminApi from '@/api/adminApi'
import moment from 'moment-timezone'
import { ElMessage } from 'element-plus'

const dataModel = reactive({
    datePicker: { value: '' },
    lastStatement: { value: '' }
})

const { datePicker, lastStatement } = dataModel

const statement = 'statement'

// 获取最后的结算日
onMounted(() => {
    // 初始化时间
    // initDatePicker()
    // 获取系统配置
    getSystemConfig()
})

// 提交结算
const submitButtonStatement = () => {
    setSettlement()
}

// 结算
async function setSettlement() {
    const res = await adminApi.setSettlement()
    if (res.success) {
        lastStatement.value = moment(lastStatement.value).add(1, 'd').format('YYYY-MM-DD')
        ElMessage.success('结算成功')
    } else {
        ElMessage.error(res.error)
    }

}

// 获取最后的结算日
async function getSystemConfig() {
    const res = await adminApi.getSystemConfig(statement)
    if (res.data) {
        lastStatement.value = res.data[0].value
    }
}

// 初始化时间
function initDatePicker() {
    datePicker.value = moment().format('YYYY-MM-DD')
}

</script>

<style lang="scss" scoped>
.div-container {
    margin-top: 150px;
    width: 250px;
}
</style>
