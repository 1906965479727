<template>
    <!-- collapse 是否折叠
            :collapse="$store.state.isCollapsed"
        collapse-transition 动画模式
        router 开启设置路由跳转模式 -->
    <el-menu :collapse-transition="true" :router="true" :default-active="route.fullPath" @click="clickMenu">
        <el-menu-item index="/index">
            <el-icon>
                <HomeFilled />
            </el-icon>
            <span>首 页 Home</span>
        </el-menu-item>

        <!-- <el-menu-item index="/center" @click="clickMenuitem" v-user>
            <el-icon>
                <UserFilled />
            </el-icon>
            <span>个人中心</span>
        </el-menu-item> -->

        <el-sub-menu index="/center" v-user>
            <template #title>
                <el-icon>
                    <User />
                </el-icon>
                <span>个人中心</span>
            </template>
            <el-menu-item index="/center/Center">个人资料</el-menu-item>
            <el-menu-item index="/center/Consume">消费明细</el-menu-item>
            <el-menu-item index="/center/Point">积分明细</el-menu-item>
        </el-sub-menu>

        <el-menu-item index="/order-manage/orderManage" @click="clickMenuitem" v-manager>
            <el-icon>
                <Timer />
            </el-icon>
            <span>定课管理</span>
        </el-menu-item>

        <!-- <el-sub-menu index="/order-manage/orderManage" @click="clickMenuitem">
            <template #title>
            <el-icon>
                <Timer />
            </el-icon>
            <span>定课管理</span>
            </template>
                <el-menu-item index="/order-manage/orderManage">教师定课</el-menu-item>
            <el-menu-item index="/order-manage/orderList">小班定课</el-menu-item>
        </el-sub-menu> -->

        <el-sub-menu index="/user-manage" v-manager>
            <template #title>
                <el-icon>
                    <User />
                </el-icon>
                <span>用户管理</span>
            </template>
            <el-menu-item index="/user-manage/userlist">用户列表</el-menu-item>
            <el-menu-item index="/user-manage/useradd">添加用户</el-menu-item>
            <!-- <el-menu-item index="/user-manage/userset">用户设置</el-menu-item> -->
        </el-sub-menu>

        <el-sub-menu index="/student-manage" v-manager>
            <template #title>
                <el-icon>
                    <User />
                </el-icon>
                <span>学生管理</span>
            </template>
            <el-menu-item index="/student-manage/StudentList">学生列表</el-menu-item>
            <el-menu-item index="/student-manage/StudentAdd">添加学生</el-menu-item>
            <el-menu-item index="/student-manage/StudentReport" v-admin>学生报告</el-menu-item>
        </el-sub-menu>

        <el-sub-menu index="/account-manage" v-admin>
            <template #title>
                <el-icon>
                    <Goods />
                </el-icon>
                <span>账目管理</span>
            </template>
            <el-menu-item index="/account-manage/Settlement">课程结算</el-menu-item>
            <el-menu-item index="/account-manage/Recharge">充值管理</el-menu-item>
            <el-menu-item index="/account-manage/balance">消费明细</el-menu-item>
            <el-menu-item index="/account-manage/score">积分明细</el-menu-item>

        </el-sub-menu>

        <!-- 词库管理 -->
        <el-sub-menu index="/thesaurus-manage" v-admin>
            <template #title>
                <el-icon>
                    <Reading />
                </el-icon>
                <span>词库管理</span>
            </template>
            <el-menu-item index="/thesaurus-manage/thesaurusAdd">添加词库</el-menu-item>
        </el-sub-menu>

        <!-- 课堂学习 -->
        <el-sub-menu index="/classroom" v-user>
            <template #title>
                <el-icon>
                    <ReadingLamp />
                </el-icon>
                <span>课堂学习</span>
            </template>
            <el-menu-item index="/classroom/Arrange">定课安排</el-menu-item>
            <el-menu-item index="/classroom/HomeworkList">课后作业</el-menu-item>
            <el-menu-item index="/classroom/ReportList">评估报告</el-menu-item>
        </el-sub-menu>

        <!-- 记忆巩固 暂时关闭用户权限 -->
        <el-sub-menu index="/memory" v-user>
            <template #title>
                <el-icon>
                    <Compass />
                </el-icon>
                <span>记忆巩固</span>
            </template>
            <el-menu-item index="/memory/recite" v-admin>单词巩固</el-menu-item>
            <el-menu-item index="/memory/practice" v-admin>单词填空</el-menu-item>
        </el-sub-menu>

        <el-sub-menu index="/teacher-center" v-teacher>
            <template #title>
                <el-icon>
                    <ReadingLamp />
                </el-icon>
                <span>teach-center</span>
            </template>
            <el-menu-item index="/teacher-center/TimeTable">timetable</el-menu-item>
            <el-menu-item index="/classroom/HomeworkList">Homework</el-menu-item>
            <el-menu-item index="/classroom/ReportList">Report</el-menu-item>
        </el-sub-menu>

        <!-- <el-sub-menu index="/torrent-manage">
                <template #title>
                    <el-icon>
                        <Setting />
                    </el-icon>
                    <span>种子管理</span>
                </template>
                <el-menu-item index="/torrent-manage/torrentupdate">剧集追更</el-menu-item>
                <el-menu-item index="/torrent-manage/torrenttransfer">转种</el-menu-item>
            </el-sub-menu>


            <el-sub-menu index="/news-manage">
                <template #title>
                    <el-icon>
                        <Position />
                    </el-icon>
                    <span>新闻管理</span>
                </template>
                <el-menu-item index="/news-manage/newsadd">充值结算</el-menu-item>
                <el-menu-item index="/news-manage/newslist">消费查询</el-menu-item>
            </el-sub-menu> -->

    </el-menu>

    <!-- </el-aside> -->
</template>

<script setup>
import { HomeFilled, Setting, Position, UserFilled, Compass, User, Reading, Goods, ReadingLamp, Timer } from '@element-plus/icons-vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import stoneAction from '@/util/stoneAction'

// 获取当前路由
const route = useRoute()
const store = useStore()

const vManager = {
    mounted(el) {
        if (!stoneAction.isManager()) {
            el.parentNode.removeChild(el)
        }
    }
}

const vAdmin = {
    mounted(el) {
        if (!stoneAction.isAdmin()) {
            el.parentNode.removeChild(el)
        }
    }
}

const vTeacher = {
    mounted(el) {
        if (!stoneAction.isTeacher()) {
            el.parentNode.removeChild(el)
        }
    }
}

const vUser = {
    mounted(el) {
        if (!stoneAction.isUser()) {
            el.parentNode.removeChild(el)
        }
    }
}

// 呼出菜单
const clickMenu = (event) => {
    //判断点击的是否为 菜单
    if (event.target.tagName === 'LI') {
        clickMenuitem()
    }
}

// 呼出菜单
const clickMenuitem = () => {
    if (!store.state.isCollapsed && window.innerWidth < 750) {
        store.commit('changeCollapsed')
    }
}
// console.log(route.fullPath)
</script>

<style lang="scss" scoped>
// 绝对高度
.el-aside .el-menu {
    // height: 90vh;
    height: calc(100vh - var(--vh-offset, -5px))
}


</style>