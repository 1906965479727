import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import MainBox from '../views/MainBox.vue'
import routesConfig from './config'
import store from '../store/index'
import storeAction from '@/util/stoneAction'

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/mainbox',
        name: 'mainbox',
        component: MainBox
    }
]

const router = createRouter({
    // createWebHashHistory, 用经典模式
    history: createWebHistory(),    
    routes
})

router.beforeEach((to, from, next) => {
    // 如果是 login 直接放行
    if (to.name === 'login') {
        // console.log('检测到login放行')
        next()
    } else {
        // console.log(localStorage.getItem('token'))

        // 没有检查到授权，直接引导到登录页面
        if (!localStorage.getItem('token')) {
            next({
                path: '/login'
            })
        } else {
            // 第一次，即没检查到 状态值，需要配置一次路由
            if (!store.state.isGetterRouter) {
                // 每次都要主动删除原有的路由，防止多次登录，不断 addRoute

                router.removeRoute('mainbox')
                configRoute()

                // document.title = '微妈英语'

                next({
                    path: to.fullPath
                })
            } else {
                next()
            }
        }
    }
})

// 配置路由
const configRoute = () => {
    // console.log('配置路由')
    if (!router.hasRoute('mainbox')) {
        router.addRoute({
            path: '/mainbox',
            name: 'mainbox',
            component: MainBox
        })
    }

    routesConfig.forEach(item => {
        checkPermission(item) &&
            router.addRoute('mainbox', item)
    })

    // 表示第一次已配置完路由
    store.commit('changGetterRouter', true)
}

const checkPermission = (item) => {
    if (item.requireAdmin) {
        return storeAction.isAdmin()
    }
    if (item.requireManager) {
        return storeAction.isManager()
    }
    if (item.requireTeacher) {
        return storeAction.isTeacher()
    }
    if (item.requireUser) {
        return storeAction.isUser()
    }
    return true
}


export default router
