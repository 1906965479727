<template>
    <div class="div-container">
        <!-- 选择用户 selectUser -->
        <el-row justify="start" align="middle" :gutter="5" style="margin-left: 10px;">
            <SearchUser v-model:selectValue="selectUser.value" :inputFold="false" />
        </el-row>

        <!-- 显示信息表 -->
        <ScoreTable :showFromName="true" :showUserName="true" :uid="selectUser.value"
            :tableHeight="stoneAction.clientHeight(config.windowHeight.rowLockHeight)" />
    </div>
</template>

<script setup>
import { reactive } from 'vue'
import SearchUser from '@/components/control/SearchUser.vue'
import ScoreTable from '@/components/control/ScoreTable.vue'
import config from '@/components/config/Config'
import stoneAction from '@/util/stoneAction'

const dataModel = reactive({
    selectUser: { value: '' },
})

const { selectUser } = dataModel

</script>

<style lang="scss" scoped></style>