<template>
    <el-container>
        <!-- asideMenu -->
        <el-aside :class="{ 'asideFloat': windows.isVertical }" :width="asideMenuWidth">
            <SideMenu />
        </el-aside>

        <!-- divMask -->
        <div class="divMask" ref="mask" v-if="isShowMask" :width="'0px'" @click="clickDivMask()" />
        <!-- {{ windows.fullHeight }} -->
        <el-container direction="vertical" class="">
            <TopHeader />
            <el-main style="padding: 0px;"><router-view></router-view></el-main>
        </el-container>
    </el-container>
</template>

<script setup>

import TopHeader from '@/components/mainbox/TopHeader.vue'
import SideMenu from '@/components/mainbox/SideMenu.vue'
import windowSize from '@/util/windowSize'
import { reactive, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
import stoneAction from '@/util/stoneAction'

const dataModel = reactive({
    asideMenu: { position: '', collapsed: false, },
    divMask: { visible: false },
    windows: { fullHeight: '', fullWidth: '', isVertical: false },
})

const { windows, divMask, asideMenu } = dataModel

// onMounted(() => {
//    const vhk = vhCheck()
//     console.log(vhk.windowHeight, vhk.vh)
// })

const isShowMask = computed(() => {
    return windows.isVertical && !store.state.isCollapsed
})

const asideMenuWidth = computed(() => {
    return windows.isVertical && store.state.isCollapsed ? '0px' : '180px'
})

const { width, height } = windowSize()

watch([width, height], ([width, height]) => {
    windows.isVertical = width < 750

    const { isVertical } = windows

    if (!isVertical) {
        if (store.state.isCollapsed) {
            store.commit('changeCollapsed')
        }
    }
    if (windows.fullWidth >= 750 && isVertical) {
        if (!store.state.isCollapsed) {
            store.commit('changeCollapsed')
        }
    }
    windows.fullWidth = width
    windows.fullHeight = height

    // console.log(`竖显=${windows.isVertical} 菜单折叠=${store.state.isCollapsed} 显示遮罩=${isShowMask.value}`)
})


const clickDivMask = () => {
    if (!store.state.isCollapsed) {
        store.commit('changeCollapsed')
    }
}

</script>

<style lang="scss" scoped>
.divMask {
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 998;
    background: rgba(0, 0, 0, 0.2);
    transition: opacity 1s ease-in-out 1s;
}

.asideFloat {
    position: absolute;
    z-index: 999;
}

// 当宽度小于 720px 时
// @media (max-width: 750px) {

// }

.overlay {
    /* 遮罩层样式 */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(170, 170, 170, 0.5);
    z-Index: 90
}
</style>