<template>
    <div class="div-container">
        <!-- <div class="div-findUser"> -->

        <el-row justify="start" align="middle" class="lockHeight">
            <!-- 老师选择框 selectTeachers -->
            <el-col :span="6" style="minWidth: 100px; margin-left: 10px;">
                <el-select value-key="username" v-model="selectTeachers.data" placeholder="选择老师" @change="getClassOrder()">
                    <el-option v-for="item in selectTeachers.list" :key="item.uid" :label="item.username" :value="item" />
                </el-select>
            </el-col>
            <!-- <el-col :span="2" style="minWidth: 70px;"> -->
            <el-checkbox label="显示价格" v-model="checkShowValue.value" style="margin-left: 10px;"
                v-if="stoneAction.isAdmin()" />
            <!-- </el-col> -->
            <!-- <el-col :span="2" style="minWidth: 60px;"> -->
            <el-button type="primary" text @click="getClassOrder(-1)">上一周</el-button>
            <!-- </el-col> -->
            <!-- <el-col :span="2"> -->
            <el-button type="primary" text style="margin-left: 0px;" @click="getClassOrder(1)">下一周</el-button>
            <!-- </el-col> -->
        </el-row>

        <!-- :cell-style="{ pointerEvents: 'none' }" 
        class="el-table-class" 
    :row-style="tableRowStyle"    

                :header-cell-style="{borderColor:'#C0C0C0',color: 'black'}"
            :cell-style="{borderColor:'#C0C0C0',height:'40px',padding:0,color: 'black',fontSize: '10px'}"

    -->
        <ClassTable :tables="dataModel.tables" :checkShowValue="checkShowValue.value" @cell-click="clickCell" />
        <!-- <el-table :data="dataModel.tables" border style="width: 100%" height="650" @cell-click="handCellClick"
            :cell-class-name="tableCellClassName" :header-cell-class-name="headerCellClassName"
            :header-cell-style="{ borderColor: '#C0C0C0', color: 'black' }" :cell-style="{
                borderColor: '#C0C0C0',
                height: '40px',
                padding: 0,
                color: 'black',
                fontSize: '10px'
            }">

            <el-table-column property="time" :label="filterFirstDayOfWeek()" fixed align="center" width="70px" />

            <el-table-column property="1.cname" label="一" align="center" width="65px">
                <template #default="scope">
                    <div>
                        {{ getCellUp(scope.row['1'])   }}
                    </div>
                    <div>
                        {{ getCellDown(scope.row['1']) }}
                    </div>
                </template>
            </el-table-column>

            <el-table-column property="2.cname" label="二" align="center" width="65px">
                <template #default="scope">
                    <div>
                        {{ getCellUp(scope.row['2'])   }}
                    </div>
                    <div>
                        {{ getCellDown(scope.row['2']) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column property="3.cname" label="三" align="center" width="65px" >
                <template #default="scope">
                    <div>
                        {{ getCellUp(scope.row['3'])   }}
                    </div>
                    <div>
                        {{ getCellDown(scope.row['3']) }}
                    </div>
                </template>
            </el-table-column>

            <el-table-column property="4.cname" label="四" align="center" width="65px" >
                <template #default="scope">
                    <div>
                        {{ getCellUp(scope.row['4'])   }}
                    </div>
                    <div>
                        {{ getCellDown(scope.row['4']) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column property="5.cname" label="五" align="center" width="65px" >
                <template #default="scope">
                    <div>
                        {{ getCellUp(scope.row['5'])   }}
                    </div>
                    <div>
                        {{ getCellDown(scope.row['5']) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column property="6.cname" label="六" align="center" width="65px" >
                <template #default="scope">
                    <div>
                        {{ getCellUp(scope.row['6'])   }}
                    </div>
                    <div>
                        {{ getCellDown(scope.row['6']) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column property="7.cname" label="日" align="center" width="65px" >
                <template #default="scope">
                    <div>
                        {{ getCellUp(scope.row['7'])   }}
                    </div>
                    <div>
                        {{ getCellDown(scope.row['7']) }}
                    </div>
                </template>
            </el-table-column>

        </el-table> -->
        <!-- </div> -->
    </div>

    <!-- adminDialog 弹出窗口设置 -->
    <el-dialog v-if="stoneAction.isAdmin()" v-model="dataModel.dialog.visible" 
    width="300px" style="height: 340px;">
        <!-- 显示 时间 星期 -->
        <el-row justify="space-between" align="middle">
            <el-col :span="16">
                <el-text>{{ dataModel.current.attach.time }}</el-text>
            </el-col>
            <el-col :span="8" style="text-align: right">
                <el-text>星期 {{ dataModel.current.attach.week }}</el-text>
            </el-col>
        </el-row>

        <!-- 显示老师 -->
        <el-row justify="space-between">
            <el-col :span="12">
                <el-text>老师：</el-text>
            </el-col>
            <el-col :span="12" style="text-align: right;">
                <el-text>{{ dataModel.selectTeachers.data.username }}</el-text>
            </el-col>
        </el-row>

        <!-- 显示学生 -->
        <el-row justify="space-between" v-show="!selectStudents.visible">
            <el-col :span="12">
                <el-text>学生：</el-text>
            </el-col>
            <el-col :span="12" style="text-align: right;">
                <el-text>{{ dataModel.current.row.cname }}</el-text>
            </el-col>
        </el-row>

        <!-- 学生选择框 selectStudents -->
        <el-row justify="space-between" align="middle" v-show="selectStudents.visible">
            <el-input placeholder="搜索" class="input-with-select" v-model="selectStudents.inputValue"
                @keyup.enter="keyupSelectStudents()">
                <!-- 插入选择框 -->
                <template #prepend>
                    <el-select style="width: 115px" :placeholder="selectStudents.hint" v-model="selectStudents.value"
                        :disabled="selectStudents.disabled">
                        <el-option v-for="item in selectStudents.list" :key="item.cid"
                            :label="`${item.e_name}, ${item.parentName}`" :value="item.cid" />
                    </el-select>
                </template>
                <!-- 插入搜索框 -->
                <template #append>
                    <el-button :icon="Search" :disabled="selectStudents.disabled" @click="keyupSelectStudents()" />
                </template>
            </el-input>
        </el-row>

        <!-- 设置单价 classValue  -->
        <el-row justify="space-between" align="middle">
            <el-col :span="12">
                <el-text>设置单价：</el-text>
            </el-col>
            <el-col :span="12">
                <el-input placeholder="设置单价" v-model="dataModel.inputClassValue.value"
                    :disabled="dataModel.inputClassValue.disabled" />
            </el-col>
        </el-row>

        <!-- 备注时间选择框  remark -->
        <el-row justify="space-between" align="middle">
            <el-col :span="12">
                <el-text>备注时间：</el-text>
            </el-col>
            <el-col :span="12">
                <el-select placeholder="选择时间" v-model="dataModel.selectRemark.value"
                    :disabled="dataModel.selectRemark.disabled">
                    <el-option v-for="item in dataModel.selectRemark.list" :key="item.label" :label="item.label"
                        :value="item.value" />
                </el-select>
            </el-col>
        </el-row>

        <!-- 操作 selectAction -->
        <el-row justify="space-between" align="middle">
            <el-col :span="12">
                <el-text>操作选择：</el-text>
            </el-col>
            <el-col :span="12">
                <el-select placeholder="操作类型" v-model="dataModel.selectAction.value" @change="changeSelectAction()">
                    <el-option v-for="item in dataModel.selectAction.list" :key="item.value" :label="item.label"
                        :value="item.value" />
                </el-select>
            </el-col>
        </el-row>

        <!-- 提交确认 buttonOrder -->
        <el-row justify="space-between" align="middle">
            <div><el-button v-if="current.row.hid" type="primary" plain @click="buttonHomeworkClick()">查看作业</el-button>
            </div>

            <el-button type="primary" plain @click="clickButtonOrder()">确 认</el-button>
        </el-row>
    </el-dialog>

    <!-- manageDialog 弹出窗口设置 -->
    <el-dialog v-if="!stoneAction.isAdmin()" v-model="dataModel.dialog.visible" width="300px" style="height: 340px;">
        <!-- 显示 时间 星期 -->
        <el-row justify="space-between" align="middle">
            <el-col :span="16">
                <el-text>{{ dataModel.current.attach.time }}</el-text>
            </el-col>
            <el-col :span="8" style="text-align: right">
                <el-text>星期 {{ dataModel.current.attach.week }}</el-text>
            </el-col>
        </el-row>

        <!-- 显示老师 -->
        <el-row justify="space-between">
            <el-col :span="12">
                <el-text>老师：</el-text>
            </el-col>
            <el-col :span="12" style="text-align: right;">
                <el-text>{{ dataModel.selectTeachers.data.username }}</el-text>
            </el-col>
        </el-row>

        <!-- 显示学生 -->
        <el-row justify="space-between" v-show="!selectStudents.visible">
            <el-col :span="12">
                <el-text>学生：</el-text>
            </el-col>
            <el-col :span="12" style="text-align: right;">
                <el-text>{{ dataModel.current.row.cname }}</el-text>
            </el-col>
        </el-row>

        <!-- 操作 selectAction -->
        <el-row justify="space-between" align="middle">
            <el-col :span="12">
                <el-text>操作选择：</el-text>
            </el-col>
            <el-col :span="12">
                <el-select placeholder="操作类型" v-model="dataModel.selectAction.value" @change="changeSelectAction()">
                    <el-option v-for="item in dataModel.selectAction.list" :key="item.value" :label="item.label"
                        :value="item.value" />
                </el-select>
            </el-col>
        </el-row>


        <!-- 提交确认 buttonOrder -->
        <el-row justify="end" align="middle">
            <el-button type="primary" plain @click="clickButtonOrder()">确认</el-button>
        </el-row>

    </el-dialog>
</template>

<script setup>
import { reactive, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { Search } from '@element-plus/icons-vue'
import config from '@/components/config/Config'
import fun from './ProductManage'
import adminApi from '@/api/adminApi'
import ClassTable from '@/components/control/ClassTable.vue'
import string from '@/util/string'
import stoneAction from '@/util/stoneAction'

import { useRouter } from 'vue-router'
const router = useRouter()

const dataModel = reactive({
    // firstDayOfWeek: '2023-07-09',
    weekPage: 0,
    tables: { firstDayOfWeek: '', data: [] },
    checkShowValue: { value: false, visible: true },
    inputClassValue: { value: '', disabled: true },     //设置价格
    selectTeachers: { data: '', list: [] }, // 老师列表
    selectStudents: { t: false, value: '', list: [], disabled: true, visible: false, hint: '选择学生', inputValue: '' },    //学生选择
    selectAction: { value: '', list: [] },    // 操作
    selectRemark: { value: '', list: [], disabled: true },    // 备注时间
    dialog: { visible: false },
    dialogManage: { visible: false },
    current: {          // 选中的cell数据
        row: {},        // 选中的数据
        attach: { time: '', week: 0 },       //附加的数据
    },
    // tables: [
    //     {
    //         time: '20:00',
    //         "2": {
    //             "kid": 24595,
    //             "cname": "Bosco",
    //             "fix": 3,
    //             "value": "100.00",
    //             "remark": "17:15"
    //         },
    //         "3": {
    //             "kid": 18846,
    //             "fix": 0,
    //             "value": "100.00"
    //         }
    //     }
    // ]
})

const { selectTeachers, checkShowValue, selectStudents, selectRemark, selectAction, current, tables, dialogManage } = dataModel

// 获取老师列表
onMounted(() => {
    fun.getTeachers(dataModel.selectTeachers)
})

// const autoHeight = computed(() => {
//     const { divContainerMargin, rowHeight } = config.windowHeight
//     const hight = stoneAction.clientHeight(divContainerMargin + rowHeight )
//     console.log(hight)
//     return hight
// })

// // 获取cell上面显示的学生名
// function getCellUp(row) {
//     return row ? row.cname : ''
// }

// // 获取cell下面显示的属性，包括价格和备注时间
// function getCellDown(row) {
//     return !row ? ''
//         : (dataModel.checkShowValue.value ? row.value : row.remark)
// }

// 动作菜单改变
function changeSelectAction() {
    fun.changeSelectAction(dataModel)
}

// 搜索学生
function keyupSelectStudents() {
    fun.findStudent(dataModel)
}

// 提交课程修改
async function clickButtonOrder() {
 

    const res = await fun.setClassOrder(dataModel)

    if (typeof res.success === 'boolean') {
        if (res.success === true) {
            getClassOrder(0)    //设置为0，表示刷新当前页
            ElMessage.success('设置成功')
            // 刷新
            dataModel.dialog.visible = false

        } else {
            ElMessage.error('数据更新失败，请汇报管理员')
        }
    } else {
        ElMessage.error(res.success)
    }
}

// 提交查看课程
const buttonHomeworkClick = () => {
    const { hid } = current.row

    if (hid) {
        router.push(`/classroom/Homework?id=${hid}`)
    }
}

// 单击表格，显示操作面板
const clickCell = ({ row, column, time }) => {
    // 没有则返回，防止误点
    // if (column.index === 0) { return }
    // 初始化Current数据，包括对一些数据清空
    fun.initCurrent(row, column, time, current, tables)

    // 设置显示学生搜索
    fun.initSelectStudents(selectStudents, current.row.fix)

    // 重置价格设置框
    fun.initInputClassValue(dataModel)

    // 重置备注时间
    fun.initSelectRemark(selectRemark, current.attach.time)

    // console.log( stoneAction.isAdmin())
    if (stoneAction.isAdmin()) {
        // 重置动态动作列表
        fun.initSelectAction(selectAction, current.row.fix)
        // console.log('current.row', current.row)
        // console.log('current.attach', current.attach)
        // console.log('teachers', selectTeachers.data)
    } else {

        if (config.longClass.indexOf(current.row.fix) === -1) {
            return
        }

        initSelectActionManage()
    }

    // 显示设置窗口
    dataModel.dialog.visible = true

}

// // 过滤显示左的时间为 月-日
// const filterFirstDayOfWeek = () => {
//     return moment(dataModel.firstDayOfWeek).format('MM-DD')
// }

// // 渲染表头背景色
// function headerCellClassName({ columnIndex }) {
//     const week = moment().format('d') || 7
//     if (columnIndex == week) {
//         return 'header-week-cell'
//     }

//     if (columnIndex > 0) {
//         return 'header-cell'
//     }

//     return
// }

// // 给不同课程添加背景色
// const tableCellClassName = ({ row, column, rowIndex, columnIndex }) => {
//     //利用单元格的 className 的回调方法，给行列索引赋值
//     row.index = rowIndex
//     column.index = columnIndex
//     // console.log(row.index, column.index)
//     if (columnIndex === 0) {
//         return 'header-cell'
//     }

//     if (!row[columnIndex]) {
//         return
//     }

//     // 根据不同的fix，修改背景颜色
//     const fix = row[columnIndex].fix
//     // console.log(row[`n${columnIndex}`], row[`f${columnIndex}`])
//     const {
//         opened, temporary, tempClosed, sortDue, teamSortDue, longDue, teamLongDue
//     } = config.class

//     switch (fix) {
//         case opened.value:
//             return 'open-cell'
//         case temporary.value:
//             return 'temporary-cell'
//         case tempClosed.value:
//             return 'tempClosed-cell'
//         case sortDue.value:
//             return 'sortDue-cell'
//         case teamSortDue.value:
//             return 'teamSortDue-cell'
//         case longDue.value:
//             return 'longDue-cell'
//         case teamLongDue.value:
//             return 'teamLongDue-cell'
//     }
//     // return ''
// }

// 获取课程表
async function getClassOrder(page) {
    if (!selectTeachers.data) {
        return
    }
    // const { weekPage, selectTeachers, tables, firstDayOfWeek } = dataModel
    dataModel.weekPage = string.isInteger(page) ? dataModel.weekPage + page : 0
    const res = await adminApi.getClassOrder(selectTeachers.data.uid, dataModel.weekPage)

    // 如果直接 dataModel.tables = res.data[0]，会造成 tables.firstDayOfWeek 无法访问
    if (res.data) {
        dataModel.tables.firstDayOfWeek = res.data[0].firstDayOfWeek
        dataModel.tables.data = res.data[0].data
        // dataModel.tables = res.data[0]
    }
}

function initSelectActionManage() {
    const { tempClosed, longDue, teamLongDue } = config.class

    // 初始化
    selectAction.value = ''
    selectAction.list.length = 0

    // 设置动态动作列表
    switch (current.row.fix) {
        // 长期定课状态
        case longDue.value:
        // 小班长期定课状态
        case teamLongDue.value:
            selectAction.list.push(tempClosed)
            break
    }
}

// 设置动态动作列表
// function setActionList(fix) {
//     fun.setActionList(fix, dataModel)
// }

// // 是否显示学生
// function isShowSelectStudent() {
//     return fun.isShowSelectStudent(dataModel)
// }
</script>

<style lang="scss" scoped>

</style>