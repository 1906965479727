
<template style="background-color: antiquewhite;">
    <!-- 问题与答案 answerText  -->
    <el-row style="background-color: antiquewhite; height: 250px; padding: 20px;margin-bottom: 0px;" >
        <template v-for="(item, index) in textSentence.question">
            <el-text> {{ item }} </el-text> <el-text class="highlight">{{ textSentence.answer[index] }}</el-text>
        </template>
        <el-text size="large">
            <!-- {{ formattedSentence }} -->
            <!-- {{ formatFront }} <span class="highlight">{{ current.value }}</span> {{ formatBack }} -->
        </el-text>
    </el-row>
    <!-- 进度条 progressTime -->
    <el-progress :percentage="progress.value" :show-text="false" style="margin-top: 0px;" />

    <div>
    <p :class="{ 'text-with-dot': true }">
      文字内容<span class="dot"></span>
    </p>
  </div>

    <!-- 解释 explainText -->
    <el-row justify="center" align="middle" style="height: 180px; padding: 20px;">
        {{ explainText.value }}

    </el-row>
    <el-row justify="center">

        <!-- 按钮 buttonAnswer -->
        <template v-for="item in buttonAnswer.list" :key="item.value">

            <el-button :type="item.type" plain style="width: 22%;" @click="clickButtonAnswer(item)">{{ item.value
            }}</el-button>

        </template>

        <!-- <el-button style="width: 22%;">aconahah</el-button>
        <el-button style="width: 22%;">aconahah</el-button>
        <el-button style="width: 22%;">aconahah</el-button>
        <el-button style="width: 22%;">aconahah</el-button> -->


    </el-row>
    <!-- 悬浮下一步 buttonNext -->
    <el-affix v-show="buttonNext.visible" position="bottom" :offset="80" style="position: fixed; right: 20px;">
        <el-button class="right-align" type="success" plain :icon="ArrowRightBold" circle @click="clickButtonNext()" />
    </el-affix>
</template>

<script setup>
import { reactive, onMounted, computed } from 'vue'
import { ArrowRightBold } from '@element-plus/icons-vue'
import string from '@/util/string'


const dataModel = reactive({
    dateStart: { value: '' },
    explainText: { value: '留白显示解释' },
    answerText: { value: '          ' },
    progressTime: { indeterminate: true, duration: 20 },
    buttonNext: { visible: false },
    buttonAnswer: {
        list: [
            { value: 'flight', type: '' },
            { value: 'logged', type: '' },
            { value: 'most', type: '' },
            { value: 'flew', type: '' }
        ]
    },
    textSentence: { question: ['Ticket prices', 'your'], answer: ['      ', '        '] },
    current: {
        data: {},
        question: 'Ticket prices include your ___ and rail journey.',
        answer: ['flight'],
        // 混淆答案
        confound: ['aaa', 'ddd', 'kkk'],
        explain: 'a plane making a particular journey',
        forget: '',
        timeout: ''
    },
    history: {
        answer: []
    },
    questionBank: {
        data: [
            { question: '1 Ticket prices include your ___ and rail journey.', answer: '1', explain: '', sound: '', forget: 0, timeout: 0 },
            { question: '2 Ticket prices include your ___ and rail journey.', answer: '2', explain: '', sound: '', forget: 0, timeout: 0 },
            { question: '3 Ticket prices include your ___ and rail journey.', answer: '3', explain: '', sound: '', forget: 0, timeout: 0 },
            { question: '4 Ticket prices include your ___ and rail journey.', answer: '4', explain: '', sound: '', forget: 0, timeout: 0 },
            { question: '5 Ticket prices include your ___ and rail journey.', answer: '5', explain: '', sound: '', forget: 0, timeout: 0 },
            { question: '6 Ticket prices include your ___ and rail journey.', answer: '6', explain: '', sound: '', forget: 0, timeout: 0 },
            { question: '7 Ticket prices include your ___ and rail journey.', answer: '7', explain: '', sound: '', forget: 0, timeout: 0 }
        ]
    },
    progress: {
        value: 0
    }
})

const { progress, textSentence, current, buttonNext, progressTime, explainText, buttonAnswer, questionBank } = dataModel

// 初始化就启动定时器
onMounted(() => {
    progressRun(10, progress)
})

// 获取问题
function getQuestion() {
    // 当前数据指向 问题合集第1个
    current.data = questionBank.data[0]
    const { confound, data } = current

    // 在历史答案中获取3个随机答案，并且排除正确答案
    confound = string.getFisherArr(history, 3, data.answer)
    // 再压入正确答案
    confound.push(data.answer)
    // 乱序
    string.fisherArr(confound)

    current.question = data.question
    current.answer = data.answer
}
// 下一个问题
const clickButtonNext = () => {
    // 判断当前是否已经完成
    if (current.answer) {

    }
    init()
}

// 时间进度条
function progressRun(second, progress) {

    const startTime = Date.now()
    progress.value = 0

    const intervalId = setInterval(() => {
        const currentTime = Date.now()
        const elapsedTime = currentTime - startTime;
        const currentProgress = Math.min(elapsedTime / (second * 1000), 1)
        progress.value = Math.round(currentProgress * 100)

        if (currentProgress >= 1) {
            clearInterval(intervalId)
        }
    }, 1)
}

function formatSentence() {
    textSentence.question = []
    textSentence.answer = []

    const { question, answer } = textSentence

    const rows = current.question.split(/_{2,}/)
    for (const i in rows) {
        question.push(rows[i])
        // console.log(i, rows.length)
        answer.push(i < rows.length - 1 ? '          ' : '')
    }
    // console.log(current.data)
}

// 格式化问题，显示前半段
const formatFront = computed(() => {
    return current.question.replace(/_.*$/, '')
})

// 格式化问题，显示后半段
const formatBack = computed(() => {
    return current.question.replace(/^.*_+/, '')
})


function isCorrent(item) {
    return item.value === current.answer
}
// 选择问题后
const clickButtonAnswer = (item) => {
    // formatSentence()
    // return
    // console.log(item.value, current.answer)
    item.type = isCorrent(item) ? 'success' : 'danger'
    current.value = current.answer
    buttonNext.visible = true
    progressTime.indeterminate = false
    explainText.value = current.explain
}

function init() {
    buttonNext.visible = false
    current.value = ''
    answerText.value = '          '
}

function findQuestion() {

}

</script>


<style scoped>
.highlight {
    text-decoration: underline;
    text-decoration-color: black;
    white-space: pre;
    font-weight: bold;
    color: green;
}


</style>