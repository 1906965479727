/*
* @描述: 负责处理函数
* @作者: aoqilajiao
* @时间: 2023-07-23
* @最后修改时间: 2023-07-23
*/

import config from '@/components/config/Config'
import adminApi from '@/api/adminApi'
import moment from 'moment-timezone'

const {
    opened, temporary, tempClosed,
    sortDue, teamSortDue, longDue,
    teamLongDue,

    cancelClosed, setValue, longClose,
    setRemark, cancelOpened, temporaryClose,
    classClose,

} = config.class

const fun = {
    // 动作菜单改变，相应的控件disabled也会改变
    changeSelectAction({ inputClassValue, selectRemark, selectAction, selectStudents }) {
        switch (Number(selectAction.value)) {
            // case cancelClosed.value:
            // case longClose.value:
            // case tempClosed.value:
            // case classClose.value:
            // case temporaryClose.value:
            //     classValue.disabled = true
            //     remark.disabled = true
            //     break

            // 设置价格
            case setValue.value:
                inputClassValue.disabled = false
                selectRemark.disabled = true
                selectStudents.disabled = true
                break

            // 设置备注时间
            case setRemark.value:
                inputClassValue.disabled = true
                selectRemark.disabled = false
                selectStudents.disabled = true
                break

            case temporary.value:
            case opened.value:
            case teamSortDue.value:
            case teamLongDue.value:
                inputClassValue.disabled = false
                selectRemark.disabled = false
                selectStudents.disabled = true
                break

            case sortDue.value:
            case longDue.value:
                inputClassValue.disabled = false
                selectRemark.disabled = false
                selectStudents.disabled = false
                break
                
            default:
                inputClassValue.disabled = true
                selectRemark.disabled = true
                selectStudents.disabled = true
        }
    },

    setClassOrder({ current, selectTeachers, selectStudents, selectAction, selectRemark, inputClassValue }) {

        
        const action = selectAction.value

        const cid = action === tempClosed.value ? current.row.cid
            : ((action === temporary.value && !selectStudents.value) ? selectStudents.inputValue : selectStudents.value)

        // console.log( selectStudents.value, selectStudents.inputValue, cid, action)
        const dataJson = {
            kid: current.row.kid,
            cid: cid,
            tid: selectTeachers.data.uid,
            action: action,
            time: current.attach.time,  // 附加的时间，经过换算
            remark: selectRemark.value,
            value: inputClassValue.value
        }

        const { addAction, setAction, delAction } = config
        // Class.opened, Class.temporary, 
        // Class.tempClosed, Class.sortDue, 
        // Class.longDue, Class.teamSortDue, 
        // Class.teamLongDue
 
        if (addAction.indexOf(action) !== -1) {
            return adminApi.addClassOrder(dataJson)
        }
 
        if (setAction.indexOf(action) !== -1) {
            // 长期关闭有两个可能，当定课与取消时间相同，则直接删除
            const { longClass } = config

            // console.log('进入到设置', action)
            // 如果长期关闭课程，而课程又是新订阅，未发生过记录，则直接删除
            if (longClass.indexOf(current.row.fix) !== -1 
                && action === longClose.value
                && current.row.time === current.attach.time) {

                return adminApi.delClassOrder(dataJson)
            }

            return adminApi.setClassOrder(dataJson)
        }

        if (delAction.indexOf(action) !== -1) {
            return adminApi.delClassOrder(dataJson)
        }

        return '错误的状态提交'
        // if (config.orderedClass.indexOf(selectAction.value) === -1) {
        //     return adminApi.setClassOrder({
        //         kid: current.row.kid,
        //         tid: selectTeachers.data.uid,
        //         cid: selectStudents.value,
        //         fix: selectAction.value,
        //         remark: selectRemark.value,
        //         value: inputClassValue.value
        //     })
        // }
        // return adminApi.setClassOrder({
        //     kid: current.row.kid,
        //     tid: selectTeachers.data.uid,
        //     cid: selectStudents.value,
        //     fix: selectAction.value,
        //     remark: selectRemark.value,
        //     value: inputClassValue.value
        // })


        // const kid = current.row.kid
        // const tid = selectTeachers.data.uid
        // const cid = selectStudents.value
        // const action = selectAction.value
        // const time = current.attach.time
        // const remark = selectRemark.value
        // const value = inputClassValue.value


        // switch (action) {
        //     // 开放预定
        //     case opened.value:

        //         // 检查价格、备注
        //         return adminApi.addClassOrder({
        //             tid: tid,
        //             cid: cid,
        //             action: action,
        //             time: time,     //这里有问题？
        //             remark: remark,
        //             value: value
        //         })

        //         return
        //     case setRemark.value:
        //         adminApi.setClassOrder({
        //             kid: kid,
        //             action: action,
        //             remark: remark
        //         })

        //     case setValue.value:
        //         adminApi.setClassOrder({
        //             kid: kid,
        //             action: action,
        //             value: value
        //         })

        //     case 

        //     default:
        //         dataJson.fix = selectAction.value
        // }


    },

    // 搜索学生
    findStudent({ selectStudents }) {
        adminApi.findStudent(selectStudents.inputValue).then(res => {
            if (res.data) {

                selectStudents.inputValue = ''
                selectStudents.value = ''
                selectStudents.list = res.data
                selectStudents.hint = `条目 ${res.data.length}`
            }
        })
    },

    // 获取老师列表
    getTeachers(selectTeachers) {
        adminApi.findUser('', true).then(res => {
            if (res.data) {
                // console.log(res.data)
                selectTeachers.list = res.data.filter(item => item.block !== 1)
            }
        })
    },

    // 初始化current 数据
    initCurrent(row, column, time, current, tables) {
        // const { current } = dataModel
        // 把选择的课程数据指向 current，并添加完善
        current.row = row
        // 重置附加数据
        // attach = {}
        // console.log(`测试哦 ${tables.firstDayOfWeek} ${time}` )
        current.attach.time = moment(`${tables.firstDayOfWeek} ${time}`).add(column.index - 1, 'd').format('YYYY-MM-DD HH:mm')
        current.attach.week = column.index
    },
    // 重置学生选择
    initSelectStudents(selectStudents, fix) {
        // 初始化清空
        selectStudents.hint = '选择学生'            // 搜索条目显示
        selectStudents.inputValue = ''              // 搜索学生字符串
        selectStudents.disabled = true
        selectStudents.list = []
        selectStudents.value = ''
        // 设置显示学生搜索
        selectStudents.visible = isShowSelectStudent(fix)
    },
    // 重置选择备注时间控件
    initSelectRemark(selectRemark, currentTime) {
        selectRemark.value = ''
        selectRemark.disabled = true
        selectRemark.list = []

        // 初始化备注时间
        for (let i = -4; i < 5; i++) {
            if (i === 0) { continue }
            const remark = moment(currentTime).add(i * 5, 'minute').format('HH:mm')
            selectRemark.list.push({ label: remark, value: remark })
        }
    },
    // 重置价格设置
    initInputClassValue({ inputClassValue, current, selectTeachers }) {
        inputClassValue.disabled = true
        // 设置默认金额，如果该课时存在金额，则以课时金额计算，否则按老师定价/2
        inputClassValue.value = current.row.value || (selectTeachers.data.balance / 2).toFixed(2)
    },
    // 设置动态动作列表
    initSelectAction(selectAction, fix) {
        const {
            opened, temporary, tempClosed,
            sortDue, teamSortDue, longDue,
            teamLongDue,

            cancelClosed, setValue, longClose,
            setRemark, cancelOpened, temporaryClose,
            classClose

        } = config.class

        // 初始化
        selectAction.value = ''
        selectAction.list.length = 0

        // 设置动态动作列表
        switch (fix) {
            case opened.value:
                selectAction.list.push(cancelOpened)
                selectAction.list.push(temporary)
                selectAction.list.push(setValue)
                selectAction.list.push(sortDue)
                selectAction.list.push(longDue)
                selectAction.list.push(teamSortDue)
                selectAction.list.push(teamLongDue)
                break
            // 试听状态
            case temporary.value:
                selectAction.list.push(temporaryClose)
                selectAction.list.push(setRemark)
                selectAction.list.push(setValue)
                break
            // 临时取消状态
            case tempClosed.value:
                selectAction.list.push(temporary)
                selectAction.list.push(sortDue)
                selectAction.list.push(teamSortDue)
                selectAction.list.push(cancelClosed)
                break
            // 短期定课状态
            case sortDue.value:
            // 小班短期定课状态
            case teamSortDue.value:
                selectAction.list.push(classClose)
                selectAction.list.push(setRemark)
                selectAction.list.push(setValue)
                break
            // 长期定课状态
            case longDue.value:
            // 小班长期定课状态
            case teamLongDue.value:
                selectAction.list.push(tempClosed)
                selectAction.list.push(longClose)
                selectAction.list.push(setRemark)
                selectAction.list.push(setValue)
                break
            default:
                selectAction.list.push(opened)
        }
    },
}

// 是否显示学生
function isShowSelectStudent(fix) {
    // console.log(fix)
    const { opened, tempClosed } = config.class

    switch (fix) {
        case opened.value:
        case tempClosed.value:
            return true
    }
    return false
}

export default fun