<template>
    <div class="div-container">
        <el-row justify="center" class="lockHeight">

            <!-- 选择老师 checkTeacher -->
            <el-col :span="4">
                <el-checkbox label="老师" v-model="dataModel.checkTeacher.value" v-if="stoneAction.isAdmin()" />
            </el-col>

            <!-- 选择冻结 checkBlock -->
            <el-col :span="4">
                <el-checkbox label="冻结" v-model="dataModel.checkBlock.value" v-if="stoneAction.isAdmin()" />
            </el-col>

            <!-- 搜索用户 inputUser -->
            <el-col :span="8">
                <el-input v-model="dataModel.inputUser.value" placeholder="搜索用户" @keyup.enter="submitInputUser">
                    <template #append>
                        <el-button :icon="Search" @click="submitInputUser" />
                    </template>
                </el-input>
            </el-col>
        </el-row>

        <!-- 

        <el-table :data="filterTableData" highlight-current-row border stripe height="850" 
        @row-click="clickTable">

            <el-table-column type="index" property="uid" width="40px" align="center" />

            <el-table-column property="username" label="姓名" width="auto" :min-width="130" align="center" />

            <el-table-column property="phone" label="电话" width="auto" :min-width="100" align="center" />

            <el-table-column property="balance" label="余额" sortable width="auto" :min-width="80" :sort-method="sortBalance"
                align="center" />

            <el-table-column property="block" label="状态" width="auto" :min-width="60" align="center">
                <template #default="scope">
                    <el-text :type="scope.row.block ? 'danger' : 'success'">
                        {{ scope.row.block ? '冻结' : '正常' }}
                    </el-text>

                </template>
            </el-table-column>

        </el-table>

         -->
        <!-- 表格显示 tables -->
        <el-table :data="filterTableData" highlight-current-row border stripe :height="stoneAction.clientHeight(0)"
            :cell-class-name="tableCellClassName" @cell-click="clickCell">

            <el-table-column type="index" property="uid" width="40px" align="center" />

            <el-table-column property="username" label="姓名" width="auto" align="center" />

            <el-table-column property="children" label="孩子" width="auto" align="center" />

            <el-table-column property="balance" label="余额" sortable width="auto" :sort-method="sortBalance"
                align="center" />

            <el-table-column property="block" label="状态" :width="60" align="center">
                <template #default="scope">
                    <el-text :type="scope.row.block ? 'danger' : 'success'">
                        {{ scope.row.block ? '冻结' : '正常' }}
                    </el-text>

                </template>
            </el-table-column>
        </el-table>
    </div>

    <!-- 设置用户资料 -->
    <el-dialog v-model="dialogChange.visible" title="设置用户资料" width="310px" style="height: 370px;">
        <el-form label-width="70px">
            <!-- 用户名 -->
            <el-form-item label="用 户 名">
                <!-- <el-input v-model="dataModel.username" autocomplete="off" /> -->
                <el-text>{{ dataModel.current.row.username }}</el-text>
            </el-form-item>

            <!-- 密码 inputPassword -->
            <el-form-item label="修改密码">
                <el-input v-model="dataModel.inputPassword.value" autocomplete="off" />
            </el-form-item>

            <!-- 手机 inputPhone -->
            <el-form-item label="修改手机">
                <el-input v-model="dataModel.inputPhone.value" autocomplete="off" />
            </el-form-item>

            <!-- 选择推荐人用户 selectReferer -->
            <el-form-item label="修改推荐" v-if="stoneAction.isAdmin()">
                <el-input v-model="dataModel.selectReferer.inputValue" placeholder="搜索" @focus="focusSelectReferer()"
                    @blur="blurSelectReferer()" @keyup.enter="clickSelectReferer()">
                    <template #prepend>
                        <el-select :style="{ width: dataModel.selectReferer.width }"
                            :placeholder="dataModel.selectReferer.hint" v-model="dataModel.selectReferer.value">
                            <el-option v-for="item in dataModel.selectReferer.list" :key="item.uid"
                                :label="`${item.username}, ${item.phone}`" :value="item.uid" />
                        </el-select>
                    </template>
                    <template #append>
                        <el-button :icon="Search" @click="clickSelectReferer()" />
                    </template>
                </el-input>
            </el-form-item>

            <el-row>
                <!-- 冻结帐号 checkSetBlock -->
                <el-col :span="12" class="right-align">
                    <el-checkbox label="冻结" v-model="dataModel.checkSetBlock.value" v-if="stoneAction.isAdmin()" />
                </el-col>
                <!-- 提交修改 buttonSetUser -->
                <el-col :span="12" class="right-align">
                    <el-button type="primary" @click="submitButtonSetUser()">修 改</el-button>
                </el-col>
            </el-row>
        </el-form>
    </el-dialog>

    <!-- 查看联系方式 -->
    <el-dialog v-model="dialogPhone.visible" title="联系方式" width="220px" style="height: 200px;">
        <el-form label-width="70px">
            <!-- 用户名 -->
            <el-form-item label="用 户 名">
                <!-- <el-input v-model="dataModel.username" autocomplete="off" /> -->
                <el-text>{{ current.row.username }}</el-text>
            </el-form-item>

            <!-- 密码 inputPassword -->
            <el-form-item label="电  话">
                <el-link type="primary" :underline="false" @click="callPhone(current.row.phone)">{{ current.row.phone
                }}</el-link>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script setup>
import { reactive, computed } from 'vue'
import { ElMessage, ElTable } from 'element-plus'
import { Search } from '@element-plus/icons-vue'
import webService from '@/components/webService'
import stoneAction from '@/util/stoneAction'
import adminApi from '@/api/adminApi'

const dataModel = reactive({
    checkTeacher: { value: false },
    inputUser: { value: '' },
    checkBlock: { value: false },
    tables: { data: [] },
    dialogChange: { visible: false },
    dialogPhone: { visible: false },
    current: { row: {} },

    /***
    * dialog
    */
    checkSetBlock: { value: '' },
    inputPassword: { value: '' },
    inputPhone: { value: '' },
    selectReferer: {
        value: '',
        list: [],
        disabled: true,
        visible: false,
        hint: '选择用户',
        inputValue: '',
        width: '100px',
        minWidth: '40px',
        maxWidth: '100px'
    },
})

const { inputUser, checkBlock, checkTeacher, tables, current, dialogChange,
    selectReferer, checkSetBlock, inputPassword, inputPhone, dialogPhone } = dataModel

// 提交设置用户
const submitButtonSetUser = () => {
    setUser()
}

// 提交获取用户
const submitInputUser = () => {
    adminApi.findUser(inputUser.value, checkTeacher.value, true).then(res => {
        if (res.data) {
            tables.data = res.data
        }
    })
}

// 初始化 dialog
const clickCell = (row, column) => {

    current.row = row
    if (column.index === 1) {
        dialogPhone.visible = true
    } else {
        selectReferer.value = ''
        inputPassword.value = ''
        inputPhone.value = ''
        checkSetBlock.value = current.row.block ? true : ''
        dialogChange.visible = true
    }
}

// 搜索推荐人
const clickSelectReferer = () => {
    webService.findUser(selectReferer, false)
}

// 余额排序
const sortBalance = (a, b) => {
    return a.balance - b.balance
}

// 样式变化
const focusSelectReferer = () => {
    selectReferer.width = selectReferer.minWidth
}

// 样式变化
const blurSelectReferer = () => {
    selectReferer.width = selectReferer.maxWidth
}

// 过滤数据
const filterTableData = computed(() =>
    tables.data.filter((data) =>
        checkBlock.value ? data.block : !data.block
    )
)

//利用单元格的 className 的回调方法，给行列索引赋值
const tableCellClassName = ({ row, column, rowIndex, columnIndex }) => {
    row.index = rowIndex
    column.index = columnIndex
}

// 修改用户资料
function setUser() {
    webService.setUser(dataModel).then(res => {
        // console.log(res)
        if (res.success) {
            ElMessage.success('修改成功')
            current.row.block = checkSetBlock.value
            dialogChange.visible = false
        } else {
            ElMessage.error(res.error)
        }
    })
}

// 呼叫电话
function callPhone(phoneNumber) {
    window.location.href = `tel://${phoneNumber}`
}

</script>

<style lang="scss" scoped></style>