<template>
    <!--  v-model:datePicker="datePicker.value" -->
    <el-row justify="start" :gutter="5" class="lockHeight" style="margin-left: 10px;">
        <!-- dateStart dateEnd -->
        <!-- 选择日期 -->
        <DatePickerDouble v-model:datePicker="datePicker.value" />
        <el-button :icon="Search" @click="clickButtonSearch()" style="margin-left: 10px;" />
    </el-row>

    <!-- table -->
    <el-table :data="dataModel.tables.data" highlight-current-row border stripe show-summary sum-text="合计"
        :height="tableHeight">

        <el-table-column type="index" width="40" prop="mid" align="center" />

        <el-table-column property="time" label="时间" width="auto" :formatter="formatterDatetime"
            align="center" />

        <el-table-column v-if="showUserName" property="username" label="姓名" width="auto" align="center" />
        <el-table-column property="remark" label="类型" width="auto" align="center" />
        <el-table-column v-if="showFromName" property="fname" label="来源" width="auto" align="center" />
        <el-table-column property="score" label="积分" width="auto" align="center" />
        <!-- <el-table-column v-if="showUserName" property="username" label="姓名" width="auto" :min-width="110" align="center" />
        <el-table-column property="remark" label="类型" width="auto" :min-width="80" align="center" />
        <el-table-column v-if="showFromName" property="fname" label="来源" width="auto" :min-width="80" align="center" />
        <el-table-column property="score" label="积分" width="auto" :min-width="60" align="center" /> -->
    </el-table>
</template>

<script setup>
import { reactive } from 'vue'
import { ElTable } from 'element-plus'
import { Search } from '@element-plus/icons-vue'
import moment from 'moment-timezone'
import userApi from '@/api/UserApi'
import DatePickerDouble from '@/components/control/DatePickerDouble.vue'
import { ElMessage } from 'element-plus'

const dataModel = reactive({
    datePicker: { value: [], defaultTime: '' },
    tables: { data: [] },
})

const props = defineProps({
    showUserName: { default: false },
    showFromName: { default: false },
    uid: { default: 0 },
    tableHeight: { default: 500 }
})

const { tables, datePicker } = dataModel

// 获取积分明细
const clickButtonSearch = () => {
    getScore()
}

// 格式化时间
const formatterDatetime = (row, column, cellValue, index) => {
    return moment(row.time).format('YYYY-MM-DD HH:mm')
}

// 获取积分明细
async function getScore() {
    const [tStart, tEnd] = datePicker.value
    const timeStart = moment(tStart).format('YYYY-MM-DD')
    const timeEnd = moment(tEnd).format('YYYY-MM-DD')
    const res = await userApi.getScore(props.uid, timeStart, timeEnd)

    if (res.data) {
        // console.log(res.data)
        tables.data = res.data
    } else {
        ElMessage.error(res.error)
    }
}

</script>

<style lang="scss" scoped></style>