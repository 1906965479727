<template>
    <div class="div-container">
        <!-- <div id="app">
    <my-component checkTeacher="Hello"  ></my-component>
  </div> -->

        <el-row justify="center" class="lockHeight">
            <!-- <el-col :span="4">
                {{ vk.height }}  {{ store.state.windowSize.height }}
            </el-col> -->
            <!-- selectUser -->
            <el-col :span="8" :push="2">
                <el-input v-model="inputSearchUser.value" placeholder="搜索用户" @keyup.enter="keyupSelectUser()">
                    <template #append>
                        <el-button :icon="Search" @click="clickSelectUser()" />
                    </template>
                </el-input>
            </el-col>
        </el-row>

        <!-- table -->
        <el-table :data="dataModel.tables.data" highlight-current-row border stripe show-summary sum-text="合计"
            :height="stoneAction.clientHeight(0)" @cell-click="clickTableCell" :cell-class-name="tableCellClassName">
            <!-- <el-table-column type="index"></el-table-column> -->

            <el-table-column type="index" width="40" prop="uid" align="center" />

            <el-table-column property="username" label="姓名" width="auto" align="center" />
            <el-table-column property="phone" label="手机" width="auto" align="center" />
            <!-- headerBalance -->
            <el-table-column property="balance" label="余额" width="auto" align="center" sortable
                :sort-method="clickHeaderBalance" />

            <el-table-column property="score" label="积分" width="auto" align="center" sortable
                :sort-method="clickHeaderScore" />
        </el-table>
    </div>

    <!-- dialogBalance 弹出窗口 充值 -->
    <el-dialog v-model="dialogBalance.visible" @open="openDialogBalance" title="充值系统" width="300px" style="height: 320px;">
        <el-form label-width="70px">

            <!-- 用户 current -->
            <el-row justify="center" style="margin-top: 0px;font-size: 16px;">
                <el-col :span="10">
                    <el-text size="large" type="primary">
                        {{ dataModel.current.row.username }}</el-text>
                </el-col>
                <el-col :span="12">
                    <el-text size="large">余额：</el-text>
                    <el-text size="large" type="danger">
                        {{ dataModel.current.row.balance }}</el-text>
                </el-col>
            </el-row>

            <!-- :controls="false" -->
            <!-- 充值金额 inputMoney  -->
            <el-form-item label="充值金额">
                <el-input placeholder="设置金额" type="number" v-model="inputMoney.value" style="width: 100%" />
            </el-form-item>

            <!-- 充值渠道备注  selectRemark  :style="{ width: dataModel.selectRemark.width }" -->
            <el-form-item label="充值渠道">
                <el-select placeholder="选择" v-model="dataModel.selectRemark.value" filterable allow-create>
                    <el-option v-for="item in dataModel.selectRemark.list" :key="item.value" :label="item.value"
                        :value="item.value" />
                </el-select>

                <!-- <el-input v-model="dataModel.selectRemark.inputValue" placeholder="手动填写"
                    :disabled="dataModel.selectRemark.disabled">
                    <template #prepend>

                    </template>
                </el-input> -->
            </el-form-item>

            <!-- 充值时间 datePicker -->
            <el-form-item label="充值时间">
                <el-date-picker v-model="datePicker.value" type="date" placeholder="选择日期" />
            </el-form-item>
        </el-form>

        <!-- 提交确认 buttonRecharge -->
        <el-row justify="end" align="middle">
            <el-button type="primary" plain @click="clickButtonRecharge()">确 认</el-button>
        </el-row>

    </el-dialog>


    <!-- dialogScore 弹出窗口 兑换积分 -->
    <el-dialog v-model="dialogScore.visible" @open="openDialogScore" title="积分兑换" width="300px" style="height: 270px;">
        <el-form label-width="70px">

            <!-- 用户 current -->
            <el-row justify="center" style="margin-top: 0px;font-size: 16px;">
                <el-col :span="10">
                    <el-text size="large" type="primary">
                        {{ dataModel.current.row.username }}</el-text>
                </el-col>
                <el-col :span="12" style="display: flex; justify-content: flex-end;">
                    <el-text size="large">积分：</el-text>
                    <el-text size="large" type="danger">
                        {{ dataModel.current.row.score }}</el-text>
                </el-col>
            </el-row>

            <!-- 兑换积分 inputScore  -->
            <el-form-item label="兑换积分">
                <el-input placeholder="设置积分" type="number" v-model="dataModel.inputScore.value" />
            </el-form-item>

            <!-- 充值时间 datePicker -->
            <el-form-item label="兑换时间">
                <el-date-picker v-model="datePicker.value" type="date" placeholder="选择日期" />
            </el-form-item>
        </el-form>

        <!-- 提交确认 buttonRecharge -->
        <el-row justify="end" align="middle">
            <el-button type="primary" plain @click="clickButtonScore()">确 认</el-button>
        </el-row>

    </el-dialog>
</template>

<script setup>
import { reactive } from 'vue'
import { ElTable, ElMessage, ElMessageBox } from 'element-plus'
import { Search } from '@element-plus/icons-vue'
import adminApi from '@/api/adminApi'
import moment from 'moment-timezone'
import stoneAction from '@/util/stoneAction'


const dataModel = reactive({

    checkTeacher: { value: true },
    inputSearchUser: { value: '' },
    tables: { data: [] },

    inputMoney: { value: '' },
    selectRemark: {
        value: '', inputValue: '', width: '120px',
        list: [
            { value: '微信' }, { value: '支付宝' }, { value: '银行' },
            { value: '试听' }, { value: '补课时' }, { value: '积分兑换' }, { value: '退款' }, { value: '其它' }
        ],
        disabled: true
    },
    dialog: { visible: false },
    dialogBalance: { visible: false },
    dialogScore: { visible: false },
    current: {          // 选中的cell数据
        row: {},        // 选中的数据
    },

    inputScore: { value: '' },
    datePicker: { value: '' }
})

const { tables, inputSearchUser, inputMoney, current, dialogBalance, dialogScore,
    selectRemark, inputScore, datePicker } = dataModel


// onMounted(() => {
//     const vhk = vhCheck()
//     vk.height = vhk.windowHeight
//     // console.log(vhk.windowHeight, vhk.vh)
// })

// const autoHeight = computed(() => {
//     const { divContainerMargin, rowHeight } = config.windowHeight
//     return stoneAction.clientHeight(divContainerMargin + rowHeight)
// })

// 对话框初始化
const openDialogBalance = () => {
    inputMoney.value = ''
    selectRemark.value = ''
    selectRemark.inputValue = ''
    datePicker.value = moment().format('YYYY-MM-DD')
}

const openDialogScore = () => {
    inputScore.value = ''
    datePicker.value = moment().format('YYYY-MM-DD')
}

// 获取用户积分和余额
const keyupSelectUser = () => {
    findUserScore(dataModel)
}

// 搜索用户
const clickSelectUser = () => {
    keyupSelectUser()
}

// 充值
const clickButtonRecharge = () => {
    setBalance()
}

// 兑换积分
const clickButtonScore = () => {
    setScore()
}

// 显示 dialog
const clickTableCell = (row, column) => {
    // initScorePoolDialog()

    current.row = row

    switch (column.index) {
        case 3:
            dialogBalance.visible = true
            break
        case 4:
            dialogScore.visible = true
    }
}

// 余额排序
const clickHeaderBalance = (a, b) => {
    return a.balance - b.balance
}

// 积分排序
const clickHeaderScore = (a, b) => {
    return a.score - b.score
}

// 设置单元格  index
const tableCellClassName = ({ row, column, rowIndex, columnIndex }) => {
    //利用单元格的 className 的回调方法，给行列索引赋值
    row.index = rowIndex
    column.index = columnIndex
}

// 初始化 ScorePoolDialog
function initScorePoolDialog() {

    inputMoney.value = ''
    selectRemark.value = ''
    selectRemark.inputValue = ''
    inputScore.value = ''
}

// 获取用户积分和余额，自动屏蔽冻结用户
async function findUserScore() {
    const res = await adminApi.findUserScore(inputSearchUser.value)
    if (res.data) {
        inputSearchUser.value = ''
        tables.data = res.data
    }
}

// 设置积分
async function setScore() {
    const res = await adminApi.setScore(
        current.row.uid,
        Math.abs(inputScore.value) * -1,
        moment(datePicker.value).format('YYYY-MM-DD HH:mm:ss')
    )

    if (res.success) {
        current.row.score = (Number(current.row.score) - Number(inputScore.value)).toFixed(2)
        dialogScore.visible = false
        ElMessageBox.alert(`已为 ${current.row.username} 成功兑换 ${inputScore.value} 点`, '兑换积分')
    } else {
        ElMessage.error(res.error)
    }

}

// 充值
async function setBalance() {
    const res = await adminApi.setBalance(
        current.row.uid,
        inputMoney.value,
        moment(datePicker.value).format('YYYY-MM-DD HH:mm:ss'),
        selectRemark.value
    )

    if (res.success) {
        current.row.balance = (Number(current.row.balance) + Number(inputMoney.value)).toFixed(2)
        dialogBalance.visible = false
        ElMessageBox.alert(`已为 ${current.row.username} 成功充值 ${inputMoney.value} 元`, '充值续费')
    } else {
        ElMessage.error(res.error)
    }
}

</script>

<style lang="scss" scoped>
// 控制el-row上下间隙
</style>