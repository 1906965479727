<template>
    <el-header :style="{ height: config.windowHeight.topHeader + 'px'}">
        <!-- <button @click="handleCollapsed">隐藏菜单</button> -->

        <!-- buttonCollapsed -->
        <div class="left">
            <el-icon @click="clickButtonCollapsed()">
                <Menu />
            </el-icon>
            <span style="margin-left: 20px;">微妈英语</span>
        </div>

        <div class="right-align">
            <span style="margin-right: 20px;">欢迎 {{ store.state.userInfo.username }} 回来</span>

            <el-dropdown>
                <span class="el-dropdown-link">
                    <el-icon size="25">
                        <User />
                    </el-icon>
                </span>

                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item @click="handleCenter">个人设置</el-dropdown-item>
                        <el-dropdown-item @click="handleLoginOut">退出</el-dropdown-item>
                    </el-dropdown-menu>
                </template>

            </el-dropdown>
        </div>
    </el-header>
</template>

<script setup>
import { Menu, User } from '@element-plus/icons-vue'
import config from '@/components/config/Config'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

// 只有当 宽度 < 750，并且为折叠状态时，才会生效
const clickButtonCollapsed = () => {

    if (store.state.isCollapsed && window.innerWidth < 750) {
        // console.log('改变 Collapsed')
        store.commit('changeCollapsed')
    }
    // store.commit('changeCollapsed')
    // console.log(document.documentElement.clientWidth, window.innerWidth, store.state.isCollapsed)
}

const handleCenter = () => {
    router.push('/center')
}

const handleLoginOut = () => {
    localStorage.removeItem('token')
    store.commit('clearUserInfo')
    router.push('/login')
}

</script>

<style lang="scss" scoped>
.el-header {
    background-color: rgb(167, 220, 245);
    color: white;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.el-icon {
    cursor: pointer;
    color: white;
}

.el-dropdown-link {
    margin: auto;
    outline: none;
}
</style>