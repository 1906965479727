<template>
    <div class="div-container">
        <el-card>
            <!-- <template #header>
                <div class="card-header">
                </div>
            </template> -->

            <el-row justify="space-between">
                <el-col :span="16">
                    <el-row justify="center">
                        <el-col :span="8" style="min-width: 90px;">
                            <div class="block"><el-avatar :size="80" :src="userAvatar" /></div>
                        </el-col>

                        <el-col :span="8" style="text-align: left; ">
                            <el-text>{{ userInfo.data.username }}</el-text>
                        </el-col>
                    </el-row>
                </el-col>

                <!-- buttonSetting -->
                <el-col :span="1" style="min-width: 90px; margin-right: 20px;" class="right-align" @click="buttonSettingClick">
                    <el-icon>
                        <Setting />
                    </el-icon>
                </el-col>
            </el-row>

            <!-- 个人信息 -->
            <el-row  justify="space-between" style="padding: 10px;">
                <el-col :span="8"><el-text>余额：</el-text><el-text type="danger">{{ userInfo.data.balance }}</el-text>
                </el-col>
                <el-col :span="8" class="center-align"><el-text>推荐人：</el-text><el-text type="danger">{{
                    userInfo.data.refererCount }}</el-text>
                </el-col>
                <el-col :span="8" class="right-align"><el-text>积分：</el-text><el-text type="danger">{{ userInfo.data.score
                }}</el-text> </el-col>
            </el-row>
        </el-card>

        <el-card>
            <!-- <template #header>
                <div class="card-header">
                </div>
            </template> -->

            <el-row :gutter="20" justify="center">
                <template v-for="child in userInfo.data.children">
                    <el-col :span="8" class="center-align">
                        <el-row justify="center">
                            <el-avatar :size="80" :src="child.avatar ? child.avatar : avatar.circleUrl" />
                        </el-row>
                        <el-row justify="center">
                            <el-text type="primary">{{ calculateAge(child.birthday) }} 岁</el-text>
                        </el-row>
                        <el-row justify="center">
                            <el-text>{{ child.e_name }}</el-text>
                        </el-row>
                    </el-col>
                </template>
            </el-row>
        </el-card>
    </div>


    <el-dialog v-model="dialog.visible" title="设置用户资料" width="310px" style="height: 370px;">
        <el-form label-width="70px">

            <!-- 密码 inputPassword -->
            <el-form-item label="当前密码">
                <el-input v-model="inputPassword.old" autocomplete="off" />
            </el-form-item>

            <el-form-item label="新的密码">
                <el-input v-model="inputPassword.new" autocomplete="off" />
            </el-form-item>
            <el-form-item label="确认密码">
                <el-input v-model="inputPassword.confirm" autocomplete="off" />
            </el-form-item>

            <el-row justify="end">
                <!-- 提交修改 buttonSetUser -->
                <el-button type="primary" @click="buttonSetUserSubmit">修 改</el-button>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script setup>
import { reactive, computed, onMounted } from 'vue'
import { Setting } from '@element-plus/icons-vue'
import userApi from '@/api/UserApi'
import moment from 'moment-timezone'
import { ElMessage } from 'element-plus'

const dataModel = reactive({
    userInfo: { data: {} },
    avatar: {
        circleUrl:
            'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
        squareUrl:
            'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png',
    },
    inputPassword: { old: '', new: '', confirm: '' },
    dialog: { visible: false }
})

const { userInfo, avatar, dialog, inputPassword } = dataModel

// 打开网页加载用户信息
onMounted(() => {
    getUser()
})

// 获取用户头像
const userAvatar = computed(() => {
    return userInfo.data.avatar || avatar.circleUrl
})

// 获取年龄
const calculateAge = (birthDate) => {
    const currentDate = moment()
    return currentDate.diff(birthDate, 'y')
}

// 点击设置，修改密码
const buttonSettingClick = () => {
    dialog.visible = true
}

// 提交修改信息
const buttonSetUserSubmit = async () => {
    if( inputPassword.new !== inputPassword.confirm){
        ElMessage.error('两次密码不一致')
        return
    }

    const res = await userApi.setUserPassword(inputPassword.old, inputPassword.new)
    if(res.success){
        ElMessage.success('修改成功')
    } else {
        ElMessage.error('修改失败')
    }

    dialog.visible = false
}

// 获取用户信息
async function getUser() {
    const res = await userApi.getUser()
    if (res.data && res.data[0]) {
        userInfo.data = res.data[0]
        // console.log(userInfo)
    }
}

</script>

<style lang="scss" scoped>
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1px;
}

.card-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10px;
}
</style>