<template>
    <div class="div-border" style="padding: 20px;">
        <!-- 抬头 -->
        <el-row justify="center" align="middle" style="border: 0px; padding: 20px;">
            <el-text size="large" type="primary" style="font-weight: bold;">STUDENT'S PROGRESS REPORT</el-text>
        </el-row>
        <!-- 左侧 属性等 -->
        <el-row align="middle" style="padding: 10px;border-right: 1px solid #ddd;border-top: 1px solid #ddd;">
            <el-col :span="12">
                <div style="padding: 5px;"><el-text type="success">5 – Outstanding 杰出</el-text></div>
                <div style="padding: 5px;"><el-text type="primary">4 – Proficient 精通</el-text></div>
                <div style="padding: 5px;"><el-text type="info">3 – Adequate 足够</el-text></div>
                <div style="padding: 5px;"><el-text type="warning">2 – Needs Improvement 需要改进</el-text></div>
                <div style="padding: 5px;"><el-text type="danger">1 – Deficient 缺乏</el-text></div>
            </el-col>

            <el-col :span="12">
                <!-- itemTeacher Student Date  -->
                <el-form label-width="130px">
                    <!-- <el-form-item style="margin-bottom: 5px;" label="Teacher 老师：">{{ formItem.teacher }}</el-form-item> -->
                    <el-form-item label="Student 学生：">{{ current.cname }}</el-form-item>
                    <!-- <el-form-item label="Date 日期：">{{ formItem.date }}</el-form-item> -->
                    <!-- 进度 learningProgress -->
                    <el-form-item label="Progress 进度："><el-input v-model="selectList.learningProgress"
                            placeholder="Please input" /></el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <!-- 听力 selectListening -->
        <el-row align="middle">
            <el-col :span="8" class="center-text">
                <div><el-text>Listening skills</el-text></div>
                <div><el-text>听 力</el-text></div>
            </el-col>

            <el-col :span="16">
                <!-- 词汇 ListenVocabulary -->
                <SelectReport textEng="vocabulary" textCn="词    汇" @updateSelect="updateListenVocabulary"
                    backgroundColor="rgb(247, 247, 247)" />

                <!-- 理解 ListenComprehension -->
                <SelectReport textEng="comprehension" textCn="理    解" @updateSelect="updateListenComprehension"
                    class="no-border-bottom" />
            </el-col>
        </el-row>

        <!-- 口语 -->
        <el-row align="middle">
            <el-col :span="8" class="center-text">
                <div><el-text>Speaking skills</el-text></div>
                <div><el-text>口 语</el-text></div>
            </el-col>

            <el-col :span="16">
                <!-- 口语 理解 pronunciation -->
                <SelectReport textEng="pronunciation" textCn="发    音" @updateSelect="updatePronunciation"
                    backgroundColor="rgb(247, 247, 247)" />
                <!-- 口语 复述细节 retellDetails -->
                <SelectReport textEng="retell details" textCn="复述细节" @updateSelect="updateRetellDetails" />
                <!-- 口语 清楚表达 articulatesClearly -->
                <SelectReport textEng="articulates clearly" textCn="清楚表达" @updateSelect="updateArticulatesClearly"
                    backgroundColor="rgb(247, 247, 247)" class="no-border-bottom" />
            </el-col>
        </el-row>

        <!-- 阅读 -->
        <el-row align="middle">
            <el-col :span="8" class="center-text">
                <div><el-text>Reading skills</el-text></div>
                <div><el-text>阅 读</el-text></div>
            </el-col>

            <el-col :span="16">
                <!-- 阅读 理解 readComprehension -->
                <SelectReport textEng="comprehension" textCn="理    解" @updateSelect="updateReadComprehension" />
                <!-- 阅读 语法 grammar -->
                <SelectReport textEng="grammar" textCn="语    法" @updateSelect="updateGrammar"
                    backgroundColor="rgb(247, 247, 247)" />
                <!-- 阅读 词汇 readVocabulary -->
                <SelectReport textEng="vocabulary" textCn="词    汇" @updateSelect="updateReadVocabulary"
                    class="no-border-bottom" />
            </el-col>
        </el-row>

        <!-- 写作 Writing skills -->
        <el-row align="middle">
            <el-col :span="8" class="center-text">
                <div><el-text>Writing skills</el-text></div>
                <div><el-text>写 作</el-text></div>
            </el-col>

            <el-col :span="16">
                <!-- 写作 拼写 spelling -->
                <SelectReport textEng="spelling" textCn="拼    写" @updateSelect="updateSpelling"
                    backgroundColor="rgb(247, 247, 247)" />
                <!-- 写作 句型 grammar -->
                <SelectReport textEng="sentence pattern" textCn="句    型" @updateSelect="updateSentencePattern" />
                <!-- 写作 条理 writingInOrder -->
                <SelectReport textEng="writing in order" textCn="条    理" @updateSelect="updateWritingInOrder"
                    backgroundColor="rgb(247, 247, 247)" class="no-border-bottom" />
            </el-col>
        </el-row>

        <!-- 社会和情感发展 -->
        <el-row align="middle">
            <el-col :span="8" class="center-text">
                <div><el-text>Social and Emotional Development</el-text></div>
                <div><el-text>社会和情感发展</el-text></div>
            </el-col>

            <el-col :span="16">
                <!-- 社会 寻求帮助 asksForHelp -->
                <SelectReport textEng="asks for help when needed" textCn="需要时寻求帮助" @updateSelect="updateAsksForHelp" />
                <!-- 社会 自信 selfConfidence -->
                <SelectReport textEng="exhibits self-confidence" textCn="表现出自信" @updateSelect="updateSelfConfidence"
                    backgroundColor="rgb(247, 247, 247)" />
                <!-- 社会 讨论 discussions -->
                <SelectReport textEng="participate the classroom discussions" textCn="参与课堂讨论"
                    @updateSelect="updateDiscussions" />
                <!-- 社会 注意力 attention -->
                <SelectReport textEng="sustains attention in class" textCn="在课堂上保持注意力" @updateSelect="updateAttention"
                    backgroundColor="rgb(247, 247, 247)" />
                <!-- 社会 兴趣 interest -->
                <SelectReport textEng="demonstrates an interest in learning" textCn="表现出对学习的兴趣"
                    @updateSelect="updateInterest" class="no-border-bottom" />
            </el-col>
        </el-row>

        <el-row align="middle">
            <el-col :span="8" class="center-text">
                <div><el-text>Teacher's observation</el-text></div>
                <div><el-text>老师观察</el-text></div>
            </el-col>

            <el-col :span="16">
                <el-input v-model="selectList.observation" :rows="5" type="textarea" placeholder="Please input"
                    class="no-border-bottom" />
            </el-col>
        </el-row>

        <el-row align="middle">
            <el-col :span="8" class="center-text">
                <div><el-text>Teacher's advice</el-text></div>
                <div><el-text>老师建议</el-text></div>
            </el-col>

            <el-col :span="16">
                <el-input v-model="selectList.advice" :rows="5" type="textarea" placeholder="Please input"
                    class="no-border-bottom" />
            </el-col>
        </el-row>

        <!-- buttonAddReport -->
        <el-row class="right-align" style="padding: 10px;border: 0px;">
            <el-button type="primary" @click="submitButtonAddReport()">提 交</el-button>
        </el-row>
    </div>
    <!-- <StoreAction :key="cid" /> -->
</template>

<script setup>
import { reactive, onMounted, onBeforeUnmount } from 'vue'
import { ElMessage } from 'element-plus'
import SelectReport from '@/components/control/SelectReport.vue'
import stoneAction from '@/util/stoneAction'
import teachApi from '@/api/teachApi'
import { useRouter } from 'vue-router'
const router = useRouter()

const dataModel = reactive({
    formItem: { teacher: '', student: '', date: '' },
    selectStudents: { value: '', list: [] },
    selectList: {
        // 学生ID
        cid: '',
        // 学习进度
        learningProgress: '',
        // 听力 词汇
        listenVocabulary: '',
        // 听力 理解
        listenComprehension: '',
        // 口语 发音
        pronunciation: '',
        // 口语 复述细节
        retellDetails: '',
        // 口语 清楚表达
        articulatesClearly: '',
        // 阅读 理解
        readComprehension: '',
        // 阅读 语法
        grammar: '',
        // 阅读 词汇
        readVocabulary: '',
        // 写作 拼写
        spelling: '',
        // 写作 句型
        sentencePattern: '',
        // 写作 条理
        writingInOrder: '',
        // 社会 寻求帮助
        asksForHelp: '',
        // 社会 自信
        selfConfidence: '',
        // 社会 讨论
        discussions: '',
        // 社会 注意力
        attention: '',
        // 社会 兴趣
        interest: '',
        // 老师观察
        observation: '',
        // 建议
        advice: '',
    },
    current: { cname: '' },
    options: { list: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }] }
})

const { selectList, current } = dataModel

// 加载对应的 cid，每次保存 hid 到 stone
onMounted(() => {
    const data = stoneAction.getAction('ReportAdd')

    if (!data || !data.cid || !data.cname) {
        router.push('/404')
    } else {
        selectList.cid = data.cid
        current.cname = data.cname
    }
})

// 在组件销毁前清理数据或取消订阅
onBeforeUnmount(() => {
    stoneAction.delAction('ReportAdd')
})

// 提交报告
const submitButtonAddReport = () => {
    addReport()
}

async function addReport() {
    const res = await teachApi.addReport(selectList)
    if (res.success) {
        ElMessage.success('Added successfully')
        router.push(`/classroom/ReportList`)
        // console.log(res)
    } else {
        ElMessage.error(res.error)
    }
}

// 听力 词汇
const updateListenVocabulary = (val) => {
    selectList.listenVocabulary = val
}
// 听力 理解
const updateListenComprehension = (val) => {
    selectList.listenComprehension = val
}
// 口语 发音
const updatePronunciation = (val) => {
    selectList.pronunciation = val
}
// 口语 复述细节
const updateRetellDetails = (val) => {
    selectList.retellDetails = val
}
// 口语 清楚表达
const updateArticulatesClearly = (val) => {
    selectList.articulatesClearly = val
}
// 阅读 理解
const updateReadComprehension = (val) => {
    selectList.readComprehension = val
}
// 阅读 语法
const updateGrammar = (val) => {
    selectList.grammar = val
}
// 阅读 词汇
const updateReadVocabulary = (val) => {
    selectList.readVocabulary = val
}
// 写作 拼写
const updateSpelling = (val) => {
    selectList.spelling = val
}
// 写作 句型
const updateSentencePattern = (val) => {
    selectList.sentencePattern = val
}
// 写作 条理
const updateWritingInOrder = (val) => {
    selectList.writingInOrder = val
}
// 社会 寻求帮助
const updateAsksForHelp = (val) => {
    selectList.asksForHelp = val
}
// 社会 自信
const updateSelfConfidence = (val) => {
    selectList.selfConfidence = val
}
// 社会 讨论
const updateDiscussions = (val) => {
    selectList.discussions = val
}

// 社会 注意力
const updateAttention = (val) => {
    selectList.attention = val
}

// 社会 兴趣
const updateInterest = (val) => {
    selectList.interest = val
}

</script>

<style lang="scss" scoped>
.el-row {
    margin-top: 0px;
    margin-bottom: 0px;
    border-left: 1px solid #ddd;
    // border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.no-border-bottom {
    border-bottom: 0px solid #ddd;
}

// 追加左右间隔
.center-text {
    padding-left: 10px;
    padding-right: 10px;
}
</style>