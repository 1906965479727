import { store, router } from '../main'
import config from '@/components/config/Config'
// import vhCheck from 'vh-check'


// console.log(vhk.windowHeight, vhk.vh)

const storeAction = {
    clientHeightNotRow(topHeight) {
        const { topHeader, divContainerMargin } = config.windowHeight
        return store.state.windowSize.height - topHeader - divContainerMargin - topHeight
    },
    clientHeight(topHeight) {
        const { rowHeight } = config.windowHeight
        return this.clientHeightNotRow(topHeight + rowHeight)
        // const vhk = vhCheck()
        // console.log(store.state.windowSize.height, vhk.windowHeight)
        // console.log(store.state.windowSize.height, config.windowSize.topHeaderHeight, elHeight )
        // const { topHeader, divContainerMargin, rowHeight } = config.windowHeight
        // return store.state.windowSize.height - topHeader - divContainerMargin - rowHeight - topHeight 
    },
    isTeacher(){
        return store.state.userInfo.identity === config.level.teacher
    },
    isManager(){
        const { identity } = store.state.userInfo
        const { admin, manager } = config.level
        return [admin, manager].includes(identity)
    },
    // 是否为admin
    isAdmin() {
        return store.state.userInfo.identity === config.level.admin
    },
    isUser(){
        const { identity } = store.state.userInfo
        const { admin, manager, user } = config.level
        return [admin, manager, user].includes(identity)  
    },
    commit(name, data) {
        store.commit(name, data)
    },

    getWindowSize() {
        return store.state.windowSize
    },
    // 设置窗口尺寸
    setWindowSize(width, height) {
        store.commit('changeWindowSize', { width: width, height: height })
    },
    
    getAction(key) {
        return store.state.lastAction[key] || ''
    },
    setAction(key, value) {
        // if (typeof keyOrData === 'string') {
        store.commit('changeLastAction', { [key]: value })
        // } else {
        //     store.commit('changeLastAction', keyOrData)
        // }
    },
    // 移除最后的动作
    delAction(key) {
        store.commit('deleteLastAction', key)
    },
    // 设置最后的动作，把 参数 永久化
    // key = query.key   storeName = 对应的 key
    // 例如 query 的参数为 id=100，实际对应的 store 则可能是  ccc_id =
    routeAction(storeName) {

        const { query } = router.currentRoute.value

        // console.log(query)
        if ( Object.keys(query).length > 0) {
            this.setAction(storeName, query)
        //     // console.log(`检查到 ${query[key]}`)
        //     this.setAction(storeName, { [key]: query[key]})
        //     // store.commit('changeLastAction', { [storeName]: query[key] })
        //     // return query[key]
        }

        return this.getAction(storeName)
    },
}



export default storeAction
