<template>
    <div class="div-container">
        <!-- 抬头 -->
        <el-row justify="center" align="middle" style="border: 0px;padding: 20px;">
            <el-text size="large" type="primary" style="font-weight: bold;">STUDENT'S PROGRESS REPORT</el-text>
        </el-row>
        <!-- 左侧 属性等 右侧 基本信息 -->
        <el-row align="middle" style="padding: 10px;border-right: 1px solid #ddd;border-top: 1px solid #ddd;">
            <!-- 左侧 属性等 -->
            <el-col :span="12">
                <div style="padding: 5px;"><el-text type="success">5 – Outstanding 杰出</el-text></div>
                <div style="padding: 5px;"><el-text type="primary">4 – Proficient 精通</el-text></div>
                <div style="padding: 5px;"><el-text type="info">3 – Adequate 足够</el-text></div>
                <div style="padding: 5px;"><el-text type="warning">2 – Needs Improvement 需要改进</el-text></div>
                <div style="padding: 5px;"><el-text type="danger">1 – Deficient 缺乏</el-text></div>
            </el-col>

            <!--  右侧 基本信息 -->
            <el-col :span="12">


                <!-- itemTeacher Student Date  -->
                <el-form label-width="130px">
                    <el-form-item label="Teacher 老师：">{{ tableData.list.username }}</el-form-item>
                    <el-form-item label="Student 学生：">{{ tableData.list.e_name }}</el-form-item>
                    <el-form-item label="Datetime 日期：">
                        {{ moment(tableData.list.time).format('YYYY-MM-DD HH:mm:ss')
                        }}
                    </el-form-item>
                    <el-form-item label="Progress 进度：">{{ tableData.list.learningProgress }}</el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <!-- 听力 selectListening -->
        <el-row align="middle">
            <el-col :span="8" class="center-text">
                <div><el-text>Listening skills</el-text></div>
                <div><el-text>听 力</el-text></div>
            </el-col>

            <el-col :span="16">
                <!-- 词汇 ListenVocabulary -->
                <TextReport textEng="vocabulary" textCn="词    汇" :textScore="tableData.list.listenVocabulary"
                    backgroundColor="rgb(247, 247, 247)" />

                <!-- 理解 ListenComprehension -->
                <TextReport textEng="comprehension" textCn="理    解" :textScore="tableData.list.listenComprehension"
                    class="no-border-bottom" />
            </el-col>
        </el-row>

        <!-- 口语 -->
        <el-row align="middle">
            <el-col :span="8" class="center-text">
                <div><el-text>Speaking skills</el-text></div>
                <div><el-text>口 语</el-text></div>
            </el-col>

            <el-col :span="16">
                <!-- 口语 理解 pronunciation -->
                <TextReport textEng="pronunciation" textCn="发    音" :textScore="tableData.list.pronunciation"
                    backgroundColor="rgb(247, 247, 247)" />
                <!-- 口语 复述细节 retellDetails -->
                <TextReport textEng="retell details" textCn="复述细节" :textScore="tableData.list.retellDetails" />
                <!-- 口语 清楚表达 articulatesClearly -->
                <TextReport textEng="articulates clearly" textCn="清楚表达" :textScore="tableData.list.articulatesClearly"
                    backgroundColor="rgb(247, 247, 247)" class="no-border-bottom" />
            </el-col>
        </el-row>

        <!-- 阅读 -->
        <el-row align="middle">
            <el-col :span="8" class="center-text">
                <div><el-text>Reading skills</el-text></div>
                <div><el-text>阅 读</el-text></div>
            </el-col>

            <el-col :span="16">
                <!-- 阅读 理解 readComprehension -->
                <TextReport textEng="comprehension" textCn="理    解" :textScore="tableData.list.readComprehension" />
                <!-- 阅读 语法 grammar -->
                <TextReport textEng="grammar" textCn="语    法" :textScore="tableData.list.grammar"
                    backgroundColor="rgb(247, 247, 247)" />
                <!-- 阅读 词汇 readVocabulary -->
                <TextReport textEng="vocabulary" textCn="词    汇" :textScore="tableData.list.readVocabulary"
                    class="no-border-bottom" />
            </el-col>
        </el-row>

        <!-- 写作 Writing skills -->
        <el-row align="middle">
            <el-col :span="8" class="center-text">
                <div><el-text>Writing skills</el-text></div>
                <div><el-text>写 作</el-text></div>
            </el-col>

            <el-col :span="16">
                <!-- 写作 拼写 spelling -->
                <TextReport textEng="spelling" textCn="拼    写" :textScore="tableData.list.spelling"
                    backgroundColor="rgb(247, 247, 247)" />
                <!-- 写作 句型 sentencePattern -->
                <TextReport textEng="sentence pattern" textCn="句    型" :textScore="tableData.list.sentencePattern" />
                <!-- 写作 条理 writingInOrder -->
                <TextReport textEng="writing in order" textCn="条    理" :textScore="tableData.list.writingInOrder"
                    backgroundColor="rgb(247, 247, 247)" class="no-border-bottom" />
            </el-col>
        </el-row>

        <!-- 社会和情感发展 -->
        <el-row align="middle">
            <el-col :span="8" class="center-text">
                <div><el-text>Social and Emotional Development</el-text></div>
                <div><el-text>社会和情感发展</el-text></div>
            </el-col>

            <el-col :span="16">
                <!-- 社会 寻求帮助 asksForHelp -->
                <TextReport textEng="asks for help when needed" textCn="需要时寻求帮助" :textScore="tableData.list.asksForHelp" />
                <!-- 社会 自信 selfConfidence -->
                <TextReport textEng="exhibits self-confidence" textCn="表现出自信" :textScore="tableData.list.selfConfidence"
                    backgroundColor="rgb(247, 247, 247)" />
                <!-- 社会 讨论 discussions -->
                <TextReport textEng="participate the classroom discussions" textCn="参与课堂讨论"
                    :textScore="tableData.list.discussions" />
                <!-- 社会 注意力 attention -->
                <TextReport textEng="sustains attention in class" textCn="在课堂上保持注意力" :textScore="tableData.list.attention"
                    backgroundColor="rgb(247, 247, 247)" />
                <!-- 社会 兴趣 interest -->
                <TextReport textEng="demonstrates an interest in learning" textCn="表现出对学习的兴趣"
                    :textScore="tableData.list.interest" class="no-border-bottom" />
            </el-col>
        </el-row>

        <el-row align="middle">
            <el-col :span="8" class="center-text">
                <div><el-text>Teacher's observation</el-text></div>
                <div><el-text>老师观察</el-text></div>
            </el-col>

            <el-col :span="16" class="left-right-border">
                <el-text>{{ tableData.list.observation }}</el-text>
            </el-col>
        </el-row>

        <el-row align="middle">
            <el-col :span="8" class="center-text">
                <div><el-text>Teacher's advice</el-text></div>
                <div><el-text>老师建议</el-text></div>
            </el-col>

            <el-col :span="16" class="left-right-border">
                <el-text>{{ tableData.list.advice }}</el-text>
            </el-col>
        </el-row>
        <el-row>

        </el-row>
    </div>
</template>

<script setup>
import { reactive, onMounted, onBeforeUnmount } from 'vue'
import TextReport from '@/components/control/TextReport.vue'
import userApi from '@/api/UserApi'
import stoneAction from '@/util/stoneAction'
import moment from 'moment-timezone'
import { useRouter } from 'vue-router'
const router = useRouter()

const dataModel = reactive({
    tableData: { list: {} },
})

const { tableData } = dataModel

// 在组件加载时把参数暂时永久化
onMounted(() => {
    const data = stoneAction.routeAction('report')
    const { id } = data
    if (id) {
        getReport(id)
    } else {
        router.push('/404')
    }
})

// 在组件销毁前清理数据或取消订阅
onBeforeUnmount(() => {
    stoneAction.delAction('report')
})


// 获取报告详情
async function getReport(pid) {
    const res = await userApi.getReport(pid)
    // console.log(res)
    if (res.data && res.data[0]) {
        tableData.list = res.data[0]
    } else {
        router.push('/404')
    }
}
</script>

<style lang="scss" scoped>
.el-row {
    margin-top: 0px;
    margin-bottom: 0px;
    border-left: 1px solid #ddd;
    // border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.no-border-bottom {
    border-bottom: 0px solid #ddd;
}

.el-form-item {
    margin-bottom: 4px;
}

// 追加左右间隔
.center-text {
    padding-left: 10px;
    padding-right: 10px;
}

.left-right-border {
    padding: 10px;
    border-left: 1px solid #ddd;
    // border-right: 1px solid #ddd;
    border-right: 1px solid #ddd;
}
</style>