<template>
    <div class="div-container">
        <el-row justify="start" align="middle" :gutter="5" style="margin-left: 10px;">
            <SearchUser 
            @changeSelectUser="changeSelectUser" 
            :showCheckTeacher="true" 
            :inputFold="false" />
        </el-row>

        <ReportTable :start="false" ref="actionSub" />
    </div>
</template>

<script setup>
import { ref } from 'vue'
import ReportTable from '@/components/control/ReportTable.vue'
import SearchUser from '@/components/control/SearchUser.vue'
 
const actionSub = ref()
 
const changeSelectUser = (value) =>{
    console.log(value)
    actionSub.value.findReport_ref(value)
}

</script>

<style lang="scss" scoped>
</style>