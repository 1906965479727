<template>
    <div class="div-container">
        <!-- <div class="div-findUser"> -->
        <el-row justify="end" align="middle" class="lockHeight">

            <!-- <el-col :span="2" style="minWidth: 60px;"> -->
            <el-button type="primary" text @click="getClassOrder(-1)">Last Week</el-button>
            <!-- </el-col> -->
            <!-- <el-col :span="2"> -->
            <el-button type="primary" text style="margin-right: 10px;" @click="getClassOrder(1)">Next week</el-button>
            <!-- </el-col> -->
        </el-row>

        <ClassTable :tables="dataModel.tables" @cell-click="clickCell" />
    </div>
    <!-- adminDialog 弹出窗口设置 -->
    <el-dialog v-model="dialog.visible" width="250px" style="height: 200px;">

        <!-- 显示学生 -->
        <el-row justify="space-between">
            <el-col :span="12">
                <el-text>student:</el-text>
            </el-col>
            <el-col :span="12" style="text-align: right;">
                <el-text>{{ current.row.cname }}</el-text>
            </el-col>
        </el-row>

        <el-row justify="space-between" align="middle" style="margin-top: 20px;">
            <!-- 布置作业 buttonHomework -->
            <el-button type="primary" @click="buttonHomeworkClick">homework</el-button>
            <!-- 写报告 buttonReport  -->
            <el-button type="primary" @click="buttonReportClick">report</el-button>
        </el-row>

    </el-dialog>
</template>

<script setup>
import { reactive, onMounted } from 'vue'
import teachApi from '@/api/teachApi'
import ClassTable from '@/components/control/ClassTable.vue'
import stoneAction from '@/util/stoneAction'
import { useRouter } from 'vue-router'
import string from '@/util/string'
import moment from 'moment-timezone'

const router = useRouter()

const dataModel = reactive({
    tables: { firstDayOfWeek: '', data: [] },
    dialog: { visible: false },
    current: {          // 选中的cell数据
        row: {},        // 选中的数据
        attach: { time: '', week: 0 },       //附加的数据
    },
    weekPage: { value: 0 }  // 获取第几周
})

const { dialog, current, weekPage, tables } = dataModel

onMounted(() => {
    getClassOrder(0)
})

// 布置作业
const buttonHomeworkClick = () => {
    const { kid, cid, cname, time, hid } = current.row
    if (hid) {
        router.push(`/classroom/Homework?id=${hid}`)
    } else {
        // current.attach.time = moment(`${tables.firstDayOfWeek} ${time}`).add(column.index - 1, 'd').format('YYYY-MM-DD HH:mm')
        stoneAction.setAction('HomeworkAdd', { kid, cid, cname, time: current.attach.time })
        router.push(`/classroom/HomeworkAdd`)
    }
}

// 填写报告
const buttonReportClick = () => {
    const { cid, cname } = current.row
    // store.commit('changeLastAction', { [storeName]: query[key] })
    stoneAction.setAction('ReportAdd', { cid, cname })
    router.push('/classroom/ReportAdd')
}

// 单击表格，显示操作面板
const clickCell = ({ row, column, time }) => {
    // if(current.row.cid > 0){
    //     current.row = row
    //     // 显示设置窗口
    //     dialog.visible = true
    // }
 
    // console.log(tables.firstDayOfWeek, time, column.index - 1)
    current.row = row
    // 设置时间为当前
    current.attach.time = moment(`${tables.firstDayOfWeek} ${time}`).add(column.index - 1, 'd').format('YYYY-MM-DD HH:mm')
    // 显示设置窗口
    dialog.visible = current.row.cid > 0
    // 没有则返回，防止误点
    // if (column.index !== 0) {
    //     current.row = !row[column.index] ? {} : row[column.index]
    //     // 显示设置窗口
    //     dialog.visible = current.row.cid > 0
    // console.log(current.row, current.attach.time)
    // }
}

// 获取课程表
async function getClassOrder(page) {
    weekPage.value = string.isInteger(page) ? weekPage.value + page : 0

    const res = await teachApi.getClassOrder(weekPage.value)
    if (res.data) {
        // console.log(res.data[0])
        // dataModel.tables = res.data[0]

        dataModel.tables.firstDayOfWeek = res.data[0].firstDayOfWeek
        dataModel.tables.data = res.data[0].data

    }
}

</script>

<style lang="scss" scoped></style>