<template>
    <div class="div-container">

        <!-- 回复 -->
        <el-card>
            <template #header>
                <el-text type="primary" size="large">sendto : {{ dataModel.current.cname }}</el-text>
            </template>

            <UploadHomework @submitUpload="submitUpload" />
            <!-- 回复内容 inputReply -->
            <!-- <el-row style="margin-bottom: 10px;">
                <el-input v-model="inputContext.value" :rows="8" type="textarea"></el-input>
            </el-row> -->

            <!-- 上传 uploadFile 提交作业 ButtonHomework -->
            <!-- <el-row justify="end" :gutter="10"> -->
            <!-- 上传 uploadFile -->
            <!-- <el-col :span="10"> -->
            <!-- v-model:file-list="fileList"   -->
            <!-- <el-upload v-model:file-list="uploadFiles.data" multiple :limit="3" :auto-upload="false"
                        :on-change="changeUploadFile" :on-remove="removeUploadFile">
                        <el-button type="primary" style="margin-left: 50px;">
                            Upload<el-icon>
                                <Upload />
                            </el-icon>
                        </el-button>
                        <template #tip>
                            <div>
                                <el-text type="warning">files with a size less than 5MB</el-text>
                            </div>
                        </template>
                    </el-upload> -->
            <!-- </el-col> -->
            <!-- 提交作业 ButtonHomework -->
            <!-- <el-col :span="4">
                    <el-button type="primary" @click="submitButtonHomework()">submit</el-button>
                </el-col>
            </el-row> -->
        </el-card>
    </div>
</template>

<script setup>
import { reactive, onMounted, onBeforeUnmount } from 'vue'
import { ElMessage } from 'element-plus'
import teachApi from '@/api/teachApi'
import UploadHomework from './UploadHomework.vue'
import stoneAction from '@/util/stoneAction'
import { useRouter } from 'vue-router'
const router = useRouter()

// 定义变量
const dataModel = reactive({
    current: { cid: 0, cname: '' },
})

// 加载页面时，获取可看学生信息
onMounted(() => {
    const data = stoneAction.getAction('HomeworkAdd')

    if (!data || !data.cid || !data.kid || !data.time) {
        router.push('/404')
    } else {
        dataModel.current = {
            ...dataModel.current,
            ...data
        }
        // console.log(dataModel.current)
    }

})

// 在组件销毁前清理数据或取消订阅
onBeforeUnmount(() => {
    stoneAction.delAction('HomeworkAdd')
})

// 提交作业
const submitUpload = async (val) => {

    const res = await addHomework(val)

    if (res.success) {
        ElMessage.success('添加成功')
        router.push('/teacher-center/TimeTable')
    } else {
        ElMessage.error(res.error)
    }
}

// 发布作业
function addHomework(val) {

    const dataJson = {
        ...dataModel.current,
        context: val.inputContext
    }

    return teachApi.addHomework(dataJson, val.uploadFiles)
}



</script>

<style lang="scss" scoped>
// .div-border {
//     margin: auto;
//     min-width: 500px;
//     width: 80%;
//     border: 3px solid #73AD21;
//     padding: 10px;
// }
</style>