
<template>
    <div class="div-container">
        <el-form label-width="100px" class="el-form-form">

            <!-- inputEname -->
            <el-form-item label="英文名">
                <el-input v-model="dataModel.inputEname.value" autocomplete="off" />
            </el-form-item>

            <!-- inputCname -->
            <el-form-item label="中文名">
                <el-input v-model="dataModel.inputCname.value" />
            </el-form-item>

            <!-- dateBirthday -->
            <el-form-item label="生  日">
                <el-col>
                    <el-date-picker v-model="dataModel.dateBirthday.value" type="date" placeholder="选择日期"
                        style="width: 100%" />
                </el-col>
            </el-form-item>

            <!-- selectParent -->
            <el-form-item label="家  长">
                <SearchUser v-model:selectValue="selectParent.value" ref="actionSelect" />
            </el-form-item>
            <!-- <el-form-item label="测  试">
                <MyButton v-model:selectParent="selectParent.value" ref="actionSelect" />
            </el-form-item> -->
            <el-form-item>
                <el-button style="margin-left: auto;" type="primary" @click="submitForm()">添 加</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { ElMessage } from 'element-plus'
import adminApi from '@/api/adminApi'
import moment from 'moment-timezone'
import SearchUser from '@/components/control/SearchUser.vue'


const actionSelect = ref()
const dataModel = reactive({
    inputEname: { value: '' },
    inputCname: { value: '' },
    dateBirthday: { value: '' },
    selectParent: { value: '' },    //学生选择
})

const { inputEname, inputCname, dateBirthday, selectParent } = dataModel
 
// 提升添加学生表单
const submitForm = () => {
    addStudent()
}

// 初始化
function init() {
    inputEname.value = ''
    inputCname.value = ''
    dateBirthday.value = ''
    actionSelect.value.initSelect() 
}

// 添加学生
async function addStudent() {
    const res = await adminApi.addStudent(
        inputCname.value,
        inputEname.value,
        moment(dateBirthday.value).format('YYYY-MM-DD'),
        selectParent.value
    )

    if (res.success) {
        init()
        ElMessage.success('添加成功')
    } else {
        ElMessage.error(res.error)
    }
}


</script>

<style lang="scss" scoped>
.div-container {
    margin-top: 150px;
    min-width: 300px;
    max-width: 500px;
    width: 40%;
}

.el-form-form {
    max-width: 350px;
}
</style>
