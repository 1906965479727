<template>
    <!-- 回复内容 inputContext -->
    <el-row style="margin-bottom: 10px;">
        <el-input v-model="inputContext.value" :rows="8" type="textarea"></el-input>
    </el-row>

    <!-- 上传 uploadFile 提交作业 ButtonHomework -->
    <el-row justify="end" :gutter="0">
        <!-- 上传 uploadFile -->
        <el-col :span="14">
            <!-- v-model:file-list="fileList"   -->
            <el-upload v-model:file-list="uploadFiles.data" multiple :limit="3" :auto-upload="false"
                :on-change="changeUploadFile" :on-remove="removeUploadFile">
                <el-button type="primary" style="margin-left: 50px;">
                    Upload<el-icon>
                        <Upload />
                    </el-icon>
                </el-button>
                <template #tip>
                    <div>
                        <el-text type="warning">files with a size less than 5MB</el-text>
                    </div>
                </template>
            </el-upload>
        </el-col>
        <!-- 提交作业 ButtonHomework -->
        <el-col :span="6">
            <el-button type="primary" @click="submitButtonHomework()">submit</el-button>
        </el-col>
    </el-row>
</template>

<script setup>
import { reactive } from 'vue'
import { Upload } from '@element-plus/icons-vue'

// 定义变量
const dataModel = reactive({
    uploadFiles: { list: [], data: [] },
    inputContext: { value: '' }
})

const { inputContext, uploadFiles } = dataModel

const emits = defineEmits(['submitUpload'])
defineExpose({ init })

// 初始化
function init() {
    inputContext.value = ''
    uploadFiles.list = []
    uploadFiles.data = []
}

// 提交作业
const submitButtonHomework = async () => {

    emits('submitUpload', {
        inputContext: inputContext.value,
        uploadFiles: uploadFiles.list
    })
}

// 改变上传文件
const changeUploadFile = (file) => {
    // console.log(file.raw)
    if (file.size > 5 * 1024 * 1024) {
        // 如果文件大小超过5M，则移除已添加的文件
        // console.log('移除前剩余', uploadFiles.data.length)
        uploadFiles.data = uploadFiles.data.filter(item => item.uid !== file.uid)
        // ElMessage.error('上传限制大小')
        // console.log('移除后剩余', uploadFiles.data.length)
    } else {
        uploadFiles.list.push(file.raw);
    }
}

// 移除上传文件
const removeUploadFile = (file) => {
    uploadFiles.list = uploadFiles.list.filter(item => item.uid !== file.raw.uid)
}

</script>

<style lang="scss" scoped></style>