<template>
    <div class="div-container">
        <!-- <el-page-header content="homework" icon="" title="wmyy"></el-page-header> -->

        <!-- 展示作业每条信息 -->
        <el-card v-for="item in tables.data" :key="item.wid" style="margin: 0px; padding: 0px;">
            <template #header>
                <el-row justify="space-between" align="middle" style="margin-top: 0px; margin-bottom: 0px;">
                    <div class="middle-align">
                        <div style="width: 40px; height: 40px;">
                            <el-image src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" />
                        </div>
                        <el-text size="large" type="primary" style="margin-left: 20px;"> {{ item.pubname }}</el-text>
                    </div>

                    <el-text size="large" type="primary"> {{ formatPubtime(item.pubtime) }}
                    </el-text>
                </el-row>
            </template>



            <el-row style="margin-bottom: 30px;white-space: pre-wrap;">
                {{ item.homework }}
            </el-row>

            <el-row>
                <template v-for="file in item.files" :key="file.filename">
                    <div>
                        <el-image v-if="isImg(file)" :src="filterFileName(file.path)" />
                    </div>
 
                </template>
            </el-row>

            <el-row>
                <template v-for="file in item.files" :key="file.filename">
 
                    <div>
                        <el-icon>
                            <Link />
                        </el-icon>
                        <el-link type="primary" @click="download(file.path, file.originalname)">
                            {{ file.originalname }} </el-link>
                    </div>
                </template>
            </el-row>

        </el-card>

        <!-- 回复 -->
        <el-card>
            <template #header>
                <el-text type="primary" size="large">Reply : </el-text>
            </template>
            <UploadHomework @submitUpload="submitUpload" ref="refUpload" />
        </el-card>
    </div>

    <el-dialog v-model="dialogUpload.visible" title="上传文件 Upload" width="280px" style="height: 180px;">
        <el-row justify="top" align="top" style="margin-bottom: 20px;">
            <el-text>请稍后 Please wait a moment ......</el-text>
        </el-row>

        <el-progress :percentage="progress.value" />
    </el-dialog>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeUnmount } from 'vue'
import { ElMessage } from 'element-plus'
import { Link } from '@element-plus/icons-vue'
import UploadHomework from './UploadHomework.vue'
import userApi from '@/api/UserApi'
import moment from 'moment-timezone'
import stoneAction from '@/util/stoneAction'
import axios from '@/util/axios'
import { useRouter } from 'vue-router'
const router = useRouter()

const refUpload = ref()

const dataModel = reactive({
    current: { hid: '' },
    tables: { data: [] },
    progress: { value: 0 },
    dialogUpload: { visible: false }
})

const { tables, current, progress, dialogUpload } = dataModel

// 加载对应的 hid，每次保存 hid 到 stone
onMounted(() => {
    const data = stoneAction.routeAction('homework')
    const { id } = data
    if (id) {
        current.hid = id
        getHomework()
    } else {
        router.push('/404')
    }
})

// 在组件销毁前清理数据或取消订阅
onBeforeUnmount(() => {
    stoneAction.delAction('homework')
})

// 原始文件是否为 img 类 image/png
function isImg(file) {
    return /image/.test(file.mimetype)// === 'image/jpeg'
}

function isPdf(file) {
    return file.mimetype === 'application/pdf'
}

// 下载地址
const download = (filepath, originName) => {
    // public/homeworks/2023-10-02
    const file = filepath.replace(/\\/g, '/').replace(/^public/, '')
    // axios.download2(file, originName)
    // console.log(file, originName)
    const link = document.createElement('a')
    link.href = file
    link.download = originName
    // link.setAttribute('download', fileName)
    link.click()
}

const download2 = (filepath, originName) => {
    // public/homeworks/2023-10-02
    const file = filepath.replace(/\\/g, '/').replace(/^public/, '')
    window.open(file)
}

function filterFileName(filepath) {
    return filepath.replace(/\\/g, '/').replace(/^public/, '')
}

// 提交作业
const submitUpload = async (val) => {
    const dataJson = {
        ...dataModel.current,
        context: val.inputContext
    }

    // console.log(dataJson)
    // console.log(val.uploadFiles.length)
    // return

    if (val.uploadFiles.length > 0) {
        dialogUpload.visible = true
    }

    const res = await userApi.setHomework(dataJson, val.uploadFiles, progress)

    dialogUpload.visible = false

    if (res.success) {
        refUpload.value.init()
        getHomework()
        // router.go(0)
    } else {
        ElMessage.error(res.error || '请检查文件格式 Please check the file format')
    }
}

const formatPubtime = (pubtime) => {
    return moment(pubtime).format('YYYY-MM-DD HH:mm:ss')
}

// 获取作业详情
async function getHomework() {
    const res = await userApi.getHomework(current.hid)
    // console.log(res.data)
    if (res.data && res.data[0]) {
        // console.log(res.data)
        tables.data = res.data
    } else {
        router.push('/404')
    }
}

</script>

<style lang="scss" scoped>
.div-border {
    margin: auto;
    min-width: 500px;
    width: 80%;
    border: 3px solid #73AD21;
    padding: 10px;
}

// .el-row {
//     margin-top: 5px;
// }

.div-pubtime {
    margin-top: 0px;
}

.el-text-reply {
    margin-top: 1px;
    border: 3px solid #73AD21;
}

.form-item {
    display: flex;
    margin-right: 10px;
    justify-content: flex-end;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10px;
}
</style>