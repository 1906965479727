<template>
    <div class="div-container">
        <!-- table -->
        <el-table :data="tables.data" highlight-current-row border stripe :height="stoneAction.clientHeightNotRow(0)"
            @row-click="clickTable">
            <!-- <el-table-column type="index"></el-table-column> -->

            <el-table-column type="index" property="uid" width="40" align="center" />

            <!-- <el-table-column property="uid" label="序列id" v-if="false">
                </el-table-column> -->
            <el-table-column property="time" label="时间 time" width="auto" align="center" :formatter="formatTime" />

            <el-table-column property="username" label="老师 teacher" width="auto" align="center" />

            <el-table-column property="e_name" label="学生 student" width="auto" align="center">
                <!-- 翻页箭头 buttonPage -->
                <template #header>
                    <div class="middle-align">
                        <div class="title-student">
                            学生 student
                        </div>
                        <div class="title-button">
                            <el-button-group>
                                <el-button style="width: 2px;" text :disabled="!buttonPage.value" :icon="ArrowLeft"
                                    @click="clickButtonPage(-1)" />
                                <el-button style="width: 2px;" text :disabled="buttonPage.disabled" :icon="ArrowRight"
                                    @click="clickButtonPage(1)" />
                            </el-button-group>
                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script setup>
import { reactive, onMounted } from 'vue'
import { ElMessage, ElTable } from 'element-plus'
import { ArrowLeft, ArrowRight } from '@element-plus/icons-vue'
import userApi from '@/api/UserApi'
import stoneAction from '@/util/stoneAction'
import moment from 'moment-timezone'
import { useRouter } from 'vue-router'
const router = useRouter()

const dataModel = reactive({
    userId: { value: '' },
    tables: { data: [] },
    buttonPage: { value: 0, disabled: true }
})

const props = defineProps({
    start: { default: true }
})

// 让父组件调用
defineExpose({ findReport_ref })

const { tables, buttonPage, userId } = dataModel

// 每页最大数量，修改需对应修改server
const maxLimit = 50

// 初始化，加载作业第一页
onMounted(async () => {
    if(props.start){
        findReport(0)
    }
})

// 过滤时间
const formatTime = (row) => {
    return moment(row.time).format('YY-MM-DD ')
}

const clickTable = (row) => {
    router.push(`/classroom/Report?id=${row.pid}`)
}

// 点击翻页
const clickButtonPage = (page) => {
    findReport(page)
}

function findReport_ref(uid){
    userId.value = uid
    findReport(0)
}

// 获取报告列表
async function findReport(page) {

    buttonPage.value = page === 0 ? 0 : buttonPage.value + page

    if (buttonPage.value < 0) {
        buttonPage.value = 0
    }

    // console.log('查询 ', buttonPage.value, userId.value )
    const res = await userApi.findReport(buttonPage.value, userId.value)
    if (res.data) {
        tables.data = res.data
        buttonPage.disabled = res.data.length < maxLimit
    }
}

</script>

<style lang="scss" scoped>
.title-student {
    flex-grow: 1;
    /* 占满剩余空间 */
    // border: 1px solid #73AD21;
}

.title-button {
    width: 60px;
    // border: 1px solid #73AD21;
}

.student-align {
    display: flex;
    // justify-content: flex-end;
    align-items: center;
}
</style>