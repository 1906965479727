
<template>
    <div class="middle-align">
        <el-input v-model="selectUser.inputValue" placeholder="搜索" @focus="focusSelectUser" @blur="blurSelectUser"
            @keyup.enter="clickSelectUser">
            <template #prepend>
                <el-select :placeholder="selectUser.hint" v-model="selectUser.value" :style="{ width: selectUser.width }"
                    @change="changeSelectUser">
                    <el-option v-for="item in selectUser.list" :key="item.uid" :label="`${item.username}, ${item.phone}`"
                        :value="item.uid" />
                </el-select>
            </template>
            <template #append>
                <el-button :icon="Search" @click="clickSelectUser" />
            </template>
        </el-input>

        <el-checkbox v-if="stoneAction.isAdmin" v-show="showCheckTeacher" label="老师" v-model="selectUser.checkTeacher"
            style="margin-left: 10px;" />
    </div>
</template>

<script setup>
import { reactive } from 'vue'
import { Search } from '@element-plus/icons-vue'
import adminApi from '@/api/adminApi'
import stoneAction from '@/util/stoneAction'

const selectUser = reactive({
    checkTeacher: false,
    value: '',
    list: [],
    disabled: true,
    visible: false,
    hint: '选择',
    inputValue: '',
    width: '100px',
    minWidth: '40px',
    maxWidth: '100px'
})

const props = defineProps({
    selectValue: { default: '' },
    inputFold: { default: true },    //input是否折叠
    showCheckTeacher: { default: false },   // 是否显示 老师选项
    isTeacher: { default: false } //老师
})


/** 父组件用法 * * 
 * 
<SearchUser v-model:selectValue="selectParent.value" ref="actionSelect" />


<script setup>
import { reactive, ref  } from 'vue'
import SearchUser from '@/components/control/SearchUser.vue'


const actionSelect = ref()

const updateSelect = (value) => {
    selectXxxx.value = value
}

调用初始化
*****  
actionSelect.value.initSelect() 
*********

*/

const emits = defineEmits(['update:selectValue', 'changeSelectUser'])
// const emits = defineEmits(['changeSelectUser'])
defineExpose({ initSelect })

// 下拉选择后，进行向父组件更新
const changeSelectUser = () => {
    emits('update:selectValue', selectUser.value)
    emits('changeSelectUser', selectUser.value)
}

// 搜索用户
const clickSelectUser = () => {
    findUser()
}

// 样式变化
const focusSelectUser = () => {
    if (props.inputFold) {
        selectUser.width = selectUser.minWidth
    }
}

// 样式变化
const blurSelectUser = () => {
    selectUser.width = selectUser.maxWidth
}

// 搜索用户
async function findUser() {
    const res = await adminApi.findUser(selectUser.inputValue, selectUser.checkTeacher, true)
    if (res.data) {
        selectUser.inputValue = ''
        selectUser.value = ''
        selectUser.list = res.data.filter(item => item.block !== 1)
        selectUser.hint = `条目 ${selectUser.list.length}`
    }
}

// 初始化
function initSelect() {
    selectUser.value = ''
    selectUser.list = []
    selectUser.inputValue = ''
    selectUser.hint = '选择'
}

</script>
