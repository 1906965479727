<template>
    <el-row justify="start" :gutter="5" style="margin-left: 10px;" class="lockHeight">
        <!-- dateStart dateEnd -->
        <!-- 选择日期 -->
        <DatePickerDouble v-model:datePicker="datePicker.value" />

        <el-button :icon="Search" @click="clickButtonSearch()" style="margin-left: 10px;" />
    </el-row>

    <!-- table -->
    <el-table :data="tables.data" highlight-current-row border stripe show-summary sum-text="合计"
        :height="tableHeight">
        <!-- <el-table-column type="index"></el-table-column> -->
        <el-table-column type="index" width="40" prop="mid" align="center" />

        <el-table-column property="m_time" label="时间" width="auto" :formatter="formatterDatetime" align="center" />

        <el-table-column property="username" label="姓名" width="auto" align="center" />

        <el-table-column property="e_name" label="学生" width="auto" align="center" />

        <el-table-column property="m_money" label="金额" width="auto" align="center" />
        <!-- <el-table-column type="index" width="40" prop="mid" align="center" />

            <el-table-column property="m_time" label="时间" width="auto" :min-width="90" :formatter="formatterDatetime" align="center" />

            <el-table-column property="username" label="姓名" width="auto" :min-width="110" align="center" />

            <el-table-column property="e_name" label="学生" width="auto" :min-width="100" align="center" />

            <el-table-column property="m_money" label="金额" width="auto" :min-width="80" align="center" /> -->

    </el-table>
</template>


<script setup>
import { reactive } from 'vue'
import { ElTable } from 'element-plus'
import { Search } from '@element-plus/icons-vue'
import moment from 'moment-timezone'
import userApi from '@/api/UserApi'
import DatePickerDouble from '@/components/control/DatePickerDouble.vue'
import { ElMessage } from 'element-plus'

const dataModel = reactive({
    datePicker: { value: '' },
    tables: { data: [] },
})

const props = defineProps({
    uid: { default: 0 },
    tableHeight: { default: 500 }
})

// defineExpose({ getConsume })

const { tables, datePicker } = dataModel


// 获取消费明细
const clickButtonSearch = async () => {
    const [ tStart, tEnd] = datePicker.value
    const timeStart = moment(tStart).format('YYYY-MM-DD')
    const timeEnd = moment(tEnd).format('YYYY-MM-DD')
    const res = await userApi.getConsume(props.uid, timeStart, timeEnd)

    if (res.data) {
        tables.data = res.data
    } else {
        ElMessage.error(res.error)
    }
}

// 格式化时间
const formatterDatetime = (row, column, cellValue, index) => {
    return moment(row.m_time).format('MM-DD HH:mm')
}

</script>


