<template>
    <div style="display: flex;">
        <el-date-picker v-model="dateStart.value" type="date" placeholder="开始日期" style="width: 120px" @change="datePickerChange" />
        <el-date-picker v-model="dateEnd.value" type="date" placeholder="结束日期" style="width: 120px" @change="datePickerChange" />
    </div>
</template>

<script setup>
import { reactive, onMounted } from 'vue'
import moment from 'moment-timezone'

// 使用
// <DatePickerDouble v-model:datePicker="datePicker.value" />

// 这个值无效
const props = defineProps({
    datePicker: { default: '' }
})

const dataModel = reactive({
    dateStart: { value: '' },
    dateEnd: { value: '' },
})

const { dateStart, dateEnd } = dataModel

const emits = defineEmits(['update:datePicker'])

onMounted(() => {
    dateStart.value = moment().startOf('M').format('YYYY-MM-DD')
    dateEnd.value = moment().format('YYYY-MM-DD')
    datePickerChange()
})

const datePickerChange = () =>{
    emits('update:datePicker', [dateStart.value, dateEnd.value])
}

</script>