import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

// 获取全局变量，只需要使用
// $store.state 

// 修改全局状态，应该在相应的VUE文件中，引入以下，避免直接修改导致的状态不一致

// <script setup>
// import { useStore } from 'vuex'
// const store = useStore()

// const active名称 = () =>{
//     store.commit('函数A')
// }
// </script>

// 在 store.index中 mutations 中添加
// 函数A(state){
//     state.isXXX = 
// }


export default createStore({
    state: {
        // 是否为第一次路由解析
        isGetterRouter: false,
        // 是否折叠菜单
        isCollapsed: false,
        // 用户信息
        userInfo: {},
        // 最后动作
        lastAction: {},
        // window尺寸
        windowSize: { width: 0, height: 0 }
    },
    getters: {
    },
    mutations: {
        // 设置路由解析
        changGetterRouter(state, value) {
            state.isGetterRouter = value
        },
        // 控制侧边栏展开
        changeCollapsed(state) {
            state.isCollapsed = !state.isCollapsed
        },
        changeUserInfo(state, value) {
            state.userInfo = {
                ...state.userInfo,
                ...value
            }
        },
        clearUserInfo(state) {
            state.userInfo = {}
        },
        changeLastAction(state, value) {
            state.lastAction = {
                ...state.lastAction,
                ...value
            }
        },
        deleteLastAction(state, value) {
            delete state.lastAction[value]
        },
        changeWindowSize(state, value) {
            state.windowSize = {
                ...state.windowSize,
                ...value
            }
        }
    },
    actions: {
    },
    modules: {
    },
    // 调用 vuex-persistedstate，设置 持久化对象，简化操作
    plugins: [createPersistedState({
        paths: ['isCollapsed', 'userInfo', 'lastAction', 'windowSize']
    })]
})
