
<template >
    <el-form label-width="80px" style="padding: 20px;padding-right: 30px;">
        <!-- sliderStudy -->
        <el-form-item label="每日新学">
            <el-slider v-model="sliderStudy.value" show-input size="small" input-size="small" :max="100"
                style="margin-left: 20px;" />
        </el-form-item>

        <!-- sliderReview -->
        <el-form-item label="每日复习">
            <el-slider v-model="sliderReview.value" show-input size="small" input-size="small" :max="300"
                style="margin-left: 20px;" />
        </el-form-item>

        <!-- sliderPeriod -->
        <el-form-item label="周期 (月)">
            <el-slider v-model="sliderPeriod.value" show-input size="small" input-size="small" :max="36"
                style="margin-left: 20px;" />
        </el-form-item>

        <!-- sliderFrequency -->
        <el-form-item label="重复频率">
            <el-slider v-model="sliderFrequency.value" show-input size="small" input-size="small" :max="10"
                style="margin-left: 20px;" />
        </el-form-item>

        <!-- sliderTimeOut -->
        <el-form-item label="超时 (秒)">
            <el-slider v-model="sliderTimeOut.value" show-input size="small" input-size="small" :max="60"
                style="margin-left: 20px;" />
        </el-form-item>

        <!-- sliderDifficulty -->
        <el-form-item label="答题难度">
            <el-slider v-model="sliderDifficulty.value" show-input size="small" input-size="small" :max="10"
                style="margin-left: 20px;" />
        </el-form-item>

        <!-- 按钮 buttonAnswer -->
        <template v-for="item in book.list" :key="item.value">
            <el-checkbox v-model="item.value" :label="item.label" size="large" />
        </template>
    </el-form>
    <el-row>

    </el-row>
    <el-text></el-text>
</template>

<script setup>
import { reactive, onMounted, computed, ref } from 'vue'
import { ArrowRightBold } from '@element-plus/icons-vue'
import booklist from '@/components/config/booklist'

const dataModel = reactive({
    sliderStudy: { value: 30 },
    sliderReview: { value: 100 },
    sliderPeriod: { value: 24 },
    sliderFrequency: { value: 5 },
    sliderTimeOut: { value: 10 },
    sliderDifficulty: { value: 5 },
    book: { list: []}
})

const { book, sliderStudy, sliderReview, sliderPeriod, sliderFrequency, sliderTimeOut, sliderDifficulty } = dataModel

onMounted(() =>{
    book.list = booklist.engPractice
})

</script>

<style>
/* .el-slider .el-input {
    width: 80px;
    display: flex;
    justify-content: end;
    margin-right: 0px;
} */

/* --el-slider-button-size */
.el-slider .el-slider__input {
    width: 80px;
}
</style>