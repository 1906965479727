<template>
    <router-view />
</template>

<script>

  // 禁止web端屏幕缩放
//   created() {
//     window.addEventListener("mousewheel",function (event) {
//       if (event.ctrlKey === true || event.metaKey) {event.preventDefault();}},{ passive: false })
//   }
</script>

<style lang="scss">
* {
    margin: 0;
    padding: 0;
}

.right-align {
    display: flex;
    justify-content: flex-end;
    // align-items: center;
}

.center-align {
    display: flex;
    justify-content: center;
    // align-items: center;
}

.middle-align {
    display: flex;
    align-items: center;
}

.center-text {
    text-align: center;
}

.el-row {
    // 需要设置 windowHeight
    margin-top: 5px;
    margin-bottom: 10px;
}

.el-row.lockHeight {
    // 需要设置 rowLockHeight
    height: 40px
}

.el-table {
    // margin: auto;
    // max-width: 900px;
}

//  height: calc(100vh - var(--vh-offset, 0px) )
/*
用于统一部署，版面设计时方便查看，最后部署时需处理
*/
.div-border {
    // border: 1px solid #73AD21;
}

.div-container {
    @extend .div-border !optional;
    margin: auto;
    max-width: 900px;
    // 需要设置 windowHeight
    margin-top: 20px;   
}

// el-dialog 属性
// .el-dialog {
//     --el-dialog-width: 50%;
//     --el-dialog-margin-top: 15vh;
//     --el-dialog-bg-color: var(--el-bg-color);
//     --el-dialog-box-shadow: var(--el-box-shadow);
//     --el-dialog-title-font-size: var(--el-font-size-large);
//     --el-dialog-content-font-size: 14px;
//     --el-dialog-font-line-height: var(--el-font-line-height-primary);
//     --el-dialog-padding-primary: 20px;
//     --el-dialog-border-radius: var(--el-border-radius-small);
//     position: relative;
//     margin: var(--el-dialog-margin-top,15vh) auto 50px;
//     background: var(--el-dialog-bg-color);
//     border-radius: var(--el-dialog-border-radius);
//     box-shadow: var(--el-dialog-box-shadow);
//     box-sizing: border-box;
//     width: var(--el-dialog-width,50%);
// }

.el-dialog{
    border-radius: 10px !important;
}

/*
样式结束
*/

.el-form-item,
.el-col {
    @extend .div-border !optional;
}

// 用于input框插入select，颜色前后一致的设定
.input-with-select .el-input-group__prepend {
    background-color: var(--el-fill-color-blank);
}

//调整表头，等价于 header-cell-style
// ::v-deep .el-table__header tr,
// ::v-deep .el-table__header th {
//     // padding: 0;
//     // height: 42px;
//     // font-size: 20px;
//     // border: 1;
//     // border-width: 1px;

// 渐变色
// background: linear-gradient(to right, #fba721, #fcce68, #faefb4) !important;
//     border-color: #C0C0C0;
//     color: black;
// }
.el-table .cell {
    padding: 0px !important;
    // padding-left: 0px!important;
    // padding-right: 0px!important;
    // border: 5px solid black;
}



// .el-table__cell div,
// .el-table__cell tr,
// .el-table__cell td {
//     padding-left: 0px;
//     padding-right: 0px;
//     padding: 0px;
//     padding-left: 0px;
//     font-size: 10px;
//     border: 5px solid black;
// }

// 设置表格，等价于 cell-style
// ::v-deep .el-table__body tr,
// ::v-deep .el-table__body td {
//     padding: 0;
//     margin: 0;
//     font-size: 10px;
//     color: black;
//     border-color: #C0C0C0;
//     border-top-color: #c0c0c0!important;
//     border-bottom-color: #c0c0c0!important;
//     // border-bottom-color: rgb(248, 117, 41);
//     // border-top-color: rgb(248, 117, 41)!important;
//     // border: 1;
//     // border-width: 1px;
//     height: 40px;
//     // 不换行，暂时没效果
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     outline-width: 0px;
//     word-break: break-all;
// }

// 防止鼠标移动变色，好像都可以
// ::v-deep  .el-table__row--hover {
//   background-color: transparent !important;
// }
// .el-table tbody tr:hover > td {
//     background-color: #e8f0fa !important;
//   }

// 功能和el-table__body差不多，不如body设置全
// ::v-deep .el-table .el-table__row {
//     // line-height: 640px; /* 设置你需要的行高 */
//     height: 10px;
//     font-size: 10px;
// }

// 设置滚动条样式，实际为0就可以了嘛
::-webkit-scrollbar {
    width: 0px;
    height: 5px;
    position: absolute;
}

::-webkit-scrollbar-thumb {
    background: #cedbf5;
}

::-webkit-scrollbar-track {
    background: #ffffff;
}
</style>
