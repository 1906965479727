import axiosA from 'axios'
// const axios = require('axios')

const axios = {
    async get(url, params) {
        try {
            const res = await axiosA.get(url, params)
            return res.data
        } catch (error) {
            console.error('访问失败: ', error)
            return false
        }
    },
    async post(url, params, headers) {
        try {
            const res = await axiosA.post(url, params, headers)
            return res.data
        } catch (error) {
            console.error('提交失败: ', error)
            return false
        }
    },

    // 上传 地址、文本内容、文件、进度json
    upload(url, dataJson, files, progress) {
        if (JSON.stringify(dataJson).length === 0 && !files[0]) {
            return { error: 'no data' }
        }
        const params = new FormData()
        for (const key in dataJson) {
            params.append(key, dataJson[key])
        }

        // 文件名要编码，防止乱码
        files.forEach(file => {
            params.append('file', file, encodeURI(file.name))
        })
        return this.post(
            url,
            params, {
            headers: {
                "Content-Type": "multipart/form-data;charset=utf-8"
            },
            onUploadProgress: function (progressEvent) {
                const pos = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                // 在这里更新进度条的状态
                // 可以将进度值 progress 存储到 Vue 实例的 data 中，然后在模板中绑定显示
                // 或者通过其他方式更新进度条组件的状态

                progress.value = pos
                // console.log('上传进度:', pos + '%', progress.value)
            }
        })
    },

    // upload(url, params) {
    //     return this.post(
    //         url,
    //         params, {
    //         headers: {
    //             "Content-Type": "multipart/form-data;charset=utf-8"
    //         }
    //     })
    // },

    async download(url, fileName) {
        const res = await this.get(url, {
            responseType: 'blob'
        })

        if (!res) { return false }

        console.log(res)
        const fileUrl = URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        try {
            link.href = fileUrl
            link.download = fileName
            
            link.setAttribute('download', fileName) // 自定义文件名
            
            // 将 <a> 元素添加到页面中
            document.body.appendChild(link)
            link.click()
        } finally {
            // 从页面中移除 <a> 元素
            // document.body.removeChild(link)
            URL.revokeObjectURL(fileUrl)
        }

        // link.href = fileUrl
        // // link.setAttribute('download', fileName) // 自定义文件名
        // link.download = fileName
        // // document.body.appendChild(link)
        // link.click()
        // URL.revokeObjectURL(url);
        /***
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
        */

    },

    async download2(url, fileName) {

        console.log(url)
        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        // link.setAttribute('download', fileName)
        link.click()

    },
}

export default axios
