/*
* @描述: 执行后台请求
* @作者: aoqilajiao
* @时间: 2023-07-12
* @最后修改时间: 2023-07-12
*/
import axios from '@/util/axios'

const UserApi = {
    // 获取课程表，id 可能是 cid， 也可能是 tid
    getClassOrder(cid, page) {
        return axios.get(`/api/user/getClassOrder?cid=${cid}&page=${page}`)
    },
    // 获取自己孩子的信息
    findChildren() {
        return axios.get('/api/user/findChildren')
    },
    // 获取个人信息
    getUser() {
        return axios.get('/api/user/getUser')
    },

    // 设置用户密码
    setUserPassword(oldPassword, NewPassword) {
        return axios.post('/api/user/setUserPassword', {
            oldPassword: oldPassword,
            NewPassword: NewPassword
        })
    },
    
    // 获取积分明细
    getScore(uid, start, end) {
        return axios.get(`/api/user/getScore?uid=${uid}&start=${start}&end=${end}`)
    },
    // 获取消费明细
    getConsume(uid, start, end) {
        return axios.get(`/api/user/getConsume?uid=${uid}&start=${start}&end=${end}`)
    },

    // 获取报告
    getReport(pid) {
        return axios.get(`/api/user/getReport?pid=${pid}`)
    },
    // 获取报告列表
    findReport(page, uid) {
        return axios.get(`/api/user/findReport?page=${page}&uid=${uid}`)
    },
    // 登录
    login(dataJson) {
        return axios.post('/api/user/login', dataJson)
    },

    // 更新作业
    setHomework(dataJson, files, progress) {
        return axios.upload('/api/user/setHomework', dataJson, files, progress)
    },
    // 获取作业详情
    getHomework(hid) {
        // console.log( `/api/user/gethomework?hid=${hid}` )
        return axios.get(`/api/user/getHomework?hid=${hid}`)
    },
    // 获取作业
    findHomework(page) {
        // console.log( `/api/user/gethomeworklist?page=${page}` )
        return axios.get(`/api/user/findHomework?page=${page}`)
    },
}

export default UserApi