
import Home from '@/views/home/Home.vue'
// 个人中心
import Center from '@/views/center/Center.vue'
import Consume from '@/views/center/Consume.vue'
import Point from '@/views/center/Point.vue'

import UserAdd from '@/views/user-manage/UserAdd.vue'
import UserList from '@/views/user-manage/UserList.vue'
// import UserSet from '@/views/user-manage/UserSet.vue'
import OrderList from '@/views/order-manage/OrderList.vue'
import OrderManage from '@/views/order-manage/OrderManage.vue'
// import NewsAdd from '../views/news-manage/NewsAdd.vue'
// import NewsList from '../views/news-manage/NewsList.vue'
import NotFound from '@/views/notfound/NotFound.vue'
// import TorrentUpdate from '../views/torrent-manage/TorrentUpdate.vue'
// import TorrentTransfer from '../views/torrent-manage/TorrentTransfer.vue'
import StudentAdd from '@/views/student-manage/StudentAdd.vue'
import StudentList from '@/views/student-manage/StudentList.vue'
import StudentReport from '@/views/student-manage/StudentReport.vue'

import Balance from '@/views/account-manage/Balance.vue'
import Settlement from '@/views/account-manage/Settlement.vue'
import Score from '@/views/account-manage/Score.vue'
import Recharge from '@/views/account-manage/Recharge.vue'

import Arrange from '@/views/classroom/Arrange.vue'
import Homework from '@/views/classroom/Homework.vue'
import HomeworkAdd from '@/views/classroom/HomeworkAdd.vue'
import HomeworkList from '@/views/classroom/HomeworkList.vue'

import Report from '@/views/classroom/Report.vue'
import ReportAdd from '@/views/classroom/ReportAdd.vue'
import ReportList from '@/views/classroom/ReportList.vue'

import Practice from '@/views/memory/Practice.vue'
import Recite from '@/views/memory/Recite.vue'
// 词库
import ThesaurusAdd from '@/views/thesaurus-manage/ThesaurusAdd.vue'

// 老师课程表
import TimeTable from '@/views/teacher-center/TimeTable.vue'


const routes = [
    {
        path: '/index',
        component: Home,
        meta: {
            title: '首页'
        }
    },

    // 个人中心
    {
        path: '/center/Center',
        component: Center,
        requireUser: true
    },
    {
        path: '/center/Consume',
        component: Consume,
        requireUser: true
    },
    {
        path: '/center/Point',
        component: Point,
        requireUser: true
    },

    // 用户管理
    {
        path: '/user-manage/UserAdd',
        component: UserAdd,
        requireManager: true
    },
    {
        path: '/user-manage/UserList',
        component: UserList,
        requireManager: true
    },
    // {
    //     path: '/user-manage/UserSet',
    //     component: UserSet
    // },
    // 学生管理
    {
        path: '/student-manage/StudentAdd',
        component: StudentAdd,
        requireManager: true
    },
    {
        path: '/student-manage/StudentList',
        component: StudentList,
        requireManager: true
    },
    {
        path: '/student-manage/StudentReport',
        component: StudentReport,
        requireAdmin: true
    },

    // 账目管理
    {
        path: '/account-manage/Balance',
        component: Balance,
        requireAdmin: true
    },
    {
        path: '/account-manage/Settlement',
        component: Settlement,
        requireAdmin: true
    },
    {
        path: '/account-manage/Score',
        component: Score,
        requireAdmin: true
    },
    {
        path: '/account-manage/Recharge',
        component: Recharge,
        requireAdmin: true
    },
    // {
    //     path: '/news-manage/NewsAdd',
    //     component: NewsAdd,
    //     requireAdmin: true
    // },
    // {
    //     path: '/news-manage/NewsList',
    //     component: NewsList,
    //     requireAdmin: true
    // },

    {
        path: '/Order-manage/OrderList',
        component: OrderList,
        requireAdmin: true
    },
    {
        path: '/Order-manage/OrderManage',
        component: OrderManage,
        requireManager: true
    },

    // {
    //     path: '/torrent-manage/TorrentUpdate',
    //     component: TorrentUpdate,
    //     requireAdmin: true
    // },
    // {
    //     path: '/torrent-manage/TorrentTransfer',
    //     component: TorrentTransfer,
    //     requireAdmin: true
    // },

    // 词库管理
    {
        path: '/thesaurus-manage/ThesaurusAdd',
        component: ThesaurusAdd,
        requireAdmin: true
    },


    // 课堂学习
    {
        path: '/classroom/Arrange',
        component: Arrange,
        requireUser: true
    },
    {
        path: '/classroom/Homework',
        component: Homework
    },
    {
        path: '/classroom/HomeworkAdd',
        component: HomeworkAdd,
        requireTeacher: true
    },
    {
        path: '/classroom/HomeworkList',
        component: HomeworkList
    },
    {
        path: '/classroom/Report',
        component: Report
    },
    {
        path: '/classroom/ReportAdd',
        component: ReportAdd,
        requireTeacher: true
    },
    {
        path: '/classroom/ReportList',
        component: ReportList
    },

    // 记忆
    {
        path: '/memory/Practice',
        component: Practice,
        requireUser: true
    },
    {
        path: '/memory/Recite',
        component: Recite,
        requireUser: true
    },

    // 教师
    {
        path: '/teacher-center/TimeTable',
        component: TimeTable,
        requireTeacher: true
    },


    {
        path: '/',
        redirect: '/index'
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Notfound',
        component: NotFound
    }
]

export default routes